import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import tableI18n from '../../TableLocalization';
import SindilegisBar from '../../commons/SindilegisBar';
import styles from '../styles';
import Loading from '../../commons/Loading';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { formataData, formataDataHora, formatarValor } from '../../Financial/util';
import CardContent from '@material-ui/core/CardContent';
import { getProcessos, SITUACAO_PROCESSO_EXCLUIDO } from '../../../actions/juridico';
import _ from 'lodash';
import { downloadXlsx } from '../../commons/util';
import { getCurrentUserEmail } from '../../../firebase/auth';
import { getFiliados, setFiliado } from '../../../actions/filiado';

class MeusProcessos extends React.Component {
  componentDidMount() {
    this.props.getProcessos();
    this.props.getFiliados();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.filiado && this.props.filiados) {
      const currentUserEmail = getCurrentUserEmail();
      const filiado = this.props.filiados.find((f) => f.email === currentUserEmail);
      this.props.setFiliado(filiado);
    }
  }

  render() {
    const { classes, processos, loading, filiadoLoading, filiado } = this.props;

    let processosFormatados = [];
    if (processos && filiado) {
      processosFormatados = processos
        .filter(
          (p) =>
            p.situacao !== SITUACAO_PROCESSO_EXCLUIDO &&
            p.filiados != null &&
            p.filiados.find((p) => p.id === filiado.id)
        )
        .map((p) => ({
          ...p,
          filiados: p.filiados
            ? p.filiados
                .filter((f) => f.nome_completo !== null)
                .map((f) => `${f.nome_completo.trim()}`)
                .join(', ')
            : 'Indeterminados',
          empresas: p.filiados
            ? _.uniq(
                p.filiados
                  .filter((f) => f.empresa != null && f.empresa.length > 0)
                  .map((f) => f.empresa)
              ).join(', ')
            : '',
          ano: new Date(p.dataProtocolo).getFullYear(),
          valorCausa: p.valorCausa,
          eventos: p.eventos,
          portal:
            p.publicarPortalSindilegis === undefined ? '' : p.publicarPortalSindilegis ? 'S' : 'N',
        }));
    }

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading && filiadoLoading} />
        <Card className={classes.paper} elevation={1} hidden={loading && filiadoLoading}>
          <CardContent>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" className={classes.title} />
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    {
                      title: 'Tipo processo',
                      field: 'tipo',
                    },
                    {
                      title: 'Filiados',
                      field: 'filiados',
                    },
                    {
                      title: 'Casa(s)',
                      field: 'empresas',
                    },
                    {
                      title: 'Número processo',
                      field: 'numeroProcesso',
                    },
                    {
                      title: 'Ano',
                      field: 'ano',
                    },
                    {
                      title: 'Tribunal/Orgão',
                      field: 'tribunalOrgao',
                    },
                    {
                      title: 'Situação',
                      field: 'situacao',
                    },
                    {
                      title: 'Valor',
                      field: 'valorCausa',
                      render: (row) => (
                        <span>
                          {formatarValor(
                            row.valorCausa
                              ? Number(row.valorCausa.replaceAll('.', '').replaceAll(',', '.'))
                              : ''
                          )}
                        </span>
                      ),
                    },
                    {
                      title: 'Portal',
                      field: 'portal',
                    },
                  ]}
                  data={processosFormatados}
                  title="Meus Processos"
                  actions={[
                    (processo) => {
                      if (processo.linkProcesso) {
                        return {
                          icon: 'link',
                          tooltip: 'Ir para processo em: ' + processo.linkProcesso,
                          onClick: (event, processo) => window.open(processo.linkProcesso),
                        };
                      } else {
                        return null;
                      }
                    },
                  ]}
                  options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50, 100],
                    exportButton: true,
                    exportAllData: true,
                    exportCsv: (columns, data) => {
                      data.forEach((d) => {
                        d.data = formataData(d.data);
                      });

                      downloadXlsx(columns, data, 'processos.xlsx');
                    },
                  }}
                  localization={tableI18n}
                  detailPanel={(row) => this.renderDetail(row)}
                  onRowClick={(event, rowData, togglePanel) => togglePanel()}
                />
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }

  renderDetail(processo) {
    const { classes } = this.props;
    const { eventos } = processo;
    if (eventos) {
      eventos.reverse();
      return (
        <React.Fragment>
          <Typography variant="body2" color="textSecondary" style={{ padding: 10 }}>
            EVENTOS DO PROCESSO
          </Typography>
          <Grid container spacing={2} className={classes.detail}>
            {eventos.map((evento, index) => {
              return (
                <Grid item sm={4} key={index}>
                  <Card className={classes.root} style={{ minHeight: '220px' }}>
                    <CardHeader
                      style={{ paddingBottom: '5px', paddingLeft: '12px' }}
                      subheader={
                        <Grid container spacing={5} className={classes.detail}>
                          <Grid item sm={10}>
                            <Typography> {'Evento - ' + evento.titulo}</Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                    <CardContent>
                      <Typography variant="body2" color="textSecondary">
                        {'Registrado em: ' + formataDataHora(evento.data)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {'Descrição: ' + evento.descricao}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {'Foi comunicado: ' + (evento.comunicacao ? 'Sim' : 'Não')}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </React.Fragment>
      );
    } else {
      return (
        <Grid
          container
          spacing={1}
          className={classes.detail}
          alignItems="center"
          justifyContent="center">
          <Typography variant="body1">Nenhum evento cadastrado</Typography>
        </Grid>
      );
    }
  }
}

const mapStateToProps = ({ juridico: { processos, loading }, filiado: { filiado, filiados } }) => ({
  filiado,
  filiados,
  processos,
  loading,
});

MeusProcessos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setFiliado,
    getFiliados,
    getProcessos,
  })
)(withRouter(withSnackbar(MeusProcessos)));
