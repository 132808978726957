export default {
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsPerPage: 'Registros por página:',
    labelRowsSelect: 'registros',
    firstAriaLabel: 'Primeira página',
    firstTooltip: 'Primeira página',
    previousAriaLabel: 'Página anterior',
    previousTooltip: 'Página anterior',
    nextAriaLabel: 'Próxima página',
    nextTooltip: 'Próxima página',
    lastAriaLabel: 'Última página',
    lastTooltip: 'Última página', // Last Page
  },
  toolbar: {
    nRowsSelected: '{0} Registro(s) selecionado(s)',
    showColumnsTitle: 'Mostrar colunas',
    showColumnsAriaLabel: 'Mostrar colunas',
    exportTitle: 'Exportar',
    exportAriaLabel: 'Exportar',
    exportName: 'Exportar',
    searchTooltip: 'Pesquisar',
    searchPlaceholder: 'Pesquisar',
  },
  header: {
    actions: 'Ações', // Actions
  },
  body: {
    emptyDataSourceMessage: 'Sem registros para mostrar',
    filterRow: {
      filterTooltip: 'Filtro',
    },
  },
};
