import React from 'react';
import _ from 'lodash';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../../commons/InputText';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import {
  atualizarProcesso,
  criarProcedimento,
  getAtendimentos,
  getEscritoriosAdvocacia,
} from '../../../actions/juridico';
import { getObjetosProcesso } from '../../../actions/objetoProcesso';
import AsyncAutoCompleteSelect from '../../commons/AsyncAutoComplete';
import { getFiliados } from '../../../actions/filiado';
import Switch from '../../commons/Switch';
import InputNumber from '../../commons/InputNumber';
import RadioButton from '../../commons/RadioButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Select from '../../commons/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { onceGetEscritorioAdvocacia } from '../../../firebase/db';
import UploadArquivosProcesso from './uploadArquivosProcesso';
import Typography from '@material-ui/core/Typography';
import Comentarios from '../comentarios';
import { Orgaos, Titulo } from './processosCommons';

class ProcedimentoForm extends React.Component {
  state = { anexos: [] };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getEscritoriosAdvocacia();
    this.props.getAtendimentos();
    this.props.getObjetosProcesso();

    if (!this.props.filiados || this.props.filiados.length === 0) {
      this.props.getFiliados();
    }
  }

  save(procedimento) {
    const { escritoriosAdvocacia, filiados } = this.props;
    if (procedimento.id) {
      this.props.atualizarProcesso(procedimento, filiados, escritoriosAdvocacia, () =>
        this.successHandler(true)
      );
    } else {
      this.props.criarProcedimento(procedimento, filiados, escritoriosAdvocacia, () =>
        this.successHandler(true)
      );
    }
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Procedimento salvo com sucesso!', {
      variant: 'success',
    });
    this.props.reset();
    handleClose(reload);
  }

  cancelar() {
    this.props.reset();
    this.props.handleClose();
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar interação.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, filiados, atendimentos, authUser } =
      this.props;

    const atendimentosComId = [];

    if (atendimentos) {
      const escritorios = Object.keys(atendimentos);
      escritorios.forEach((idEscritorio) => {
        const atendimentosEscritorio = atendimentos[idEscritorio];
        Object.keys(atendimentosEscritorio).forEach((idAtendimento) => {
          const atendimentoEscritorio = atendimentosEscritorio[idAtendimento];
          if (atendimentoEscritorio.escritorio == null) {
            //Legado
            onceGetEscritorioAdvocacia(idEscritorio).then((e) => {
              const escritorioAdvocacia = e.val();
              escritorioAdvocacia.id = idEscritorio;
              atendimentosEscritorio.escritorio = escritorioAdvocacia;
              atendimentosComId.push({
                id: idAtendimento,
                ...atendimentosEscritorio[idAtendimento],
              });
            });
          } else {
            atendimentosComId.push({
              id: idAtendimento,
              ...atendimentosEscritorio[idAtendimento],
            });
          }
        });
      });
    }

    const filiadosIndeterminados =
      this.props.procedimento &&
      this.props.procedimento.values &&
      this.props.procedimento.values.filiadosIndeterminados;
    const relacionadoAtendimentos =
      this.props.procedimento &&
      this.props.procedimento.values &&
      this.props.procedimento.values.relacionadoAtendimentos;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {this.props.processo ? 'Editar' : 'Novo'} procedimento
        </DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} hidden={false} alignItems="left" justifyContent="left">
              <Grid item sm={4}>
                <Field
                  name="relacionadoAtendimentos"
                  component={RadioButton}
                  label="Relacionado a atendimentos?">
                  <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                  <FormControlLabel value="Não" control={<Radio />} label="Não" />
                </Field>
              </Grid>
              {relacionadoAtendimentos === 'Sim' && (
                <Grid item sm={8}>
                  <Field
                    defaultOptions={[]}
                    isMulti={true}
                    loadOptions={(input) => {
                      if (input.length < 3) {
                        return Promise.resolve([]);
                      }
                      return Promise.resolve(
                        atendimentosComId
                          .filter((a) => a.numero && a.numero.includes(input))
                          .map((a) => ({ label: a.numero, value: a.id }))
                          .slice(0, 100)
                      );
                    }}
                    name="atendimentos"
                    component={AsyncAutoCompleteSelect}
                    label="Atendimentos"
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={6}>
                <Field name="natureza" component={Select} label="Natureza">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {['Profissional', 'Particular'].map((t) => (
                    <MenuItem key={t} value={t}>
                      {t}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={6}>
                <Field name="tags" component={InputText} multiline={false} label="Tags" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={6}>
                <Field name="valorCausa" component={InputNumber} multiline={false} label="Valor" />
              </Grid>
              <Grid item sm={6}>
                <Field name="escritorio" component={Select} label="Escritório">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {this.props.escritoriosAdvocacia.map((t) => (
                    <MenuItem key={t.nome} value={t.id}>
                      {t.nome}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={3}>
                <Field name="publicarPortalSindilegis" component={Switch} label="Ação Coletiva" />
              </Grid>
              <Titulo />
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={12}>
                <Field name="objetoProcesso" component={Select} label="Objeto">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {this.props.objetosProcesso.map((t) => (
                    <MenuItem
                      key={t.codigo + ' - ' + t.descricao}
                      value={t.codigo + ' - ' + t.descricao}>
                      {t.codigo + ' - ' + t.descricao}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={12}>
                <Field
                  name="pessoasTemDireito"
                  component={InputText}
                  multiline={false}
                  label="Quais pessoas tem direito?"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={4}>
                <Field
                  name="filiadosIndeterminados"
                  component={Switch}
                  label="Filiados Indeterminados"
                />
              </Grid>
              <Orgaos />
            </Grid>
            {!filiadosIndeterminados && (
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start">
                <Grid item sm={12}>
                  <Field
                    defaultOptions={[]}
                    isMulti={true}
                    loadOptions={(input) => {
                      if (input.length < 3) {
                        return Promise.resolve([]);
                      }
                      return Promise.resolve(
                        filiados
                          .filter(
                            (f) =>
                              f.nome_completo &&
                              f.nome_completo.toLowerCase().includes(input.toLowerCase())
                          )
                          .map((f) => ({ label: `${f.nome_completo} - ${f.cpf}`, value: f.id }))
                          .slice(0, 100)
                      );
                    }}
                    name="filiados"
                    component={AsyncAutoCompleteSelect}
                    label="Filiados interessados"
                  />
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              hidden={false}
              alignItems="flex-start"
              justifyContent="flex-start">
              <Grid item sm={12}>
                <UploadArquivosProcesso
                  onChange={(anexos) => {
                    this.setState({ anexos });
                  }}
                />
              </Grid>
              <Grid item sm={12}>
                <Typography variant="h6">Comentários registrados</Typography>
                <FieldArray name="comentarios" component={Comentarios} email={authUser?.email} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelar.bind(this)} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting || invalid}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ProcedimentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  form: { procedimento },
  filiado: { filiado, filiadoId, filiados },
  juridico: { escritoriosAdvocacia, processo, atendimentos },
  objetoProcesso: { objetosProcesso },
}) => ({
  initialValues: processo
    ? {
        ...processo,
        escritorio: processo.escritorio.id,
        filiados: processo.filiados
          ? processo.filiados.map((f) => ({
              label: f.nome_completo,
              value: f.id,
            }))
          : undefined,
      }
    : undefined,
  procedimento,
  escritoriosAdvocacia,
  filiado,
  filiadoId,
  filiados,
  processo,
  atendimentos,
  objetosProcesso,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = [
    'relacionadoAtendimentos',
    'natureza',
    'tags',
    'valorCausa',
    'escritorio',
    'objetoProcesso',
  ];

  if (values.publicarPortalSindilegis) {
    requiredFields.push('titulo');
  }

  requiredFields.forEach((field) => {
    if (field === 'relacionadoAtendimentos' && values[field] === 'Sim' && !values['atendimentos']) {
      errors['atendimentos'] = ' Obrigatório';
    } else if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
      errors[field] = ' Obrigatório';
    }
  });

  if (!values['filiadosIndeterminados'] && _.isEmpty(values['filiados'])) {
    errors['filiados'] = ' Obrigatório';
  } else if (values['filiadosIndeterminados'] && _.isEmpty(values['orgaos'])) {
    errors['orgaos'] = ' Obrigatório';
  }

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    atualizarProcesso,
    getAtendimentos,
    criarProcedimento,
    getEscritoriosAdvocacia,
    getFiliados,
    getObjetosProcesso,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'procedimento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ProcedimentoForm))
);
