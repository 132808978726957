export default (values) => {
  const errors = {};
  const requiredFields = ['nome_completo', 'data_nascimento'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};
