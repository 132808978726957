import React from 'react';
import { Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@material-ui/core';

const CustomDialog = ({ open, onClose, title, content }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={false} maxWidth="sm" role="dialog">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {Array.isArray(content) ? (
          <ul style={{ padding: '0', margin: '0' }}>
            {content.map((item, index) => (
              <li
                key={index}
                style={{ margin: '5px 0', listStyleType: 'disc', paddingLeft: '20px' }}>
                {item}
              </li>
            ))}
          </ul>
        ) : (
          <div>{content}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto',
          }}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
