import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { getFiliados } from '../../actions/filiado';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { formataData } from '../Financial/util';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { setFiliadosAComunicar } from '../../actions/comunicacao';
import ComunicacaoDialog from './comunicacao';
import CustomDialog from '../commons/CustomDialog';
import TooltipText from '../commons/TooltipText';
import ButtonComponent from '../commons/ButtonComponent';
import { FormControlLabel, Switch, TextField, Typography, MenuItem } from '@material-ui/core';
import * as config from '../UserDetail/config';
import { formataCPF } from '../commons/util';

const Comunicacao = ({
  classes,
  filiados,
  loading,
  filiadosAComunicar,
  getFiliados,
  setFiliadosAComunicar
}) => {
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = useState({ open: false, tipo: '', conteudo: '' });
  const [filiadosState, setFiliadosState] = useState([]);
  const [apllyFilters, setApllyFilters] = useState(false);

  const [filters, setFilters] = useState({
    matricula: '',
    nome: '',
    email: '',
    cargo: '',
    cpf: '',
    sexo: '',
    casa: '',
    cidade: '',
    estado: '',
    situacao: '',
    forma_pagamento: '',
    cadastro: '',

  });

  useEffect(() => {
    getFiliados();
  }, [getFiliados]);

  useEffect(() => {
    if (filiadosState.length === 0 && filiados.length > 0) {
      setFiliadosState(filiados);
    }
  }, [filiados, filiadosState]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({
      matricula: '',
      nome: '',
      email: '',
      cargo: '',
      cpf: '',
      sexo: '',
      casa: '',
      cidade: '',
      estado: '',
      situacao: '',
      forma_pagamento: '',
      cadastro: '',
    });
  }

  const safeToLowerCase = (value) => {
    return value && typeof value === 'string' ? value.toLowerCase() : '';
  };

  const filteredData = filiadosState.filter((row) => {
    if (!row) return false;

    return (
      (safeToLowerCase(row.matricula?.toString()).includes(safeToLowerCase(filters.matricula)) || filters.matricula === '') &&
      (safeToLowerCase(row.nome_completo).includes(safeToLowerCase(filters.nome)) || filters.nome === '') &&
      (safeToLowerCase(row.email).includes(safeToLowerCase(filters.email)) || filters.email === '') &&
      (safeToLowerCase(row.cargo).includes(safeToLowerCase(filters.cargo)) || filters.cargo === '') &&
      (safeToLowerCase(row.cpf?.toString()).includes(safeToLowerCase(filters.cpf)) || filters.cpf === '') &&
      (safeToLowerCase(row.sexo).includes(safeToLowerCase(filters.sexo)) || filters.sexo === '') &&
      (safeToLowerCase(row.empresa) === (safeToLowerCase(filters.casa)) || filters.casa === '') &&
      (safeToLowerCase(row.cidade).includes(safeToLowerCase(filters.cidade)) || filters.cidade === '') &&
      (safeToLowerCase(row.estado).includes(safeToLowerCase(filters.estado)) || filters.estado === '') &&
      (safeToLowerCase(row.situacao_filiado) === safeToLowerCase(filters.situacao) || filters.situacao === '') &&
      (safeToLowerCase(row.forma_pagamento) === (safeToLowerCase(filters.forma_pagamento)) || filters.forma_pagamento === '') &&
      (safeToLowerCase(row.cadastro) === (safeToLowerCase(filters.cadastro)) || filters.cadastro === '')
    );
  });

  const handleComunicacaoClose = () => {
    setOpen(false);
    setFiliadosState(filiadosState.map((f) => {
      if (f.tableData) {
        f.tableData.checked = false;
      }
      return f;
    }));
  };

  const handleOpenDialog = (tipo, conteudo) => {
    setDialog({ open: true, tipo, conteudo });
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, tipo: '', conteudo: '' });
  };

  const handleOpenFiliado = (selectedFiliado) => {
    handleOpenDialog('Nome Completo', selectedFiliado);
  };

  const handleOpenCargo = (selectedCargo) => {
    handleOpenDialog('Cargo', selectedCargo);
  };

  const handleOpenEndereco = (selectedEndereco) => {
    handleOpenDialog('Endereço', selectedEndereco);
  };

  const handleSwitchChange = (event) => {
    setApllyFilters(event.target.checked)
  }

  const localization = tableI18n;
  localization.toolbar.nRowsSelected = '{0} Filiado(s) selecionado(s)';
  const buttonDisabled = filiadosAComunicar.length === 0;

  return (
    <div className={classes.root}>
      <SindilegisBar />
      <Loading loading={loading} />
      <Card className={classes.paper} elevation={1} hidden={loading}>
        <CardHeader
          title="Comunicação"
          action={
            <ButtonComponent
              variant="insert"
              disabled={buttonDisabled}
              onClick={() => setOpen(true)}
            >
              Comunicar
            </ButtonComponent>
          }
        />
        <Grid item xs={12} md={12}>
          <Grid container style={{ padding: 20, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={apllyFilters} onChange={handleSwitchChange} />}
                label={<Typography variant="body2">Filtragem</Typography>}
                labelPlacement="end"
              />
            </Grid>
          </Grid>
          {apllyFilters && (
            <Grid container spacing={2} style={{ padding: 20 }}>
              <Grid item sm={1}>
                <TextField
                  label="Matrícula"
                  name="matricula"
                  value={filters.matricula}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="Nome Completo"
                  name="nome"
                  value={filters.nome}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  label="CPF"
                  name="cpf"
                  value={formataCPF(filters.cpf)}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  label="Sexo"
                  name="sexo"
                  value={filters.sexo}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {config.sexos.map((p) => (
                    <MenuItem key={p} value={p}>{p}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={4}>
                <TextField
                  label="Email"
                  name="email"
                  value={filters.email}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={2}>
                <TextField
                  label="Casa"
                  name="casa"
                  value={filters.casa}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={'SENADO'}>Senado</MenuItem>
                  <MenuItem value={'TCU'}>TCU</MenuItem>
                  <MenuItem value={'CAMARA'}>Câmara</MenuItem>
                </TextField>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  label="Cargo"
                  name="cargo"
                  value={filters.cargo}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={3}>
                <TextField
                  label="Cidade"
                  name="cidade"
                  value={filters.cidade}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                />
              </Grid>
              <Grid item sm={1}>
                <TextField
                  label="Estado"
                  name="estado"
                  value={filters.estado}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {config.estados.map((p) => (
                    <MenuItem key={p} value={p}>{p}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={2}>
                <TextField
                  label="Situação"
                  name="situacao"
                  value={filters.situacao}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={'ATIVO'}>Ativo</MenuItem>
                  <MenuItem value={'APOSENTADO'}>Aposentado</MenuItem>
                  <MenuItem value={'PENSIONISTA'}>Pensionista</MenuItem>
                  <MenuItem value={'INATIVO'}>Inativo</MenuItem>

                </TextField>
              </Grid>
              <Grid item sm={2}>
                <TextField
                  label="Forma de pagamento"
                  name="forma_pagamento"
                  value={filters.forma_pagamento}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {config.pagamentos.map((p) => (
                    <MenuItem key={p} value={p}>{p}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item sm={2}>
                <TextField
                  label="Cadastro"
                  name="cadastro"
                  value={filters.cadastro}
                  onChange={handleFilterChange}
                  className={classes.filterInput}
                  variant="standard"
                  select
                >
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  <MenuItem value={'FILIADO'}>Filiado</MenuItem>
                  <MenuItem value={'INATIVO'}>Desfiliado</MenuItem>
                </TextField>
              </Grid>
              <Grid item sm={1} >
                <ButtonComponent
                  variant="insert"
                  onClick={handleClearFilters}
                >
                  Limpar
                </ButtonComponent>
              </Grid>
            </Grid>
          )}

          <div className={classes.demo}>
            <MaterialTable
              columns={[
                { title: 'Matrícula', field: 'matricula' },
                {
                  title: 'Nome Completo',
                  field: 'nome_completo',
                  render: (rowData) => (
                    <TooltipText
                      text={rowData.nome_completo}
                      onClick={() => handleOpenFiliado(rowData.nome_completo)}
                    />
                  ),
                },
                { title: 'Email', field: 'email' },
                {
                  title: 'Cargo',
                  field: 'cargo',
                  render: (rowData) => (
                    <TooltipText
                      text={rowData.cargo}
                      onClick={() => handleOpenCargo(rowData.cargo)}
                    />
                  ),
                },
                { title: 'CPF', field: 'cpf' },
                { title: 'Data de nascimento', field: 'data_nascimento' },
                { title: 'Sexo', field: 'sexo' },
                { title: 'Casa', field: 'empresa' },
                { title: 'CEP', field: 'cep' },
                {
                  title: 'Endereço',
                  field: 'logradouro',
                  render: (rowData) => (
                    <TooltipText
                      text={rowData.logradouro}
                      onClick={() => handleOpenEndereco(rowData.logradouro)}
                    />
                  ),
                },
                { title: 'Bairro', field: 'bairro' },
                { title: 'Cidade', field: 'cidade' },
                { title: 'UF', field: 'estado' },
                { title: 'Situação', field: 'situacao_filiado' },
                { title: 'Nome do servidor titular', field: 'nome_falecido' },
                { title: 'CPF do titular', field: 'cpf_falecido' },
                { title: 'Celular', field: 'tel_celular' },
                { title: 'Tel. comercial', field: 'tel_comercial' },
                { title: 'Tel. residencial', field: 'tel_residencial' },
                { title: 'Forma de pagamento', field: 'forma_pagamento' },
                {
                  title: 'Data de cadastro',
                  field: 'data_cadastro',
                  render: (row) => formataData(row.data_cadastro),
                },
                {
                  title: 'Cadastro',
                  field: 'cadastro',
                  lookup: { FILIADO: 'Filiado', INATIVO: 'Desfiliado' },
                },
              ]}
              data={filteredData}
              title=""
              localization={localization}
              options={{
                selection: true,
                padding: 'dense',
              }}
              onSelectionChange={(filiadosAComunicar) => setFiliadosAComunicar(filiadosAComunicar)}
            />
          </div>
        </Grid>
      </Card>
      <ComunicacaoDialog open={open} handleClose={handleComunicacaoClose} />
      <CustomDialog
        open={dialog.open}
        onClose={handleCloseDialog}
        title={dialog.tipo}
        content={dialog.conteudo}
      />
    </div>
  );
};

const mapStateToProps = ({
  filiado: { filiados, loading },
  comunicacao: { filiadosAComunicar },
}) => ({ filiados, loading, filiadosAComunicar });

Comunicacao.propTypes = {
  classes: PropTypes.object.isRequired,
  getFiliados: PropTypes.func.isRequired,
  setFiliadosAComunicar: PropTypes.func.isRequired,
  filiados: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  filiadosAComunicar: PropTypes.array.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getFiliados, setFiliadosAComunicar })
)(Comunicacao);
