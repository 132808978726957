import {
  DEPENDENTS_SET,
  REMOVE_DEPENDENT,
  DEPENDENTES_COM_TITULAR_SET,
  DEPENDENT_UPDATED,
  DEPENDENT_SET,
} from '../actions/types';

const INITIAL_STATE = {
  dependent: null,
  dependents: [],
  dependentesComTitular: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEPENDENTS_SET:
      return { ...state, dependents: action.dependents };
    case DEPENDENT_SET:
      return { ...state, dependent: action.dependent };
    case REMOVE_DEPENDENT: {
      const dependents = state.dependents.filter((d) => d.id !== action.id);
      return { ...state, dependents: dependents };
    }
    case DEPENDENT_UPDATED: {
      const { dependents } = state;
      const { dependent } = action;
      const index = dependents.findIndex((f) => f.id === dependent.id);
      const updated = { ...dependents[index], ...dependent };
      dependents.splice(index, 1, updated);
      return { ...state, dependents: [...dependents] };
    }
    case DEPENDENTES_COM_TITULAR_SET: {
      return { ...state, dependentesComTitular: action.dependentesComTitular };
    }
    default:
      return state;
  }
}
