import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import SindilegisBar from '../commons/SindilegisBar';
import rolePage from './rolePage';
import UnauthorizedPage from '../UnauthorizedPage';

const modules = {
  1: 'Administrativo e Financeiro',
  2: 'Filiados',
  3: 'Jurídico',
  4: 'Sindical',
};

class ProfilePage extends React.Component {
  state = { groupedPages: {} };

  componentDidMount() {
    const { roles } = this.props.authUser;
    const pages = _.uniqBy(
      roles.map((r) => rolePage[r]),
      'name'
    );

    const groupedPages = pages.reduce((acc, page) => {
      const moduleKey = page.modules;
      if (!acc[moduleKey]) {
        acc[moduleKey] = [];
      }
      acc[moduleKey].push(page);
      return acc;
    }, {});

    this.setState({ groupedPages });

    if (pages.length === 1) {
      this.props.history.push(pages[0].route);
    }
  }

  render() {
    const { classes } = this.props;
    const { roles } = this.props.authUser;

    if (roles.length === 0) {
      return <UnauthorizedPage />;
    }

    return (
      <>
        <SindilegisBar />
        <Container container spacing={2} className={classes.conteudo}>
          <Grid>
            {Object.entries(this.state.groupedPages).map(([moduleKey, pages]) => (
              <Box key={moduleKey} my={5} className={classes.homeContent}>
                <Box mb={2}>
                  <Typography variant="h5" color="textPrimary">
                    {modules[moduleKey]}
                  </Typography>
                </Box>
                <Box className={classes.homeButtonGrid}>
                  {pages.map((page) => this.renderCardModules(page))}
                </Box>
              </Box>
            ))}
          </Grid>
        </Container>
      </>
    );
  }

  renderCardModules({ name, route, vector, altText }) {
    const { classes, history } = this.props;
    return (
      <Box className={classes.homeButton} key={route} onClick={() => history.push(route)}>
        <img src={vector} alt={altText} />
        <Typography variant="h6" color="textPrimary">
          {name}
        </Typography>
      </Box>
    );
  }
}

export default compose(withStyles(styles))(withRouter(ProfilePage));
