import React from 'react';
import _ from 'lodash';
import { Route, Redirect } from 'react-router-dom';
import { AuthConsumer } from './AuthContext';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';

function hasRoles(authUser, roles) {
  return !roles || roles.some((role) => _.includes(authUser.roles, role));
}

const ProtectedRoute = ({ component: Component, roles, ...rest }) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
    <AuthConsumer>
      {({ authUser }) => (
        <Route
          render={(props) =>
            authUser && hasRoles(authUser, roles) ? (
              <Component {...props} authUser={authUser} />
            ) : (
              <Redirect to="/" />
            )
          }
          {...rest}
        />
      )}
    </AuthConsumer>
  </MuiPickersUtilsProvider>
);

export default ProtectedRoute;
