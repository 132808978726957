import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../../commons/InputText';
import { withSnackbar } from 'notistack';
import styles from './styles';
import {
  getObjetoProcesso,
  criarObjetoProcesso,
  saveObjetoProcesso,
} from '../../../actions/objetoProcesso';

class ObjetoProcessoForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.objetoProcessoId) this.props.getObjetoProcesso(this.props.objetoProcessoId);
  }

  save(objetoProcesso) {
    const { objetoProcessoId } = this.props;
    if (objetoProcessoId) {
      this.props.saveObjetoProcesso(objetoProcessoId, objetoProcesso, () => this.successHandler());
    } else {
      this.props.criarObjetoProcesso(objetoProcesso, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Objeto de processo salvo com sucesso!', {
      variant: 'success',
    });
    reset('objetoProcesso');
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar objeto de processo.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Funcionário</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6}>
                <Field name="codigo" component={InputText} label="Código" />
              </Grid>
              <Grid item sm={6}>
                <Field name="descricao" component={InputText} label="Descrição" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && this.props.handleClose()} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ObjetoProcessoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['codigo', 'descricao'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = ({ objetoProcesso: { objetoProcesso, objetoProcessoId } }) => ({
  objetoProcesso: objetoProcesso,
  objetoProcessoId: objetoProcessoId,
  initialValues: objetoProcesso,
});

export default compose(
  connect(mapStateToProps, {
    getObjetoProcesso,
    criarObjetoProcesso,
    saveObjetoProcesso,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'objetoProcesso',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ObjetoProcessoForm))
);
