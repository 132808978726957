export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: 20,
    marginTop: 40,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  grow: {
    flexGrow: 1,
  },
  filterInput: {
    width: '100%',
  }
});
