import { db } from '../firebase';
import { doCheckFiliadoProcesso } from '../firebase/db';
import {
  JURIDICO_ATENDIMENTO_ID_SET,
  JURIDICO_ATENDIMENTO_SET,
  JURIDICO_ATENDIMENTO_UPDATED,
  JURIDICO_ATENDIMENTOS_REQUEST,
  JURIDICO_ATENDIMENTOS_SET,
  JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET,
  JURIDICO_ESCRITORIO_ADVOCACIA_SET,
  JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST,
  JURIDICO_ESCRITORIOS_ADVOCACIA_SET,
  JURIDICO_ESCRITORIO_ADVOCACIA_UPDATED,
  DELETE_JURIDICO_ESCRITORIO_ADVOCACIA,
  JURIDICO_PROCESSO_SET,
  JURIDICO_PROCESSO_UPDATED,
  JURIDICO_PROCESSOS_REQUEST,
  JURIDICO_PROCESSOS_SET,
  JURIDICO_PROCESSO_REQUEST,
  FILIADOS_PROCESSO_REQUEST,
  FILIADOS_PROCESSO_SET,
} from './types';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO = 'Aguardando realização';
export const SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO = 'Aguardando conclusão';
export const SITUACAO_ATENDIMENTO_CANCELADO = 'Cancelado';
export const SITUACAO_ATENDIMENTO_CONCLUIDO = 'Concluído';

export const SITUACAO_PROCESSO_EM_ANDAMENTO = 'Em andamento';
export const SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS =
  'Aguardando manifestação do Sindilegis';
export const SITUACAO_PROCESSO_ENCERRADO = 'Encerrado';
export const SITUACAO_PROCESSO_EXCLUIDO = 'Excluído';
export const TIPO_PROCESSO_ADMINISTRATIVO = 'Administrativo';
export const TIPO_PROCESSO_JUDICIAL = 'Judicial';
export const TIPO_PROCESSO_PROCEDIMENTO = 'Procedimento';

export const criarAtendimento = (atendimento, filiados, callback) => {
  return (dispatch) => {
    atendimento.situacao = SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO;
    atendimento.filiados = atendimento.filiados
      .map((f) => f.value)
      .map((id) => {
        let filiado = filiados.find((f) => f.id === id);
        return _.omitBy(
          {
            id: filiado.id,
            nome_completo: filiado.nome_completo,
            email: filiado.email,
            sexo: filiado.sexo,
            cpf: filiado.cpf,
            empresa: filiado.empresa,
            tel_celular: filiado.tel_celular,
            tel_comercial: filiado.tel_comercial,
            tel_residencial: filiado.tel_residencial,
            tel_fixo: filiado.tel_fixo,
          },
          _.isNil
        );
      });
    atendimento.processos = atendimento.processos ? atendimento.processos.map((p) => p.value) : [];

    db.doCreateAtendimento(atendimento.escritorio.id, atendimento).then(() => {
      callback && callback();
    });
  };
};

export const atualizarAtendimento = (atendimento, filiados, callback) => {
  return (dispatch) => {
    atendimento.filiados = atendimento.filiados
      .map((f) => f.value)
      .map((id) => {
        let filiado = filiados.find((f) => f.id === id);
        return _.omitBy(
          {
            id: filiado.id,
            nome_completo: filiado.nome_completo,
            email: filiado.email,
            sexo: filiado.sexo,
            cpf: filiado.cpf,
            empresa: filiado.empresa,
            tel_celular: filiado.tel_celular,
            tel_comercial: filiado.tel_comercial,
            tel_residencial: filiado.tel_residencial,
            tel_fixo: filiado.tel_fixo,
          },
          _.isNil
        );
      });
    atendimento.processos = atendimento.processos ? atendimento.processos.map((p) => p.value) : [];
    db.doUpdateAtendimento(atendimento).then(() => {
      callback && callback();
    });
  };
};

export const getAtendimentos = () => {
  return (dispatch) => {
    dispatch({ type: JURIDICO_ATENDIMENTOS_REQUEST });
    db.onceGetAtendimentos().then((snapshot) => {
      let atendimentos = snapshot.val() || {};
      let itens = {};
      Object.keys(atendimentos).forEach((key) => {
        let nestedItens = {};
        Object.keys(atendimentos[key]).forEach((nKey) => {
          nestedItens = {
            ...nestedItens,
            [nKey]: { id: nKey, ...atendimentos[key][nKey] },
          };
        });
        itens = { ...itens, [key]: nestedItens };
      });
      dispatch({ type: JURIDICO_ATENDIMENTOS_SET, atendimentos: itens });
    });
  };
};

export const updateAtendimentosByEscritorio = async (escritorio) => {
  try {
    const snapshot = await db.onceGetAtendimentosByEscritorio(escritorio.id);
    const atendimentos = snapshot.val() || {};

    if (atendimentos) {
      const atendimentosFiltrados = Object.keys(atendimentos)
        .filter((key) => {
          const situacao = atendimentos[key].situacao;
          return situacao !== 'Concluído' && situacao !== 'Cancelado';
        })
        .map((key) => ({ id: key, ...atendimentos[key] }));

      for (const atendimento of atendimentosFiltrados) {
        await db.doUpdateAtendimento({ id: atendimento.id, escritorio });
      }
    }
    return atendimentos;
  } catch (error) {
    console.error('Erro ao obter/atualizar atendimentos:', error);
  }
};

export const updateProcessosByEscritorio = async (escritorio) => {
  try {
    const snapshot = await db.onceGetProcessosByEscritorio(escritorio.id);
    const processos = snapshot.val() || {};

    if (processos) {
      const processosFiltrados = Object.keys(processos)
        .filter((key) => processos[key].situacao === 'Em andamento')
        .map((key) => ({ id: key, ...processos[key] }));

      for (const processo of processosFiltrados) {
        await db.doUpdateProcesso({ id: processo.id, escritorio });
      }
    }
    return processos;
  } catch (error) {
    console.error('Erro ao obter/atualizar processos:', error);
  }
};

export const cancelarAtendimento = (atendimento) => {
  return (dispatch) => {
    atendimento.situacao = SITUACAO_ATENDIMENTO_CANCELADO;
    db.doUpdateAtendimento(atendimento).then(() => {
      dispatch({
        type: JURIDICO_ATENDIMENTO_UPDATED,
        payload: {
          escritorioAdvocaciaId: atendimento.escritorio.id,
          id: atendimento.id,
          atendimento,
        },
      });
    });
  };
};

export const cancelarConclusaoAtendimento = (atendimento) => {
  return (dispatch) => {
    atendimento.situacao = SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO;
    atendimento = _.omit(atendimento, 'conclusao');
    db.doUpdateAtendimento(atendimento).then(() => {
      dispatch({
        type: JURIDICO_ATENDIMENTO_UPDATED,
        payload: {
          escritorioAdvocaciaId: atendimento.escritorio.id,
          id: atendimento.id,
          atendimento,
        },
      });
    });
  };
};

export const registrarRealizacaoAtendimento = (atendimento, conclusao, callback) => {
  return (dispatch) => {
    if (conclusao.ocorreuAtendimento === 'Sim') {
      atendimento.situacao = SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO;
    } else {
      atendimento.situacao = SITUACAO_ATENDIMENTO_CONCLUIDO;
    }
    //Limpa seraConstituidoNovoProcesso caso o campo tenha sido escondido com o valor preenchido
    if (conclusao.ocorreuAtendimento !== 'Sim' && conclusao.seraConstituidoNovoProcesso) {
      conclusao = _.omit(conclusao, 'seraConstituidoNovoProcesso');
    }
    atendimento.conclusao = conclusao;
    if (conclusao.advogadoResponsavel) {
      conclusao.advogadoResponsavel = {
        nome: conclusao.advogadoResponsavel.displayName,
        id: conclusao.advogadoResponsavel.id,
      };
    }
    db.doUpdateAtendimento(atendimento)
      .then(() => {
        dispatch({
          type: JURIDICO_ATENDIMENTO_UPDATED,
          payload: {
            escritorioAdvocaciaId: atendimento.escritorio.id,
            id: atendimento.id,
            atendimento,
          },
        });
        callback();
      })
      .catch((error) => console.error(error));
  };
};

export const concluirAtendimento = (atendimento, conclusao, callback) => {
  return (dispatch) => {
    atendimento.situacao = SITUACAO_ATENDIMENTO_CONCLUIDO;
    atendimento.conclusao.processos = conclusao.processos ? conclusao.processos : [];
    db.doUpdateAtendimento(atendimento)
      .then(() => {
        dispatch({
          type: JURIDICO_ATENDIMENTO_UPDATED,
          payload: {
            escritorioAdvocaciaId: atendimento.escritorio.id,
            id: atendimento.id,
            atendimento,
          },
        });
        callback();
      })
      .catch((error) => console.error(error));
  };
};

export const setAtendimento = (atendimento) => (dispatch) =>
  dispatch({ type: JURIDICO_ATENDIMENTO_SET, atendimento });

export const setAtendimentoId = (atendimentoId) => (dispatch) =>
  dispatch({
    type: JURIDICO_ATENDIMENTO_ID_SET,
    atendimentoId,
  });

export const getEscritoriosAdvocacia = () => {
  return (dispatch) => {
    dispatch({ type: JURIDICO_ESCRITORIOS_ADVOCACIA_REQUEST });
    db.onceGetEscritoriosAdvocacia().then((snapshot) => {
      let escritoriosAdvocacia = snapshot.val() || {};
      Object.keys(escritoriosAdvocacia).map((id) => {
        escritoriosAdvocacia[id].id = id;
        return escritoriosAdvocacia[id];
      });
      escritoriosAdvocacia = _.values(escritoriosAdvocacia);
      dispatch({
        type: JURIDICO_ESCRITORIOS_ADVOCACIA_SET,
        escritoriosAdvocacia,
      });
    });
  };
};

export const getEscritorioAdvocacia = (id) => {
  return (dispatch) => {
    return db.onceGetEscritorioAdvocacia(id).then((snapshot) => {
      dispatch({ type: JURIDICO_ESCRITORIO_ADVOCACIA_SET, escritorioAdvocacia: snapshot.val() });
    });
  };
};

export const saveEscritorioAdvocacia = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveEscritorioAdvocacia(id, data).then(() => {
      dispatch({ type: JURIDICO_ESCRITORIO_ADVOCACIA_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarEscritorioAdvocacia = (data, callback) => {
  return (dispatch) => {
    db.doCreateEscritorioAdvocacia(data).then(() => {
      callback && callback();
    });
  };
};

export const deleteEscritorioAdvocacia = (id) => {
  return (dispatch) => {
    if (id) {
      db.doDeleteEscritorioAdvocacia(id).then(() => {
        dispatch({ type: DELETE_JURIDICO_ESCRITORIO_ADVOCACIA, payload: { id } });
      });
    } else {
      throw new Error('Empty Id');
    }
  };
};

export const setEscritorioAdvocacia = (escritorioAdvocacia) => (dispatch) =>
  dispatch({
    type: JURIDICO_ESCRITORIO_ADVOCACIA_SET,
    escritorioAdvocacia,
  });

export const setEscritorioAdvocaciaId = (escritorioAdvocaciaId) => (dispatch) =>
  dispatch({
    type: JURIDICO_ESCRITORIO_ADVOCACIA_ID_SET,
    escritorioAdvocaciaId,
  });

const verificarRelacionamentoExistente = async (filiadoId, idProcessoFiliado) => {
  const relacionamentoExiste = await doCheckFiliadoProcesso(filiadoId, idProcessoFiliado);
  return relacionamentoExiste;
};

export const criarRelacionamentoFiliadosProcessos = async (doCreateFiliadoProcesso) => {
  try {
    const snapshot = await db.onceGetProcessos();
    const processos = snapshot.val() || {};

    for (const processoKey in processos) {
      const processo = processos[processoKey];
      if (processo.situacao !== SITUACAO_PROCESSO_EXCLUIDO) {
        const filiados = processo.filiados;
        if (filiados && filiados.length > 0) {
          for (const filiado of filiados) {
            const filiadoId = filiado.id;
            const idProcessoFiliado = processoKey;
            const situacaoProcesso = processo.situacao;
            const relacionamentoExiste = await verificarRelacionamentoExistente(
              filiadoId,
              idProcessoFiliado
            );
            if (!relacionamentoExiste) {
              await doCreateFiliadoProcesso(filiadoId, idProcessoFiliado, situacaoProcesso);
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const getFiliadosProcesso = () => {
  return (dispatch) => {
    dispatch({ type: FILIADOS_PROCESSO_REQUEST });
    db.onceGetFiliadosProcesso().then((snapshot) => {
      let filiadosProcesso = snapshot.val() || {};
      Object.keys(filiadosProcesso).map((id) => {
        filiadosProcesso[id].id = id;
        return filiadosProcesso[id];
      });
      filiadosProcesso = _.values(filiadosProcesso);
      dispatch({
        type: FILIADOS_PROCESSO_SET,
        filiadosProcesso,
      });
    });
  };
};

export const criarProcessoAdministrativo = (
  processoAdministrativo,
  filiados,
  escritoriosAdvocacia,
  callback
) => {
  return (dispatch) => {
    criarProcesso(
      TIPO_PROCESSO_ADMINISTRATIVO,
      processoAdministrativo,
      filiados,
      escritoriosAdvocacia,
      callback
    );
  };
};

export const criarProcessoJudicial = (
  processoJudicial,
  filiados,
  escritoriosAdvocacia,
  callback
) => {
  return (dispatch) => {
    criarProcesso(
      TIPO_PROCESSO_JUDICIAL,
      processoJudicial,
      filiados,
      escritoriosAdvocacia,
      callback
    );
  };
};

export const criarProcedimento = (procedimento, filiados, escritoriosAdvocacia, callback) => {
  return (dispatch) => {
    criarProcesso(
      TIPO_PROCESSO_PROCEDIMENTO,
      procedimento,
      filiados,
      escritoriosAdvocacia,
      callback
    );
  };
};

const criarProcesso = (tipoProcesso, processo, filiados, escritoriosAdvocacia, callback) => {
  processo.tipo = tipoProcesso;
  processo.situacao = SITUACAO_PROCESSO_EM_ANDAMENTO;
  processo.escritorio = escritoriosAdvocacia.find((e) => e.id === processo.escritorio);

  if (processo.filiados) {
    processo.filiados = processo.filiados
      .map((f) => f.value)
      .map((id) => {
        let filiado = filiados.find((f) => f.id === id);
        return _.omitBy(
          {
            id: filiado.id,
            nome_completo: filiado.nome_completo,
            email: filiado.email,
            sexo: filiado.sexo,
            cpf: filiado.cpf,
            empresa: filiado.empresa,
            tel_celular: filiado.tel_celular,
            tel_comercial: filiado.tel_comercial,
            tel_residencial: filiado.tel_residencial,
          },
          _.isNil
        );
      });
  }

  if (processo.tipo === TIPO_PROCESSO_PROCEDIMENTO) {
    db.getNumeroProcedimento().then((numero) => {
      processo.numeroProcesso = numero;
      db.doCreateProcesso(processo).then(() => {
        callback && callback();
      });
    });
  } else {
    db.doCreateProcesso(processo).then(() => {
      callback && callback();
    });
  }
};

export const atualizarProcesso = (processo, filiados, escritoriosAdvocacia, callback) => {
  return (dispatch) => {
    processo.escritorio = escritoriosAdvocacia.find((e) => e.id === processo.escritorio);
    const filiadosProcesso = processo.filiados
    if (processo.filiados) {
      processo.filiados = processo.filiados
        .map((f) => f.value)
        .map((id) => {
          let filiado = filiados.find((f) => f.id === id);
          return _.omitBy(
            {
              id: filiado?.id,
              nome_completo: filiado?.nome_completo,
              email: filiado?.email,
              sexo: filiado?.sexo,
              cpf: filiado?.cpf,
              tel_celular: filiado?.tel_celular,
              tel_comercial: filiado?.tel_comercial,
              tel_residencial: filiado?.tel_residencial,
            },
            _.isNil
          );
        });
      for (const filiado of filiadosProcesso) {
        db.doCreateFiliadoProcesso(filiado.id, processo.id, processo.situacao);
      }
    }

    db.doUpdateProcesso(processo).then(() => {
      callback && callback();
    });
  };
};

export const adicionarEventoProcesso = (processo, evento, anexos, callback) => {
  return (dispatch) => {
    if (!processo.eventos) {
      processo.eventos = [];
    }

    const eventoComID = { ...evento, id: uuidv4() };

    eventoComID.data = new Date().getTime();
    eventoComID.anexos = anexos;

    processo.eventos.push(eventoComID);

    processo.eventos.forEach((eventoExistente) => {
      if (!eventoExistente.id) {
        eventoExistente.id = uuidv4();
      }
    });

    db.doUpdateProcesso(processo).then(() => {
      callback && callback();
    });
  };
};

export const editarEventoProcesso = (processo, eventoId, novosDados, anexos, callback) => {
  return (dispatch) => {
    if (!processo.eventos) {
      console.error('Processo não contém eventos.');
      return;
    }

    const eventoIndex = processo.eventos.findIndex((evento) => evento.id === eventoId);

    if (eventoIndex === -1) {
      console.error('Evento não encontrado.');
      return;
    }

    processo.eventos[eventoIndex] = {
      ...processo.eventos[eventoIndex],
      ...novosDados,
      anexos: anexos,
    };

    db.doUpdateProcesso(processo).then(() => {
      callback && callback();
    });
  };
};

export const adicionarIDsParaEventos = (processos) => {
  return (dispatch) => {
    processos.forEach((processo) => {
      if (processo.eventos && processo.eventos.length > 0) {
        processo.eventos.forEach((evento) => {
          if (!evento.id) {
            evento.id = uuidv4();
          }
        });
      }
      db.doUpdateProcesso(processo);
    });
  };
};

export const incluirAnexoEvento = (id, path, processoId) => {
  return (dispatch) => {
    db.doIncluirAnexoEvento(id, path, processoId);
  };
};

export const deleteEventoProcesso = (id, processoId) => {
  return (dispatch) => {
    db.doDeleteEventoProcesso(id, processoId);
  };
};

export const getEventoProcesso = (id, processoId) => {
  return (dispatch) => {
    db.onceGetEventoProcesso(id, processoId).then((snapshot) => {
      const evento = snapshot.val() || {};
      return evento;
    });
  };
};

export const adicionarComentarioProcesso = (processo, comentario, email, callback) => {
  return (dispatch) => {
    if (!processo.comentarios) {
      processo.comentarios = [];
    }
    let objeto = {};
    objeto.data = new Date().getTime();
    objeto.comentario = comentario;
    objeto.email = email;

    processo.comentarios.push(objeto);

    db.doUpdateProcesso(processo).then(() => {
      callback && callback();
    });
  };
};

export const adicionarManifestacao = (processo, manifestacao, email, callback) => {
  return () => {
    processo.situacao = SITUACAO_PROCESSO_EM_ANDAMENTO;

    if (!processo.manifestacoes) {
      processo.manifestacoes = [];
    }

    processo.manifestacoes.push({
      data: new Date().getTime(),
      manifestacao,
      email,
    });

    db.doUpdateProcesso(processo).then(() => {
      callback && callback();
    });
  };
};

export const adicionarComentarioAtendimento = (atendimento, comentario, email, callback) => {
  return (dispatch) => {
    if (!atendimento.comentarios) {
      atendimento.comentarios = [];
    }
    let objeto = {};
    objeto.data = new Date().getTime();
    objeto.comentario = comentario;
    objeto.email = email;

    atendimento.comentarios.push(objeto);

    db.doUpdateAtendimento(atendimento).then(() => {
      callback && callback();
    });
  };
};

export const getProcessos = (escritorioId) => {
  return (dispatch) => {
    dispatch({ type: JURIDICO_PROCESSOS_REQUEST });
    db.onceGetProcessos().then((snapshot) => {
      let processos = snapshot.val() || {};
      Object.keys(processos).map((id) => {
        processos[id].id = id;
        return processos[id];
      });
      processos = _.values(processos);
      if (escritorioId) {
        processos = processos.filter((processo) => processo.escritorioId === escritorioId);
      }

      processos.map((p) => {
        if (!p.escritorio && p.escritoriosAdvocacia) {
          p.escritorio = p.escritoriosAdvocacia[0];
        }
        return p;
      });
      dispatch({ type: JURIDICO_PROCESSOS_SET, processos });
    });
  };
};

export const getProcesso = (processoId) => {
  return (dispatch) => {
    dispatch({ type: JURIDICO_PROCESSO_REQUEST });
    db.onceGetProcesso(processoId).then((snapshot) => {
      const processo = snapshot.val();
      if (processo) {
        processo.id = processoId;
        dispatch({ type: JURIDICO_PROCESSO_SET, processo });
      }
    });
  };
};

export const encerrarProcesso = (processo) => {
  return (dispatch) => {
    processo.situacao = SITUACAO_PROCESSO_ENCERRADO;
    db.doUpdateProcesso(processo).then(() => {
      dispatch({ type: JURIDICO_PROCESSO_UPDATED, payload: { processo } });
    });
  };
};

export const reabrirProcesso = (processo) => {
  return (dispatch) => {
    processo.situacao = SITUACAO_PROCESSO_EM_ANDAMENTO;
    db.doUpdateProcesso(processo).then(() => {
      dispatch({ type: JURIDICO_PROCESSO_UPDATED, payload: { processo } });
    });
  };
};

export const enviarParaManifestacaoSindilegis = (processo) => {
  return (dispatch) => {
    processo.situacao = SITUACAO_PROCESSO_AGUARDANDO_MANIFESTACAO_SINDILEGIS;
    db.doUpdateProcesso(processo).then(() => {
      dispatch({ type: JURIDICO_PROCESSO_UPDATED, payload: { processo } });
    });
  };
};

export const excluirProcesso = (processo) => {
  return (dispatch) => {
    processo.situacao = SITUACAO_PROCESSO_EXCLUIDO;
    db.doUpdateProcesso(processo).then(() => {
      dispatch({ type: JURIDICO_PROCESSO_UPDATED, payload: { processo } });

      db.doDeleteFiliadosProcesso(processo.id);
    });
  };
};

export const setProcesso = (processo) => (dispatch) =>
  dispatch({ type: JURIDICO_PROCESSO_SET, processo });
