import React from 'react';
import {
  SITUACAO_ATENDIMENTO_CANCELADO,
  SITUACAO_ATENDIMENTO_CONCLUIDO,
  SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
} from '../../../actions/juridico';
import { matchFullName } from '../../commons/util';
import FiliadoPopover from '../filiadoPopover';

export const tiposAtendimento = ['Virtual', 'Presencial'];

export function getColumns(situacao, filiados) {
  let columns = [
    {
      title: 'Atendimento',
      field: 'numero',
    },
    {
      title: 'Data/Hora',
      field: 'dataHora',
    },
    {
      title: 'data',
      field: 'data',
      hidden: true,
    },
    {
      title: 'Tipo',
      field: 'tipoAtendimento',
    },
    {
      title: 'Filiados',
      field: 'filiados',
      noExport: true,
      customFilterAndSearch: (term, row) =>
        matchFullName(
          term,
          row.filiados ? row.filiados.map((f) => f.nome_completo).join(', ') : ''
        ),
      render: (row) =>
        row.filiados
          ? row.filiados.map((f) => {
              const filiado = filiados.find((it) => it.id === f.id);
              return filiado ? <FiliadoPopover filiado={filiado} key={filiado.id} /> : null;
            })
          : '',
    },
    {
      title: 'Filiados',
      field: 'nomeFiliados',
      hidden: true,
    },
    {
      title: 'Comentários',
      field: 'comentarios',
      noExport: true,
      customFilterAndSearch: (term, row) =>
        matchFullName(
          term,
          row.comentarios ? row.comentarios.map((row) => row.comentario).join(', ') : ''
        ),
      render: (row) =>
        row.comentarios ? row.comentarios.map((row) => row.comentario).join(', ') : '',
    },
    {
      title: 'Comentários',
      field: 'todosComentarios',
      hidden: true,
    },
    {
      title: 'Escritório',
      field: 'escritorio',
      noExport: true,
      customFilterAndSearch: (term, row) => matchFullName(term, row.escritorio.nome),
      render: (row) => row.escritorio.nome,
    },
    {
      title: 'Escritório',
      field: 'nomeEscritorio',
      hidden: true,
    },
    {
      title: 'Descrição',
      field: 'descricao',
    },
    {
      title: 'Advogado responsável atendimento',
      field: 'conclusao.advogadoResponsavel.nome',
    },
    {
      title: 'Ocorreu atendimento?',
      field: 'ocorreuAtendimento',
    },
    {
      title: 'Comentários atendimento',
      field: 'comentariosConclusao',
    },
    {
      title: 'Há proc/proced relacionado?',
      field: 'processoEmCurso',
    },
    {
      title: 'Necessidade de proc/proced?',
      field: 'seraConstituidoNovoProcesso',
    },
  ];

  if (situacao === SITUACAO_ATENDIMENTO_CANCELADO) {
    columns = columns.filter(
      (c) =>
        !['processoEmCurso', 'seraConstituidoNovoProcesso', 'ocorreuAtendimento'].includes(c.field)
    );
  }

  if (
    [SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO, SITUACAO_ATENDIMENTO_CONCLUIDO].includes(situacao)
  ) {
    columns = columns.filter(
      (c) =>
        ![
          'seraConstituidoNovoProcesso',
          'ocorreuAtendimento',
          'conclusao.advogadoResponsavel.nome',
        ].includes(c.field)
    );
  }

  return columns;
}
