import React from 'react';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default (props) => {
  const { loading, height } = props;
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? '200ms' : '0ms',
      }}
      unmountOnExit>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={height ? { minHeight: height } : { minHeight: '100vh' }}>
        <CircularProgress />
      </Grid>
    </Fade>
  );
};
