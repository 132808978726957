import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import addMonths from 'date-fns/addMonths';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

const dateFns = new DateFnsUtils({ locale: ptLocale });
const today = dateFns.startOfDay(new Date());

export const formatarValor = (valor) => {
  return (
    <NumberFormat
      value={valor}
      displayType={'text'}
      isNumericString={true}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={true}
      fixedDecimalScale={true}
      prefix={'R$ '}
    />
  );
};

export const dataPorExtenso = (date) => {
  return dateFns.format(date, `dd 'de' MMMM 'de' yyyy`);
};

export const somadorDouble = (a, b) => (a * 1000 + b * 1000) / 1000;

export const subtrair = (valor1, valor2) => valor1 - valor2;

export const calcularEstorno = ({ valor_estorno, valor }) =>
  valor_estorno ? valor - valor_estorno : valor;

export const getDataFinalProjecao = () => {
  const dataFinal = getDataFinal();
  return addMonths(dataFinal, 12);
};

export const getDataInicialProjecao = () => {
  const dataInicial = getDataInicial();
  return addMonths(dataInicial, -12);
};

export const getDataFinal = () => {
  const month = today.getDate() > 20 ? today.getMonth() + 1 : today.getMonth();
  return dateFns.endOfDay(new Date(today.getFullYear(), month, 20));
};

export const getDataInicial = () => {
  const month = today.getDate() < 21 ? today.getMonth() - 1 : today.getMonth();
  return dateFns.startOfDay(new Date(today.getFullYear(), month, 21));
};

export const getDataInicialJuridico = () => {
  return dateFns.startOfDay(new Date());
};

export const getDataFinalJuridico = () => {
  return dateFns.endOfDay(new Date());
};

export const getCorDespesa = (data_vencimento, data_liquidacao) => {
  if (data_liquidacao) {
    return '#54B160';
  }

  if (data_vencimento < today.getTime()) {
    return '#FF2222';
  } else if (dateFns.startOfDay(new Date(data_vencimento)).getTime() === today.getTime()) {
    return '#00BCD4';
  } else {
    return 'rgba(0, 0, 0, 0.87)';
  }
};

export const converterParaNumero = (valor) => {
  if (!valor) {
    return 0;
  }
  if (typeof valor === 'number') {
    return valor;
  }

  valor = valor.replace(/,/g, '.');
  if (valor.indexOf('.') < 0) {
    valor = valor + '.00';
  } else {
    while (valor.split('.').length > 2) {
      valor = valor.replace('.', '');
    }
  }
  if (valor.substring(valor.indexOf('.') + 1).length === 1) {
    valor = valor + '0';
  }
  const arr = valor.split('.');
  valor = arr[0].substring(0, 15) + '.' + arr[1].substring(0, 2);

  return Number(valor);
};

export const formataData = (data) => {
  if (data) {
    try {
      return dateFns.format(new Date(data), 'dd/MM/yyyy');
    } catch (e) {
      console.error('Data inválida: ', data);
    }
  }
  return '';
};

export const formataDataHora = (dataHora) => {
  if (dataHora) {
    try {
      return dateFns.format(new Date(dataHora), 'HH:mm:ss, dd/MM/yyyy');
    } catch (e) {
      console.error('Data inválida: ', dataHora);
    }
  }
  return '';
};

export const formatarData = (data, formato) => {
  if (data) {
    try {
      return dateFns.format(new Date(data), formato);
    } catch (e) {
      console.error('Data inválida: ', data);
    }
  }
  return '';
};

export const formataHora = (hora) => {
  if (hora) {
    try {
      return dateFns.format(new Date(hora), 'HH:mm');
    } catch (e) {
      console.error('Hora inválida: ', hora);
    }
  }
  return '';
};

export const getMesAno = (data) => {
  const today = new Date(data);
  let month = today.getDate() <= 20 ? today.getMonth() : today.getMonth() + 1;
  let year = today.getFullYear();
  if (month === 0) {
    month = 12;
    --year;
  }

  return `${month}/${year}`;
};

export const getMesAnoProjecao = (data) => {
  const ar = formataData(data)
    .split('/')
    .map((ma) => parseInt(ma));
  const day = ar[0];
  let month = ar[1];
  let year = ar[2];

  if (day >= 21) {
    ++month;
    if (month > 12) {
      month = 1;
      ++year;
    }
  }

  return `${month}/${year}`;
};

export const getAnoProjecao = (data) => {
  const ar = formataData(data)
    .split('/')
    .map((ma) => parseInt(ma));
  const day = ar[0];
  let month = ar[1];
  let year = ar[2];

  if (day >= 21) {
    ++month;
    if (month > 12) {
      month = 1;
      ++year;
    }
  }

  return `${year}`;
};

export const mesAnoToPeriodo = (mesAno) => {
  const ar = mesAno.split('/').map((ma) => parseInt(ma));
  let year = ar[1];
  let month = ar[0];
  month--;

  if (month === 0) {
    month = 12;
    --year;
  }

  const fromDate = dateFns.startOfDay(new Date(year, month - 1, 21));
  const toDate = dateFns.endOfDay(new Date(year, month, 20));

  return { fromDate, toDate };
};

export const dataHoraSort = (a, b) => {
  const dataHoraA = new Date(
    `${formatarData(a.data, 'yyyy-MM-dd')}T${formataHora(a.horaInicio)}:00`
  ).getTime();
  const dataHoraB = new Date(
    `${formatarData(b.data, 'yyyy-MM-dd')}T${formataHora(b.horaInicio)}:00`
  ).getTime();
  if (dataHoraA > dataHoraB) {
    return 1;
  }
  if (dataHoraA < dataHoraB) {
    return -1;
  }
  return 0;
};

export const dateFieldSort = (a, b, fieldName) => {
  const defaultDate = '01/01/1900';
  const toDateOrDefault = (date) =>
    _.isEmpty(date)
      ? new Date(defaultDate.split('/')[2], defaultDate.split('/')[1], defaultDate.split('/')[0])
      : new Date(date.split('/')[2], date.split('/')[1], date.split('/')[0]);

  const dataHoraA = toDateOrDefault(a[fieldName]).getTime();
  const dataHoraB = toDateOrDefault(b[fieldName]).getTime();
  if (dataHoraA > dataHoraB) {
    return 1;
  }
  if (dataHoraA < dataHoraB) {
    return -1;
  }
  return 0;
};
