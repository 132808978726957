import { COMUNICACAO_FILIADOS_SET } from './types';
import { enviarComunicacaoPorEmail, enviarComunicacaoPorWhatsapp } from '../firebase/functions';

export const comunicarPorEmail = (
  remetente,
  filiadosAComunicar,
  titulo,
  mensagem,
  callbackSucesso,
  callbackErro
) => {
  return (dispatch) => {
    enviarComunicacaoPorEmail({
      remetente,
      destinatarios: filiadosAComunicar.map((f) => f.email),
      titulo,
      mensagem,
    })
      .then((_) => {
        dispatch({ type: COMUNICACAO_FILIADOS_SET, filiadosAComunicar: [] });
        callbackSucesso();
      })
      .catch((e) => {
        console.error(e);
        callbackErro();
      });
  };
};

export const comunicarPorWhatsapp = (
  filiadosAComunicar,
  template,
  callbackSucesso,
  callbackErro
) => {
  return (dispatch) => {
    enviarComunicacaoPorWhatsapp({
      filiados: filiadosAComunicar.map((f) => f.tel_celular),
      template,
    })
      .then((_) => {
        dispatch({ type: COMUNICACAO_FILIADOS_SET, filiadosAComunicar: [] });
        callbackSucesso();
      })
      .catch((e) => {
        console.error(e);
        callbackErro();
      });
  };
};

export const setFiliadosAComunicar = (filiadosAComunicar) => {
  return (dispatch) => {
    dispatch({ type: COMUNICACAO_FILIADOS_SET, filiadosAComunicar });
  };
};
