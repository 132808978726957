/* eslint-disable no-const-assign */
import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Grid, Box, Container, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { getFiliados } from '../../actions/filiado';
import { enviarPesquisa } from '../../actions/satisfacao';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import ButtomComponent from '../commons/ButtonComponent';

class Satisfacao extends React.Component {
  state = {
    selectedOption: null,
    filiados: [],
    filiadosAComunicar: [],
  };

  componentDidMount() {
    this.props.getFiliados();
  }

  handleSelect = (option) => {
    this.setState({ selectedOption: option });
  };

  handleSendPesquisa = (filiadosAComunicar) => {
    const { enqueueSnackbar } = this.props;
    const { selectedOption } = this.state;

    const clearStates = () => {
      this.setState({
        filiados: this.state.filiados.map((f) => {
          if (f.tableData) {
            f.tableData.checked = false;
          }
          return f;
        }),
        selectedOption: null,
      });
    };

    const filiadosArray = filiadosAComunicar.filiadosAComunicar.length;
    if (filiadosArray < 1) {
      enqueueSnackbar('Você precisa selecionar pelo menos um(a) filiado(a)!', { variant: 'error' });
      clearStates();
    } else if (!selectedOption) {
      enqueueSnackbar('Você precisa selecionar pelo menos uma pesquisa!', { variant: 'error' });
      clearStates();
    } else {
      this.props.enviarPesquisa(filiadosAComunicar);
      enqueueSnackbar('Pesquisa enviada com sucesso!', { variant: 'success' });
      clearStates();
    }
  };

  render() {
    const { classes, loading } = this.props;
    const { selectedOption, filiadosAComunicar } = this.state;

    const localization = tableI18n;
    localization.toolbar.nRowsSelected = `{0} Filiado(s) selecionado(s)`;
    const filiados = this.props.filiados.filter((filiado) => filiado.cadastro === 'FILIADO');
    if (this.state.filiados.length === 0 && filiados.length > 0) {
      this.setState({ filiados });
    }

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Paper elevation={3} style={{ padding: 3, mt: 4, marginTop: 20 }}>
            <Typography variant="h5" align="start" gutterBottom style={{ padding: 20 }}>
              Envio de Pesquisa de Satisfação
            </Typography>
            <Grid container spacing={2}>
              <Grid container item spacing={3} style={{ marginLeft: 5 }}>
                <Grid item xs={12} sm={2}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    {['juridico', 'odonto', 'contabil', 'psicologico'].map((option) => (
                      <ButtomComponent
                        key={option}
                        variant={selectedOption === option ? 'insert' : 'default'}
                        onClick={() => this.handleSelect(option)}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </ButtomComponent>
                    ))}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <MaterialTable
                    columns={[
                      { title: 'Nome Completo', field: 'nome_completo' },
                      { title: 'Email', field: 'email' },
                      { title: 'CPF', field: 'cpf' },
                      { title: 'Sexo', field: 'sexo' },
                      { title: 'Casa', field: 'empresa' },
                      { title: 'UF', field: 'estado' },
                    ]}
                    data={this.state.filiados}
                    title="Filiados"
                    localization={localization}
                    options={{
                      selection: true,
                      padding: 'dense',
                    }}
                    onSelectionChange={(filiadosAComunicar) => {
                      this.setState({ filiadosAComunicar });
                    }}
                  />
                  <Grid item container justifyContent="flex-end" style={{ padding: 20 }}>
                    <ButtomComponent
                      variant="insert"
                      onClick={() => {
                        const dados = {
                          selectedOption,
                          filiadosAComunicar: filiadosAComunicar.map((filiado) => filiado.email),
                        };
                        this.handleSendPesquisa(dados);
                      }}>
                      Enviar
                    </ButtomComponent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ filiado: { filiados } }) => ({ filiados });

Satisfacao.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getFiliados, enviarPesquisa })
)(withRouter(withSnackbar(Satisfacao)));
