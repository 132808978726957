import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import {
  setTema,
  setTemaId,
  setTemaStep,
  getTotalTemasSituacao,
  descartarTotalTemasSituacao,
  getTemas,
} from '../../actions/politico_sindical';
import TemaWizardFormDialog from './TemaWizardFormDialog';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { steps } from '../../controllers/temaController';
import * as roles from '../../constants/roles';
import * as routes from '../../constants/routes';

class TemaDashboard extends React.Component {
  state = {
    open: false,
  };

  componentDidMount() {
    this.props.getTotalTemasSituacao();
    this.props.getTemas();
  }

  componentWillUnmount() {
    this.props.descartarTotalTemasSituacao();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.temas.length, this.props.temas.length)) {
      this.props.getTotalTemasSituacao();
    }
  }

  handleTemaClickOpen = () => {
    this.setState({ open: true });
  };

  handleTemaClose = (reload) => {
    this.setState({ open: false });
    this.props.setTema(null);
    this.props.setTemaId(null);
    if (reload) {
      this.reloadTotalizadoresTemas();
    }
  };

  reloadTotalizadoresTemas() {
    this.props.getTotalTemasSituacao();
  }

  handleReload = () => this.reloadTotalizadoresTemas();

  renderCardHeader() {
    return <CardHeader title="Político sindical" />;
  }

  renderSumarioAdministrativoGrid(key) {
    const { classes, temas } = this.props;
    const temasAdministrativosEmAcompanhamento = temas.filter(
      (tema) => tema.tipo_tema === 'Administrativo' && tema.situacao === 'EM_ACOMPANHAMENTO'
    );
    const temasAdministrativosEncerrados = temas.filter(
      (tema) => tema.tipo_tema === 'Administrativo' && tema.situacao === 'ENCERRADO'
    );

    return (
      <>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={() =>
            temasAdministrativosEmAcompanhamento.length > 0
              ? this.props.history.push(
                  `${routes.POLITICO_SINDICAL_TEMAS}?situacao=EM_ACOMPANHAMENTO&tipo_tema=Administrativo`
                )
              : null
          }>
          <Grid item xs={8} md={8} className={classes.padding16}>
            <Typography variant="body1">Em acompanhamento</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.padding16}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {temasAdministrativosEmAcompanhamento.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={() =>
            temasAdministrativosEncerrados.length > 0
              ? this.props.history.push(
                  `${routes.POLITICO_SINDICAL_TEMAS}?situacao=ENCERRADO&tipo_tema=Administrativo`
                )
              : null
          }>
          <Grid item xs={8} md={8} className={classes.padding16}>
            <Typography variant="body1">Encerrado</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.padding16}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {temasAdministrativosEncerrados.length}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  renderSumarioLegislativoGrid(key) {
    const { classes, temas } = this.props;
    const temasLegislativosEmAcompanhamento = temas.filter(
      (tema) => tema.tipo_tema !== 'Administrativo' && tema.situacao === 'EM_ACOMPANHAMENTO'
    );
    const temasLegislativosEncerrados = temas.filter(
      (tema) => tema.tipo_tema !== 'Administrativo' && tema.situacao === 'ENCERRADO'
    );

    return (
      <>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={() =>
            temasLegislativosEmAcompanhamento.length > 0
              ? this.props.history.push(
                  `${routes.POLITICO_SINDICAL_TEMAS}?situacao=EM_ACOMPANHAMENTO&tipo_tema!=Administrativo`
                )
              : null
          }>
          <Grid item xs={8} md={8} className={classes.padding16}>
            <Typography variant="body1">Em acompanhamento</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.padding16}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {temasLegislativosEmAcompanhamento.length}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          key={key}
          container
          alignItems="center"
          className={classes.overrideBtnPrimary}
          onClick={() =>
            temasLegislativosEncerrados.length > 0
              ? this.props.history.push(
                  `${routes.POLITICO_SINDICAL_TEMAS}?situacao=ENCERRADO&tipo_tema!=Administrativo`
                )
              : null
          }>
          <Grid item xs={8} md={8} className={classes.padding16}>
            <Typography variant="body1">Encerrado</Typography>
          </Grid>
          <Grid item xs={4} md={4} className={classes.padding16}>
            <Typography variant="h5" align="right" color="primary" justify="right">
              {temasLegislativosEncerrados.length}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  renderCardContentGrid() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item md={6}>
          <Card className={classes.paper} variant="outlined">
            <CardHeader title="Administrativo" />
            <CardContent>
              <Box width="100%" display="flex" justifyContent="flex-end" mb="8px"></Box>
              <Grid container direction="column" alignItems="center">
                {this.renderSumarioAdministrativoGrid()}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card className={classes.paper} variant="outlined">
            <CardHeader title="Legislativo" />
            <CardContent>
              <Box width="100%" display="flex" justifyContent="flex-end" mb="8px"></Box>
              <Grid container direction="column" alignItems="center">
                {this.renderSumarioLegislativoGrid()}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  render() {
    const { classes, authUser, loading } = this.props;
    const canEdit = _.includes(authUser.roles, roles.POLITICO_SINDICAL);
    const canDelete = _.includes(authUser.roles, roles.POLITICO_SINDICAL);

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <Card
              className={classes.paper}
              elevation={1}
              hidden={loading}
              style={{ paddingBottom: 20 }}>
              {this.renderCardHeader()}
              <Box width="100%" display="flex" justifyContent="flex-start" mb="8px" padding="20px">
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    this.props.setTemaId(null);
                    this.props.setTemaStep(steps.TEMA);
                    this.props.setTema(null);
                    this.handleTemaClickOpen();
                  }}>
                  Novo tema
                </Button>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {this.renderCardContentGrid()}
                </Grid>
              </Grid>
            </Card>
            <TemaWizardFormDialog
              open={this.state.open}
              handleClose={this.handleTemaClose}
              handleReload={this.handleReload}
              canEdit={canEdit}
              canDelete={canDelete}
            />
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ politico_sindical: { temas, situacoes, loading } }) => ({
  temas,
  situacoes,
  loading,
});

TemaDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setTema,
    setTemaId,
    setTemaStep,
    getTotalTemasSituacao,
    descartarTotalTemasSituacao,
    getTemas,
  })
)(withRouter(TemaDashboard));
