import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { liquidarPagamento } from '../../actions/financial';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { connect } from 'react-redux';
import { formataData } from './util';

class EfetivacaoPagamento extends Component {
  state = {
    data: null,
    pagamento: null,
    banco: '',
    numero_pagamento: '',
    liquidado: false,
  };

  handleLiquidarPagamentoClick() {
    const { data, banco, numero_pagamento, pagamento } = this.state;

    const liquidacao = {
      data_liquidacao_pagamento: data.getTime(),
      banco_liquidacao: banco,
      numero_pagamento,
      pagamento,
    };

    this.props.liquidarPagamento(this.props.despesa.id, liquidacao);
    this.setState({ liquidado: true });
  }

  render() {
    const {
      data_liquidacao_pagamento,
      banco_liquidacao,
      numero_pagamento,
      meio_pagamento,
      pagamento,
    } = this.props.despesa;

    if (data_liquidacao_pagamento || this.state.liquidado) {
      return (
        <Grid item>
          <Typography variant="body1" align="right" color="textSecondary">
            Parcela liquidada em {formataData(data_liquidacao_pagamento || this.state.data)}
            {meio_pagamento === 'Dinheiro'
              ? ` | Pagamento: ${pagamento || this.state.pagamento}`
              : ` | Banco: ${banco_liquidacao || this.state.banco} | Número: ${
                  numero_pagamento || this.state.numero_pagamento
                }`}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item>
            <KeyboardDatePicker
              autoOk
              clearable
              label="Data efetiva"
              format="dd/MM/yyyy"
              value={this.state.data}
              onChange={(data) => this.setState({ data })}
            />
          </Grid>
          {meio_pagamento === 'Dinheiro' ? (
            <Grid item>
              <CurrencyTextField
                label="Dinheiro"
                variant="standard"
                onChange={(event) => this.setState({ pagamento: event.target.value })}
                decimalCharacter=","
                digitGroupSeparator="."
                currencySymbol="R$"
                outputFormat="number"
                value={this.state.pagamento}
                InputLabelProps={{ shrink: true }}
                margin="dense"
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment>
              {' '}
              <Grid item>
                <TextField
                  label="Banco"
                  value={this.state.banco}
                  onChange={(event) => this.setState({ banco: event.target.value })}
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Número de pagamento"
                  onChange={(event) => this.setState({ numero_pagamento: event.target.value })}
                  value={this.state.numero_pagamento}
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item>
            <Button
              variant="outlined"
              disabled={this.state.data === null}
              onClick={() => this.handleLiquidarPagamentoClick()}
              color="primary">
              Liquidar Parcela
            </Button>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default connect(null, { liquidarPagamento })(EfetivacaoPagamento);
