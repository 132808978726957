import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { atualizarReceita } from '../../actions/financial';
import { connect } from 'react-redux';
import _ from 'lodash';
import { formatarValor, converterParaNumero } from './util';

class EstornoReceita extends Component {
  state = { valor_estorno: '' };

  constructor(props) {
    super(props);
    this.handleChangeValorAEstornar = this.handleChangeValorAEstornar.bind(this);
    this.handleEstornarReceitaClick = this.handleEstornarReceitaClick.bind(this);
  }

  handleChangeValorAEstornar(event) {
    this.setState({ valor_estorno: event.target.value });
  }

  handleEstornarReceitaClick() {
    const { valor_estorno } = this.state;

    const receita = {
      valor_estorno: converterParaNumero(valor_estorno),
    };
    this.props.atualizarReceita(this.props.receita.id, receita);
  }

  render() {
    const { valor_estorno } = this.props.receita;

    if (valor_estorno) {
      return (
        <Grid item>
          <Typography variant="body1" align="right" color="textSecondary">
            Estorno: {formatarValor(valor_estorno || this.state.valor_estorno)}
          </Typography>
        </Grid>
      );
    } else {
      return (
        <React.Fragment>
          <Grid item>
            <CurrencyTextField
              label="Estorno"
              onChange={this.handleChangeValorAEstornar}
              decimalCharacter=","
              digitGroupSeparator="."
              currencySymbol="R$"
              outputFormat="number"
              value={this.state.valor_estorno}
              InputLabelProps={{ shrink: true }}
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              style={{ marginTop: 21 }}
              disabled={_.isEmpty(this.state.valor_estorno)}
              onClick={this.handleEstornarReceitaClick}
              color="primary">
              Estornar
            </Button>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default connect(null, { atualizarReceita })(EstornoReceita);
