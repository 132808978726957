import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getFiliado, getFiliados, saveFiliado, setFiliado } from '../../actions/filiado';
import styles from './styles';
import { Field, reduxForm } from 'redux-form';
import validate from '../UserDetail/validate';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import InputText from '../commons/InputText';
import { cepMask } from '../commons/masks';
import Select from '../commons/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as config from '../UserDetail/config';
import InputCep from '../commons/InputCep';
import { getCurrentUserEmail } from '../../firebase/auth';
import { changeUserEmail } from '../../firebase/functions';
import { db } from '../../firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import SindilegisBar from '../commons/SindilegisBar';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import { CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonComponent from '../commons/ButtonComponent';

class Filiado extends React.Component {
  state = { open: false, loading: false, editing: false };

  componentDidMount() {
    this.props.getFiliados();
  }

  handleComunicacaoClose = () => {
    this.setState({
      ...this.state,
      open: false,
      filiados: this.state.filiados.map((f) => {
        if (f.tableData) {
          f.tableData.checked = false;
        }
        return f;
      }),
    });
  };

  async save(values) {
    const { enqueueSnackbar, filiado } = this.props;

    this.setState({ loading: true });

    values.email = values.email.toLowerCase();

    if (filiado.email !== values.email) {
      await changeUserEmail(filiado.id, values.email)
        .then(() => {
          this.saveFiliado(filiado.id, values);
        })
        .catch(({ message }) => {
          this.setState({ loading: false });
          enqueueSnackbar('Erro ao alterar o email do usuario.' + message, {
            variant: 'error',
          });
        });
    } else {
      await this.saveFiliado(filiado.id, values);
    }
    this.setState({ ...this.state, editing: false });
  }

  async saveFiliado(filiadoId, values) {
    const { enqueueSnackbar } = this.props;
    db.doSaveFiliado(filiadoId, values)
      .then(() => {
        enqueueSnackbar('Usuário atualizado com sucesso!', {
          variant: 'success',
        });
        this.setState({ loading: false });
      })
      .catch(
        () =>
          this.setState({ loading: false }) &
          enqueueSnackbar('Erro ao atualizar os dados do usuário.', {
            variant: 'error',
          })
      );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.filiado && this.props.filiados) {
      const currentUserEmail = getCurrentUserEmail();
      const filiado = this.props.filiados.find((f) => f.email === currentUserEmail);
      this.props.setFiliado(filiado);
    }
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <SindilegisBar />
        <form
          className={this.props.classes.container}
          onSubmit={this.props.handleSubmit(this.save.bind(this))}>
          <Card className={this.props.classes.paper} elevation={1}>
            <CardHeader
              title="Meu Perfil"
              action={
                !this.state.editing ? (
                  <ButtonComponent
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '12px',
                      marginRight: '5px',
                    }}
                    variant="insert"
                    disabled={!this.props.filiado}
                    onClick={() =>
                      this.setState({
                        ...this.state,
                        editing: true,
                      })
                    }>
                    Editar
                  </ButtonComponent>
                ) : (
                  <React.Fragment>
                    <Button
                      color="secondary"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginRight: '5px',
                      }}
                      variant="contained"
                      onClick={() => {
                        this.setState({ ...this.state, editing: false });
                        this.props.initialize(this.props.filiado);
                      }}>
                      Cancelar
                    </Button>
                    <Button
                      color="primary"
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                        fontSize: '12px',
                        marginRight: '5px',
                      }}
                      variant="contained"
                      type="submit">
                      Confirmar
                    </Button>
                  </React.Fragment>
                )
              }
            />
            <CardContent>
              <Grid
                container
                item
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{
                  minHeight: '50vh',
                  display: !this.props.filiado ? 'block' : 'none',
                }}>
                <CircularProgress />
              </Grid>
              <Grid container spacing={2} style={this.props.filiado ? {} : { display: 'none' }}>
                <Grid item sm={8}>
                  <Field
                    name="nome_completo"
                    component={InputText}
                    label="Nome"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Field
                    name="email"
                    component={InputText}
                    label="E-mail"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={2}>
                  <Field
                    name="cep"
                    component={InputCep}
                    {...cepMask}
                    label="CEP"
                    onSuccess={this.onSucessCep}
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={4}>
                  <Field
                    name="logradouro"
                    component={InputText}
                    label="Logradouro"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={3}>
                  <Field
                    name="bairro"
                    component={InputText}
                    label="Bairro"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={2}>
                  <Field
                    name="cidade"
                    component={InputText}
                    label="Cidade"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={1}>
                  <Field
                    name="estado"
                    component={Select}
                    label="Estado"
                    disabled={!this.state.editing}>
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {config.estados.map((e) => (
                      <MenuItem key={e} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item sm={6}>
                  <Field
                    name="tel_celular"
                    component={InputText}
                    label="Celular"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={6}>
                  <Field
                    name="tel_fixo"
                    component={InputText}
                    label="Telefone"
                    disabled={!this.state.editing}
                  />
                </Grid>
                <Grid item sm={10}></Grid>
                <Grid item>
                  <ButtonComponent
                    onClick={() => this.props.history.push('filiado/processos')}
                    style={{
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                    variant="insert">
                    Ver processos
                  </ButtonComponent>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  filiado: { filiados, filiado, loading },
  comunicacao: { filiadosAComunicar },
}) => ({
  filiados,
  loading,
  filiadosAComunicar,
  initialValues: filiado,
  filiado,
});

Filiado.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getFiliado, getFiliados, saveFiliado, setFiliado })
)(
  reduxForm({
    form: 'meuPerfil',
    validate,
    enableReinitialize: true,
  })(withSnackbar(Filiado))
);
