import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

class ConfirmDialog extends React.Component {
  handleCancel = () => {
    this.props.onClose();
  };

  handleOk = () => {
    this.props.onClose(true);
  };

  render() {
    const { ...other } = this.props;

    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        {...other}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            other.onClose(event, reason);
          }
        }}>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            {this.props.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={this.handleOk} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmDialog.propTypes = {
  onClose: PropTypes.func,
};

export default ConfirmDialog;
