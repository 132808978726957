import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import { storage } from '../../firebase';
import { incluirAnexoDependente } from '../../actions/dependent';
import { LinearProgress } from '@material-ui/core';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  progress: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
});

class AnexoDependente extends Component {
  state = { anexos: {}, uploadProgress: 0 };

  componentDidMount() {
    if (this.props.anexos) {
      this.setState({ anexos: this.props.anexos });
    }
  }

  componentWillUnmount() {
    this.setState({ anexos: null });
  }

  handleCapture = (event) => {
    const file = event.target.files[0];
    const { id } = this.props;

    const uploadTask = storage.uploadAnexoDependente(`${id}/${file.name}`, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.setState({ uploadProgress: progress });
      },
      (error) => {
        console.error('Erro ao fazer upload do anexo:', error);
      },
      () => {
        uploadTask.snapshot.ref.getMetadata().then(({ fullPath }) => {
          const anexo = { nome: file.name, url: fullPath };
          this.props.incluirAnexoDependente(id, anexo);
          const { anexos } = this.state;
          anexos[anexo.nome] = anexo;
          this.setState({ anexos: { ...anexos }, uploadProgress: 0 });
        });
      }
    );
  };

  render() {
    const { classes, id } = this.props;
    const { anexos, uploadProgress } = this.state;
    const anexarButton = (
      <>
        <AttachFile /> Anexar Documento
      </>
    );

    return (
      <Fragment>
        <Grid item container sm={12} spacing={2}>
          {_.map(anexos, (item, index) => this.renderAnexo(item, index))}
        </Grid>
        <Grid item container sm={12} spacing={2}>
          <input
            className={classes.input}
            id={id}
            onChange={(event) => this.handleCapture(event)}
            type="file"
          />
          <label
            htmlFor={id}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button color="primary" component="span" size="small" style={{ marginRight: 8 }}>
              {uploadProgress ? (
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  className={classes.progress}
                />
              ) : (
                anexarButton
              )}
            </Button>
          </label>
        </Grid>
      </Fragment>
    );
  }

  renderAnexo(item, id) {
    const { canEdit } = this.props;

    if (!canEdit) {
      return (
        <Chip
          size="small"
          key={item.url}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          size="small"
          key={item.url}
          onDelete={() => this.handleDelete({ ...item, id })}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    }
  }

  handleDelete(anexo) {
    const { anexos } = this.state;
    if (anexos && anexos[anexo.id]) {
      delete anexos[anexo.id];
    }
    this.setState({ anexos: { ...anexos } });
    this.props.handleDelete(anexo);
  }
}

export default connect(null, { incluirAnexoDependente })(
  withStyles(styles, { withTheme: true })(AnexoDependente)
);
