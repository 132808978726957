import React from 'react';
import { FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const renderAutocompleteField = ({
  input,
  label,
  options,
  meta: { touched, error },
  ...custom
}) => (
  <FormControl fullWidth error={touched && !!error}>
    <Autocomplete
      {...input}
      {...custom}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" margin="normal" />
      )}
      onChange={(event, value) => input.onChange(value)}
    />
    {touched && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
);

export default renderAutocompleteField;
