import { ROLES_SET, REMOVE_ROLE } from '../actions/types';

const INITIAL_STATE = {
  roles: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ROLES_SET:
      return { ...state, roles: action.roles };
    case REMOVE_ROLE: {
      const roles = state.roles.filter((r) => r !== action.role);
      return { ...state, roles };
    }
    default:
      return state;
  }
}
