import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import styles from './styles';
import {
  getAutoridadePolitica,
  criarAutoridadePolitica,
  saveAutoridadePolitica,
  removerAnexoAutoridade,
} from '../../actions/autoridadePolitica';
import * as consts from './consts';
import PhotoAutoridade from './photo_autoridade';
import RenderTextField from '../commons/RenderTextField';
import DynamicFields from '../commons/DynamicFields';
import { telefoneMask } from '../commons/masks';
import { validateEmail } from '../commons/util';
import { cargosETempo } from './consts';
import renderAutocompleteField from './AutoComplete';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import AnexoAutoridade from './Arquivos/arquivosAutoridade';
import _ from 'lodash';
import ConfirmDialog from '../commons/ConfirmDialog';
import FolderIcon from '@material-ui/icons/Folder';
import ArquivosAutoridadePolitica from './Arquivos/tableArquivos';

class AutoridadeForm extends React.Component {
  state = {
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    photo: this.props.politico?.photo || null,
    anexos: this.props.politico?.anexos || [],
    uploadProgress: 0,
    arquivosOpen: false,
  };
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const { politico, getAutoridadePolitica, isCreatingNew, politicoId } = this.props;

    if (prevProps.politicoId !== politicoId && politicoId && !isCreatingNew) {
      getAutoridadePolitica(politicoId);
    }

    if (prevProps.politico !== politico) {
      this.setState({
        photo: politico?.photo || null,
        anexos: politico?.anexos || [],
      });
    }
  }

  save(formValues) {
    const { criarAutoridadePolitica, saveAutoridadePolitica, politicoId } = this.props;

    const updatedFormValues = {
      ...formValues,
      photo: this.state.photo,
      anexos: this.state.anexos,
    };

    if (politicoId) {
      saveAutoridadePolitica(politicoId, updatedFormValues, () => this.successHandler());
    } else {
      criarAutoridadePolitica(updatedFormValues, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Político salvo com sucesso!', {
      variant: 'success',
    });
    reset();
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar político.', {
      variant: 'error',
    });
  }

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleArquivosOpen = (politicoId) => {
    this.setState({ arquivosOpen: true, politicoId });
  };

  handleArquivosClose = () => {
    this.setState({ arquivosOpen: false, politicoId: null });
  };

  handleDeleteArquivo = (anexoId, politicoId) => {
    this.handleConfirmOpen(() => {
      this.props
        .removerAnexoAutoridade(anexoId, politicoId)
        .then(() => {
          this.props.getAutoridadePolitica(politicoId);
          this.props.enqueueSnackbar('Arquivo excluído com sucesso!', { variant: 'success' });
        })
        .catch(() => {
          this.props.enqueueSnackbar('Erro ao excluir arquivo.', { variant: 'error' });
        });
    }, `Confirma a exclusão do arquivo?`);
  };

  render() {
    const { handleSubmit, politicoId, handleClose, pristine, submitting, reset, politico } =
      this.props;
    const sortedPartidos = consts.partidos.sort((a, b) => {
      if (a.sigla < b.sigla) return -1;
      if (a.sigla > b.sigla) return 1;
      return 0;
    });
    const { arquivosOpen } = this.state;

    const documentos = politico?.anexos ? politico.anexos : null;
    const hasDoc = !_.isEmpty(documentos);

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Perfil</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6}>
                <Field name="nome" component={RenderTextField} label="Nome" />
              </Grid>
              <Grid item sm={4}>
                <Field name="nome_politico" component={RenderTextField} label="Nome Político" />
              </Grid>
              <Grid item sm={1}>
                <Field name="idade" component={RenderTextField} label="Idade" />
              </Grid>
              <Grid>
                <PhotoAutoridade
                  onChange={(photo) => {
                    this.setState({ photo }, () => {
                      this.props.change('photoFlag', new Date().getTime());
                    });
                  }}
                  photo={this.state.photo}
                  autoridadeId={politico?.id || null}
                />
                <Field name="photoFlag" component="input" type="hidden" />
              </Grid>

              <Grid item sm={6}>
                <Field
                  name="filiacao_partidaria"
                  component={RenderTextField}
                  label="Histórico de Filiação Partidária"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="biografia"
                  component={RenderTextField}
                  label="Biografia"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="exerce_mandato"
                  component={renderAutocompleteField}
                  options={consts.simNao.map((opcao) => opcao)}
                  label="Exerce mandato atualmente?"
                />
              </Grid>
              <Grid item sm={3}>
                <Field
                  name="partido"
                  component={renderAutocompleteField}
                  options={sortedPartidos.map((partido) => partido.sigla)}
                  label="Partido"
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="atividades_politicas"
                  component={RenderTextField}
                  label="Principais atividades politicas"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={12}>
                <Typography>Cargos e tempo de mandato</Typography>
                <FieldArray
                  name="cargos"
                  component={(props) => (
                    <DynamicFields
                      {...props}
                      fieldConfig={cargosETempo}
                      addButtonText="Incluir Cargo"
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="tel_primario"
                  component={RenderTextField}
                  label="Telefone Primário"
                  {...telefoneMask}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="tel_secundario"
                  component={RenderTextField}
                  label="Telefone Secundário"
                  {...telefoneMask}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="email_primario"
                  component={RenderTextField}
                  label="E-mail Primário"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="email_secundario"
                  component={RenderTextField}
                  label="E-mail Secundário"
                  validate={validateEmail}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="redes_sociais"
                  component={RenderTextField}
                  label="Redes Sociais"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="noticias"
                  component={RenderTextField}
                  label="Notícias"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="alma_mater"
                  component={RenderTextField}
                  label="Alma Mater"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="premios"
                  component={RenderTextField}
                  label="Prêmios"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="projetos_de_lei_propostos"
                  component={RenderTextField}
                  label="Projetos de Lei Propostos"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="comissoes"
                  component={RenderTextField}
                  label="Comissões"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="historico_de_votacao"
                  component={RenderTextField}
                  label="Histórico de Votação"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="interesses_politicos_especificos"
                  component={RenderTextField}
                  label="Interesses Políticos Específicos"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="afiliacoes_a_grupos_ou_blocos_politicos"
                  component={RenderTextField}
                  label="Afiliações a Grupos ou Blocos Políticos"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="publicacoes"
                  component={RenderTextField}
                  label="Publicações"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="indices_de_aprovacao"
                  component={RenderTextField}
                  label="Índices de Aprovação"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="endosso_de_organizacoes"
                  component={RenderTextField}
                  label="Endosso de Organizações"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="parentesco"
                  component={RenderTextField}
                  label="Parentesco"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="aliados_politicos"
                  component={RenderTextField}
                  label="Aliados políticos"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="religiao"
                  component={renderAutocompleteField}
                  options={consts.religiao.map((religiao) => religiao)}
                  label="Religião"
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="linguas_faladas"
                  component={RenderTextField}
                  label="Línguas Faladas"
                  multiline
                  rows={1}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="formacao_adicional"
                  component={RenderTextField}
                  label="Formação Adicional"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="atividades_profissionais"
                  component={RenderTextField}
                  label="Atividades Profissionais"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="agendas_com_o_sindilegis"
                  component={RenderTextField}
                  label="Agendas com o Sindilegis"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="possivel_agenda_futura_com_o_sindilegis"
                  component={RenderTextField}
                  label="Possível Agenda Futura com o Sindilegis"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="curiosidades"
                  component={RenderTextField}
                  label="Curiosidades"
                  multiline
                  rows={3}
                />
              </Grid>
              {politico?.id && (
                <>
                  <Grid item sm={3}>
                    <AnexoAutoridade
                      onChange={(anexos) => {
                        this.setState({ anexos }, () => {
                          this.props.change('anexosFlag', new Date().getTime());
                        });
                      }}
                      id={politico?.id}
                      anexo={politico?.anexos}
                    />
                    <Field name="anexosFlag" component="input" type="hidden" />
                  </Grid>
                  <Grid item sm={3}>
                    <Tooltip
                      title={`${hasDoc ? 'Arquivos' : 'Não há arquivos para essa autoridade'}`}>
                      <IconButton
                        color={`${hasDoc ? 'primary' : 'default'}`}
                        component="span"
                        size="small"
                        onClick={() => (hasDoc ? this.handleArquivosOpen(politico?.id) : null)}>
                        <FolderIcon /> {hasDoc ? 'Ver documentos' : ''}
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => reset() & handleClose() & this.setState({ anexos: [] })}
              color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
        <ArquivosAutoridadePolitica
          open={arquivosOpen}
          handleClose={this.handleArquivosClose}
          politicoId={politicoId}
          handleDelete={this.handleDeleteArquivo}
        />
      </Dialog>
    );
  }
}

AutoridadeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  isCreatingNew: PropTypes.bool,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['nome', 'nome_politico'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = ({ autoridade_politica: { politico, politicoId } }, ownProps) => ({
  politico: ownProps.isCreatingNew ? {} : politico,
  politicoId: ownProps.isCreatingNew ? null : politico?.id,
  initialValues: ownProps.isCreatingNew ? {} : politico,
});

export default compose(
  connect(mapStateToProps, {
    getAutoridadePolitica,
    criarAutoridadePolitica,
    saveAutoridadePolitica,
    removerAnexoAutoridade,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'autoridade',
    validate,
    enableReinitialize: true,
  })(withSnackbar(AutoridadeForm))
);
