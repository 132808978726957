import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { getEventos, setEventoId, setEvento, saveEvento, deleteEvento } from '../../actions/evento';
import { getReceitasEDespesas } from '../../actions/financial';
import { downloadXlsx } from '../commons/util';
import tableI18n from '../TableLocalization';
import EventoForm from './evento';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { formataData, calcularEstorno, somadorDouble, formatarValor } from '../Financial/util';
import ButtomComponent from '../commons/ButtonComponent';

class Eventos extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
  };

  componentDidMount() {
    this.props.getReceitasEDespesas();
    this.props.getEventos();
  }

  handleEventoClickOpen = () => {
    this.setState({ open: true });
  };

  handleEventoClose = (reload) => {
    this.setState({ open: false });
    this.props.setEvento(null);
    this.props.setEventoId(null);
    if (reload) {
      this.reloadEventos();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadEventos() {
    this.props.getEventos();
  }

  render() {
    const {
      classes,
      eventos,
      loading,
      authUser: { roles },
    } = this.props;

    const isPerfilGestor = () => {
      return roles.includes('gestor_evento');
    };

    const botoesAcoes = () => {
      if (isPerfilGestor()) {
        return [
          {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (event, row) => this.props.setEventoId(row.id) && this.handleEventoClickOpen(),
          },
          {
            icon: 'delete',
            tooltip: 'Excluir',
            onClick: (event, row) =>
              this.handleConfirmOpen(
                () => this.removeEvento(row.id),
                'Confirma a exclusão permanente do evento?'
              ),
          },
          (row) =>
            !row.desabilitado
              ? {
                  icon: 'block',
                  iconProps: {
                    color: 'error',
                  },
                  tooltip: 'Desabilitar',
                  onClick: (event, row) => {
                    this.handleConfirmOpen(
                      () => this.desabilitarEvento(row.id),
                      'Deseja desabilitar o evento?'
                    );
                  },
                }
              : {
                  icon: 'add',
                  iconProps: {
                    color: 'primary',
                  },
                  tooltip: 'Habilitar',
                  onClick: (event, row) => {
                    this.handleConfirmOpen(
                      () => this.habilitarEvento(row.id),
                      'Deseja habilitar o evento?'
                    );
                  },
                },
        ];
      } else {
        return [];
      }
    };

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <Grid
            container
            item
            md={12}
            justifyContent="flex-end"
            spacing={2}
            style={{ marginLeft: 20 }}
            className={classes.areaOperacoes}>
            {isPerfilGestor() && (
              <Grid item>
                <ButtomComponent variant="insert" onClick={() => this.handleEventoClickOpen()}>
                  Cadastrar evento
                </ButtomComponent>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: 'Tipo', field: 'tipo' },
                  { title: 'Área', render: (row) => _.values(row.areas).join(' / ') },
                  { title: 'Nome', field: 'nome' },
                  { title: 'Início realização', field: 'data_inicio' },
                  { title: 'Fim realização', field: 'data_fim' },
                ]}
                data={eventos}
                title="Eventos"
                actions={botoesAcoes()}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                  exportCsv: (columns, data) => {
                    const { expenses, receitas } = this.props;

                    data.forEach((item) => {
                      item.filiados_cd = item.publicos_alvo?.includes('Filiados CD')
                        ? 'Sim'
                        : 'Não';
                      item.filiados_sf = item.publicos_alvo?.includes('Filiados SF')
                        ? 'Sim'
                        : 'Não';
                      item.filiados_tcu = item.publicos_alvo?.includes('Filiados TCU')
                        ? 'Sim'
                        : 'Não';
                      item.filiados_outros = item.publicos_alvo?.includes('Outras pessoas')
                        ? 'Sim'
                        : 'Não';
                      const despesasEvento = expenses.filter((e) => e.id_evento === item.id);
                      item.custo = despesasEvento
                        .map((e) => calcularEstorno(e))
                        .reduce(somadorDouble, 0);

                      const receitasEvento = receitas.filter((r) => r.id_evento === item.id);
                      item.lucro = receitasEvento
                        .map((e) => calcularEstorno(e))
                        .reduce(somadorDouble, 0);
                    });

                    columns = columns
                      .concat([
                        { title: 'Filiados CD', field: 'filiados_cd' },
                        { title: 'Filiados SF', field: 'filiados_sf' },
                        { title: 'Filiados TCU', field: 'filiados_tcu' },
                        { title: 'Outras pessoas', field: 'filiados_outros' },
                        { title: 'Custo', field: 'custo' },
                        { title: 'Receita', field: 'lucro' },
                      ])
                      .filter((c) => !_.isEmpty(c.title));

                    downloadXlsx(columns, data, 'receitas.xlsx');
                  },
                }}
                localization={tableI18n}
                detailPanel={(row) => this.renderDetail(row)}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}
              />
            </div>
          </Grid>
          <EventoForm open={this.state.open} handleClose={this.handleEventoClose} />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  renderDetail(evento) {
    const { publicos_alvo, responsaveis } = evento;
    const { classes, expenses, receitas } = this.props;

    const despesasEvento = expenses.filter((e) => e.id_evento === evento.id);
    const custo = despesasEvento.map((e) => calcularEstorno(e)).reduce(somadorDouble, 0);

    const receitasEvento = receitas.filter((r) => r.id_evento === evento.id);
    const lucro = receitasEvento.map((e) => calcularEstorno(e)).reduce(somadorDouble, 0);

    return (
      <Grid container spacing={2} className={classes.detail}>
        <Grid item sm={12}>
          <Typography variant="body2" color="textSecondary">
            Custo:{' '}
          </Typography>
          <Typography variant="body1">{formatarValor(custo)}</Typography>
          {custo > 0 && (
            <Link
              component="button"
              variant="body2"
              onClick={() => this.downloadDespesas(despesasEvento)}>
              (ver itens de despesa registrados para este evento)
            </Link>
          )}
        </Grid>
        <Grid item sm={12}>
          <Typography variant="body2" color="textSecondary">
            Receitas:{' '}
          </Typography>
          <Typography variant="body1">{formatarValor(lucro)}</Typography>
          {lucro > 0 && (
            <Link
              component="button"
              variant="body2"
              onClick={() => this.downloadReceitas(receitasEvento)}>
              (ver receitas registradas para este evento)
            </Link>
          )}
        </Grid>
        {publicos_alvo && (
          <Grid item sm={12}>
            <Typography variant="body2" color="textSecondary">
              Público-alvo:{' '}
            </Typography>
            <Typography variant="body1">{_.values(publicos_alvo).join(' / ')}</Typography>
          </Grid>
        )}
        {responsaveis && (
          <Grid item sm={12}>
            <Typography variant="body2" color="textSecondary">
              Responsáveis:{' '}
            </Typography>
            <Typography variant="body1">{responsaveis}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  downloadDespesas(data) {
    const columns = [
      { title: 'Data de vencimento', field: 'data_vencimento' },
      { title: 'Item de despesa', field: 'item_despesa' },
      { title: 'Descrição', field: 'descricao' },
      { title: 'Centro de custo', field: 'centro_custo' },
      { title: 'Valor da parcela', field: 'valor' },
      { title: 'Tipo de despesa', field: 'tipo_despesa' },
      { title: 'Beneficiário', field: 'nome_beneficiario' },
      { title: 'CPF/CNPJ', field: 'cpfCnpj' },
      { title: 'Nº parcelas', field: 'parcela' },
      { title: 'Meio de pagamento', field: 'meio_pagamento' },
      { title: 'Número do documento', field: 'numero_documento' },
      { title: 'Tipo do documento', field: 'tipo_documento' },
      { title: 'Data de liquidação', field: 'data_liquidacao_pagamento' },
      { title: 'Banco', field: 'banco_liquidacao' },
      { title: 'Número', field: 'numero_pagamento' },
    ];

    data.forEach((d) => {
      d.data_vencimento = formataData(d.data_vencimento);
      d.data_liquidacao_pagamento = formataData(d.data_liquidacao_pagamento);
    });

    downloadXlsx(columns, data, 'despesas.xlsx');
  }

  downloadReceitas(data) {
    const columns = [
      { title: 'Item', field: 'item_receita' },
      { title: 'Tipo', field: 'tipo' },
      { title: 'Descrição', field: 'descricao' },
      { title: 'Valor', field: 'valor' },
      { title: 'Data', field: 'data' },
      { title: 'Orgão', field: 'empresa' },
      { title: 'Parcela', field: 'parcela' },
    ];

    data.forEach((d) => {
      d.data = formataData(d.data);
    });

    downloadXlsx(columns, data, 'receitas.xlsx');
  }

  habilitarEvento(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveEvento(id, { desabilitado: false }, () =>
      enqueueSnackbar('Evento habilitado com sucesso.', {
        variant: 'success',
      })
    );
  }

  desabilitarEvento(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveEvento(id, { desabilitado: true }, () =>
      enqueueSnackbar('Evento desabilitado com sucesso.', {
        variant: 'success',
      })
    );
  }

  removeEvento(id) {
    const { enqueueSnackbar, expenses } = this.props;
    if (expenses.some((e) => e.id_evento === id)) {
      enqueueSnackbar('O Evento está associado a uma ou mais despesas e não pode ser excluído.', {
        variant: 'error',
      });
      return;
    }

    this.props.deleteEvento(id);
  }
}

const mapStateToProps = ({ evento: { eventos }, financial: { expenses, receitas, loading } }) => ({
  eventos,
  loading,
  expenses,
  receitas,
});

Eventos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEventos,
    setEventoId,
    setEvento,
    saveEvento,
    deleteEvento,
    getReceitasEDespesas,
  })
)(withRouter(withSnackbar(Eventos)));
