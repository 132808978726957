import React from 'react';
import TextField from '@material-ui/core/TextField';
import ViaCep from 'react-via-cep';

export default (props) => {
  const {
    input,
    label,
    meta: { touched, error },
    onSuccess,
  } = props;
  return (
    <ViaCep cep={input.value} lazy onSuccess={onSuccess}>
      {(viacep) => {
        const { loading, fetch } = viacep;
        if (loading) {
          return <p>Buscando...</p>;
        }
        return (
          <div>
            <TextField
              label={label}
              disabled={props.disabled}
              InputLabelProps={{ shrink: true }}
              error={touched && (error || viacep.error)}
              {...input}
              onBlur={() => {
                if (input.value && input.value.replace(/\D/g, '').length > 7) {
                  fetch();
                }
              }}
              margin="dense"
              helperText={error || viacep.error}
              fullWidth
            />
          </div>
        );
      }}
    </ViaCep>
  );
};
