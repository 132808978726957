import React, { Component } from 'react';
import { auth, authFailMessage } from '../../firebase';
import * as routes from '../../constants/routes';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from './styles';
import { logByUserLogin } from '../commons/util';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

function SignIn(props) {
  const { classes } = props;
  const { history } = props;
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <img
          src="/img/sindilegis-logo.svg"
          style={{ width: '80%', height: 'auto', marginBottom: 8, paddingBottom: 40 }}
          alt="Sindilegis"
        />
        <SignInForm history={history} className={classes.form} />
        <div className={classes.backGorundLogIn} />
      </Paper>
    </main>
  );
}

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    const { history } = this.props;

    auth
      .doSignInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        auth.getRoles(user, (authUser) => {
          this.setState({ ...INITIAL_STATE });
          localStorage.setItem('authUser', JSON.stringify(authUser));
          history.push(routes.PROFILE);
          const data = new Date();
          const dataHoraFormatada = data.toLocaleString('pt-BR', {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
          });
          logByUserLogin(dataHoraFormatada);
        });
      })
      .catch((error) => {
        this.setState(byPropKey('error', error));
      });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <form onSubmit={this.onSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">E-mail</InputLabel>
          <Input
            value={email}
            onChange={(event) => this.setState(byPropKey('email', event.target.value))}
            type="text"
            placeholder="Email"
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">Senha</InputLabel>
          <Input
            value={password}
            onChange={(event) => this.setState(byPropKey('password', event.target.value))}
            type="password"
            placeholder="Senha"
          />
        </FormControl>
        <Grid style={{ display: 'flex', justifyContent: 'space-around', paddingTop: 80 }}>
          <Link to={routes.PASSWORD_FORGET}>
            <Button variant="text" color="primary">
              <Typography variant="body2">
                {' '}
                <strong>esqueci a senha</strong>
              </Typography>
            </Button>
          </Link>
          <Button type="submit" variant="contained" color="primary" disabled={isInvalid}>
            <Typography variant="body2" style={{ color: 'white' }}>
              {' '}
              <strong>Acessar</strong>
            </Typography>
          </Button>
        </Grid>
        {error && <p>{authFailMessage(error.code)}</p>}
      </form>
    );
  }
}
export default withStyles(styles)(SignIn);

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};
