import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Radio, FormControlLabel } from '@material-ui/core';
import AutoCompleteSelect from '../commons/AutoComplete';
import DatePicker from '../commons/DatePicker';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import Switch from '../commons/Switch';
import RadioButton from '../commons/RadioButton';
import AsyncAutoComplete from '../commons/AsyncAutoComplete';
import { classificacao } from './config';
import {
  getInteracao,
  criarInteracao,
  saveInteracao,
  setInteracaoId,
  setInteracao,
  getInteracoes,
} from '../../actions/interacao';
import { getFuncionarios } from '../../actions/funcionario';
import { getDependents } from '../../actions/dependent';
import { getItensInteracao } from '../../actions/item_interacao';
import { setFiliadoId } from '../../actions/filiado';
import { getEscritoriosAdvocacia } from '../../actions/juridico';

const InteracaoForm = ({
  handleSubmit,
  pristine,
  submitting,
  reset,
  open,
  handleClose,
  enqueueSnackbar,
  change,
}) => {
  const dispatch = useDispatch();

  const {
    interacaoId,
    filiadoId,
    dependents,
    itens_interacao,
    escritoriosAdvocacia,
    funcionarios,
  } = useSelector((state) => ({
    dependents: state.dependent.dependents,
    itens_interacao: state.item_interacao.itens_interacao,
    escritoriosAdvocacia: state.juridico.escritoriosAdvocacia,
    funcionarios: state.funcionario.funcionarios,
    interacaoId: state.interacao.interacaoId,
    interacao: state.interacao.interacao,
    filiadoId: state.filiado.filiadoId,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getItensInteracao()),
          dispatch(getEscritoriosAdvocacia()),
          dispatch(getFuncionarios()),
        ]);
        if (interacaoId) {
          dispatch(getDependents(interacaoId.split('/')[0]));
          dispatch(getInteracao(interacaoId));
        }
        if (filiadoId) {
          dispatch(getDependents(filiadoId));
        }
      } catch (error) {
        enqueueSnackbar('Erro ao carregar dados.', { variant: 'error' });
      }
    };

    fetchData();
  }, [dispatch, interacaoId, filiadoId, enqueueSnackbar]);

  const resetState = () => {
    dispatch(setFiliadoId(null));
    dispatch(setInteracao(null));
    dispatch(setInteracaoId(null));
  };

  const save = (interacao) => {
    const callback = (error) => {
      if (error) {
        enqueueSnackbar('Erro ao salvar interação.', { variant: 'error' });
      } else {
        enqueueSnackbar('Interação salva com sucesso!', { variant: 'success' });
        dispatch(getInteracoes());
        resetState();
        handleClose();
      }
    };

    if (interacaoId) {
      dispatch(saveInteracao(interacaoId, interacao, callback));
    } else {
      dispatch(criarInteracao(filiadoId, interacao, callback));
    }
  };

  const handleChangeItemInteracao = (item) => {
    const item_interacao = itens_interacao.find((i) => i.nome === item);
    if (item_interacao) {
      const { tipo, descricao } = item_interacao;
      tipo && change('tipo', tipo);
      descricao && change('descricao', descricao);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(null)}
      fullWidth
      maxWidth="md"
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Interação</DialogTitle>
      <form onSubmit={handleSubmit(save)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Field
                name="item_interacao"
                options={itens_interacao
                  .filter((item) => !item.desabilitado)
                  .map((d) => ({ label: d.nome, value: d.nome }))}
                component={AutoCompleteSelect}
                label="Item de interação"
                onChange={(item) => handleChangeItemInteracao(item)}
              />
            </Grid>
            <DadosEscritorios escritorios={escritoriosAdvocacia} />
            <Grid item sm={2}>
              <Field name="data" component={DatePicker} label="Data" />
            </Grid>
            <Grid item sm={4}>
              <Field name="classificacao" component={RadioButton} label="Classificação">
                {_.map(classificacao, (classificacao, key) => (
                  <FormControlLabel
                    key={key}
                    value={classificacao.value}
                    control={<Radio />}
                    label={classificacao.label}
                  />
                ))}
              </Field>
            </Grid>
            <Grid item sm={12}>
              <Field
                name="responsavel"
                component={AsyncAutoComplete}
                label="Responsável"
                loadOptions={(input) =>
                  Promise.resolve(
                    funcionarios
                      .filter((f) => f?.nome && f.nome.toLowerCase().includes(input.toLowerCase()))
                      .map((f) => ({ label: f.nome.toUpperCase(), value: f.id }))
                      .slice(0, 100)
                  )
                }
              />
            </Grid>
            <Grid item sm={12}>
              <Field name="descricao" component={InputText} label="Descrição" />
            </Grid>
            <Grid item container sm={12} spacing={2}>
              <Grid item sm={4}>
                <Field
                  name="relacao_dependente"
                  component={Switch}
                  label="Interação relacionada a dependente?"
                />
              </Grid>
              <DadosDependente dependentes={dependents} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              resetState();
              handleClose();
            }}
            color="default">
            Cancelar
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={pristine || submitting}
            color="primary">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const DadosEscritorios = ({ escritorios }) => {
  const [item_interacao] = useSelector((state) => [state.form.interacao?.values?.item_interacao]);

  if (item_interacao === 'Assistência jurídica') {
    return (
      <Grid item sm={4}>
        <Field name="tipo" component={Select} label="Escritório">
          <MenuItem value="">
            <em>Selecione</em>
          </MenuItem>
          {escritorios.map((d) => (
            <MenuItem key={d.id} value={d.nome}>
              {d.nome}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    );
  }
  return null;
};

const DadosDependente = ({ dependentes }) => {
  const [relacao_dependente] = useSelector((state) => [
    state.form.interacao?.values?.relacao_dependente,
  ]);

  if (relacao_dependente) {
    return (
      <Grid item sm={8}>
        <Field name="id_dependente" component={Select} label="Nome do dependente">
          <MenuItem value="">
            <em>Selecione</em>
          </MenuItem>
          {dependentes.map((d) => (
            <MenuItem key={d.id} value={d.id}>
              {d.nome_completo}
            </MenuItem>
          ))}
        </Field>
      </Grid>
    );
  }
  return null;
};

InteracaoForm.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'interacao',
  validate: (values) => {
    const errors = {};
    const requiredFields = ['item_interacao', 'tipo', 'data', 'descricao'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        errors[field] = 'Obrigatório';
      }
    });
    return errors;
  },
  enableReinitialize: true,
})(withSnackbar(InteracaoForm));
