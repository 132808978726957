import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import InputNumber from '../commons/InputNumber';
import DatePicker from '../commons/DatePicker';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { getSuprimento, criarSuprimento, saveSuprimento } from '../../actions/suprimentos';
import { converterParaNumero } from '../Financial/util';

class SuprimentoForm extends React.Component {
  state = { origens_suprimento: [] };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ suprimentoId }) {
    if (suprimentoId) {
      this.props.getSuprimento(suprimentoId);
    }
  }

  save(suprimento) {
    const { suprimentoId } = this.props;
    suprimento.valor = converterParaNumero(suprimento.valor);
    if (suprimentoId) {
      this.props.saveSuprimento(suprimentoId, suprimento, () => this.successHandler());
    } else {
      this.props.criarSuprimento(suprimento, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Suprimento salvo com sucesso!', {
      variant: 'success',
    });
    reset('suprimento');
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar suprimento.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Suprimento</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={4} hidden={false} alignItems="center">
              <Grid item sm={4}>
                <Field name="data" component={DatePicker} label="Data" />
              </Grid>
              <Grid item sm={8}>
                <Field name="descricao" component={InputText} label="Descrição" />
              </Grid>
              <Grid item sm={6}>
                <Field name="valor" component={InputNumber} label="Valor" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && this.props.handleClose()} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

SuprimentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ suprimento: { suprimento, suprimentoId } }) => ({
  suprimento: suprimento,
  suprimentoId: suprimentoId,
  initialValues: suprimento,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['tipo', 'data', 'descricao', 'valor'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

export default compose(
  connect(mapStateToProps, { getSuprimento, criarSuprimento, saveSuprimento }),
  withStyles(styles)
)(
  reduxForm({
    form: 'suprimento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(SuprimentoForm))
);
