import _ from 'lodash';
import {
  BENEFICIARIOS_SET,
  BENEFICIARIO_SET,
  BENEFICIARIO_ID_SET,
  BENEFICIARIOS_REQUEST,
  BENEFICIARIO_UPDATED,
  DELETE_BENEFICIARIO,
} from './types';
import { db } from '../firebase';

export const saveBeneficiario = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveBeneficiario(id, data).then(() => {
      dispatch({ type: BENEFICIARIO_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarBeneficiario = (data, callback) => {
  return (dispatch) => {
    db.doCreateBeneficiario(data).then(() => {
      callback && callback();
    });
  };
};

export const getBeneficiarios = () => {
  return (dispatch) => {
    dispatch({ type: BENEFICIARIOS_REQUEST });
    db.onceGetBeneficiarios().then((snapshot) => {
      let beneficiarios = snapshot.val();
      if (beneficiarios) {
        Object.keys(beneficiarios).map((id) => {
          beneficiarios[id].id = id;
          return beneficiarios[id];
        });
        beneficiarios = _.orderBy(
          _.values(beneficiarios),
          [(b) => b.nome_beneficiario.toLowerCase()],
          ['asc']
        );
      } else {
        beneficiarios = [];
      }

      dispatch({ type: BENEFICIARIOS_SET, beneficiarios });
    });
  };
};

export const getBeneficiario = (id) => {
  return (dispatch) => {
    db.onceGetBeneficiario(id).then((snapshot) => {
      const beneficiario = snapshot.val();
      dispatch({ type: BENEFICIARIO_SET, beneficiario });
    });
  };
};

export const deleteBeneficiario = (id) => {
  return (dispatch) => {
    db.doDeleteBeneficiario(id).then(() => {
      dispatch({ type: DELETE_BENEFICIARIO, payload: { id } });
    });
  };
};

export const setBeneficiario = (beneficiario) => (dispatch) =>
  dispatch({ type: BENEFICIARIO_SET, beneficiario });

export const setBeneficiarioId = (beneficiarioId) => (dispatch) =>
  dispatch({ type: BENEFICIARIO_ID_SET, beneficiarioId });
