import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Select from '../commons/Select';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { getItemReceita, criarItemReceita, saveItemReceita } from '../../actions/item_receita';
import * as config from '../Financial/config';

class ItemReceitaForm extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ item_receita_id }) {
    if (item_receita_id) {
      this.props.getItemReceita(item_receita_id);
    }
  }

  save(item_receita) {
    const { item_receita_id } = this.props;
    if (item_receita_id) {
      this.props.saveItemReceita(item_receita_id, item_receita, () => this.successHandler());
    } else {
      this.props.criarItemReceita(item_receita, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('ItemReceita salva com sucesso!', {
      variant: 'success',
    });
    reset('item_receita');
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar item_receita.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, reset } = this.props;
    const { tipos_receita } = config;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Item de receita</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field name="nome" component={InputText} label="Nome" />
              </Grid>
              <Grid item sm={12}>
                <Typography>Configurações</Typography>
              </Grid>
              <Grid item sm={2}>
                <Field name="tipo" component={Select} label="Tipo">
                  <MenuItem value="">
                    <em>Selecione</em>
                  </MenuItem>
                  {tipos_receita.map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item sm={10}>
                <Field name="descricao" component={InputText} label="Descrição" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && this.props.handleClose()} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

ItemReceitaForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ item_receita: { item_receita, item_receita_id } }) => ({
  item_receita: item_receita,
  item_receita_id: item_receita_id,
  initialValues: item_receita,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['tipo', 'nome', 'descricao'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

export default compose(
  connect(mapStateToProps, { getItemReceita, criarItemReceita, saveItemReceita }),
  withStyles(styles)
)(
  reduxForm({
    form: 'item_receita',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ItemReceitaForm))
);
