import React from 'react';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import compose from 'recompose/compose';
import _ from 'lodash';

import styles from './styles';
const LinkChip = ({ id, item, rootItem, canDelete, icon, size, handleDeleteLink }) => {
  const chipInstance = canDelete ? (
    <Chip
      key={id}
      size={size}
      onDelete={() =>
        _.isNil(rootItem)
          ? handleDeleteLink({ ...item, id })
          : handleDeleteLink({ ...item, id }, rootItem)
      }
      onClick={() => window.open(item.url, '_blank')}
      label={item.nome}
      clickable
      variant="outlined"
    />
  ) : (
    <Chip
      key={id}
      size={size}
      icon={icon}
      onClick={() => window.open(item.url, '_blank')}
      label={item.nome}
      clickable
      variant="outlined"
    />
  );

  return (
    <Tooltip key={id} title={`Clique e acesse: ${item.url}`} placement="left">
      {chipInstance}
    </Tooltip>
  );
};

export default compose(withStyles(styles))(LinkChip);
