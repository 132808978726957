import React, { Fragment, useEffect, useState } from 'react';
import { KeyboardTimePicker } from '@material-ui/pickers';

function RenderTimeInput(props) {
  const {
    input,
    label,
    name,
    meta: { dirty, error },
  } = props;
  const [selectedTime, setValue] = useState(null);

  useEffect(() => {
    if (input.value) {
      let timeVar = input.value;
      if (typeof input.value == 'string') {
        timeVar = '01/01/1970 ' + input.value;
      }
      setValue(new Date(timeVar));
    }
  }, [props]);

  useEffect(() => {
    if (selectedTime) {
      let timeVar = selectedTime;
      if (typeof selectedTime == 'string') {
        timeVar = '01/01/1970 ' + selectedTime;
      }
      const time = new Date(timeVar).getTime();
      input.onChange(time);
    }
  }, [selectedTime]);

  return (
    <Fragment>
      <KeyboardTimePicker
        autoOk
        {...input}
        name={name}
        label={label}
        invalidDateMessage="Hora inválida"
        onClear={() => {}}
        fullWidth
        variant="inline"
        format={'HH:mm'}
        value={selectedTime}
        onChange={setValue}
        InputLabelProps={{ shrink: true }}
      />
      {dirty && error && <span className="error_msg">{error}</span>}
    </Fragment>
  );
}

export default RenderTimeInput;
