import React from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as routes from '../../constants/routes';
import { getFiliados } from '../../actions/filiado';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import { downloadXlsx, getRoutes } from '../commons/util';
import styles from './styles';
import MenuButton from '../commons/MenuButton';

class Financial extends React.Component {
  state = {
    filiadosComEmail: 0,
    filiadosSemEmail: 0,
    filiadosComEmailECelular: 0,
    filiadosSemEmailECelular: 0,
    filiadosComCelular: 0,
    filiadosSemCelular: 0,
    filiadosComEndereco: 0,
    filiadosSemEndereco: 0,
  };

  componentDidMount() {
    this.props.getFiliados();
  }

  filiadosPorEstado() {
    const groupedByState = _.groupBy(
      this.props.filiados.filter((e) => e.empresa === 'TCU'),
      'estado'
    );
    const aux = Object.keys(groupedByState).map((key) => ({
      uf: key,
      qtd: groupedByState[key].length,
    }));

    const columns = [
      { title: 'UF', field: 'uf' },
      { title: 'Quantidade', field: 'qtd' },
    ];
    downloadXlsx(columns, aux, `filiadosTCUPorUF.xlsx`);
  }

  estatisticasFiliados() {
    const estatisticas = {
      filiadosComEmail: 0,
      filiadosSemEmail: 0,
      filiadosComEmailECelular: 0,
      filiadosSemEmailECelular: 0,
      filiadosComCelular: 0,
      filiadosSemCelular: 0,
      filiadosComEndereco: 0,
      filiadosSemEndereco: 0,
    };

    this.props.filiados
      .filter((f) => f.cadastro === 'FILIADO')
      .forEach((f) => {
        if (!f.email || f.email.indexOf('@substituir.com') > -1) {
          estatisticas.filiadosSemEmail++;
        }
        if (f.email && !f.email.indexOf('@substituir.com') > -1) {
          estatisticas.filiadosComEmail++;
        }

        if (_.isEmpty(f.tel_celular)) {
          estatisticas.filiadosSemCelular++;
        } else {
          estatisticas.filiadosComCelular++;
        }

        if (_.isEmpty(f.logradouro)) {
          estatisticas.filiadosSemEndereco++;
        } else {
          estatisticas.filiadosComEndereco++;
        }

        if ((!f.email || f.email.indexOf('@substituir.com') > -1) && _.isEmpty(f.tel_celular)) {
          estatisticas.filiadosSemEmailECelular++;
        }

        if (f.email && !f.email.indexOf('@substituir.com') > -1 && !_.isEmpty(f.tel_celular)) {
          estatisticas.filiadosComEmailECelular++;
        }
      });

    return estatisticas;
  }

  downloadRelatorio(texto, filtro) {
    const data = this.props.filiados.filter((f) => f.cadastro === 'FILIADO').filter(filtro);
    const columns = [
      { title: 'CPF', field: 'cpf' },
      { title: 'Nome', field: 'nome_completo' },
    ];
    downloadXlsx(columns, data, `${texto}.xlsx`);
  }

  render() {
    const { classes, loading, location } = this.props;
    const estatisticas = this.estatisticasFiliados();
    const currentPath = location.pathname;
    const buttonRoutes = getRoutes(routes, currentPath);
    const ADITIONAL_BUTTON = [
      {
        label: 'Filiados TCU por UF',
        route: '#',
        condition: true,
        onClick: () => this.filiadosPorEstado(),
      },
    ];

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />

        {!loading && (
          <Container maxWidth="xl">
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.areaOperacoes}>
              <Grid container item md={12} justifyContent="flex-end" spacing={2}>
                <MenuButton
                  buttonsRoutes={buttonRoutes}
                  location={location}
                  additionalButtons={ADITIONAL_BUTTON}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.areaGrafico}>
              <Card style={{ width: 'calc(100% - 32px)' }}>
                <CardContent>
                  <Grid container item sm={12} spacing={2}>
                    {this.renderCard(
                      estatisticas.filiadosComEmail,
                      'Filiados com e-mail',
                      (f) => f.email && !f.email.indexOf('@substituir.com') > -1
                    )}
                    {this.renderCard(
                      estatisticas.filiadosSemEmail,
                      'Filiados sem e-mail',
                      (f) => !f.email || f.email.indexOf('@substituir.com') > -1
                    )}
                    {this.renderCard(
                      estatisticas.filiadosComEmailECelular,
                      'Filiados com e-mail e celular',
                      (f) =>
                        f.email &&
                        !f.email.indexOf('@substituir.com') > -1 &&
                        !_.isEmpty(f.tel_celular)
                    )}
                    {this.renderCard(
                      estatisticas.filiadosSemEmailECelular,
                      'Filiados sem e-mail e celular',
                      (f) =>
                        (!f.email || f.email.indexOf('@substituir.com') > -1) &&
                        _.isEmpty(f.tel_celular)
                    )}
                    {this.renderCard(
                      estatisticas.filiadosComCelular,
                      'Filiados com celular',
                      (f) => !_.isEmpty(f.tel_celular)
                    )}
                    {this.renderCard(estatisticas.filiadosSemCelular, 'Filiados sem celular', (f) =>
                      _.isEmpty(f.tel_celular)
                    )}
                    {this.renderCard(
                      estatisticas.filiadosComEndereco,
                      'Filiados com endereço',
                      (f) => !_.isEmpty(f.logradouro)
                    )}
                    {this.renderCard(
                      estatisticas.filiadosSemEndereco,
                      'Filiados sem endereço',
                      (f) => _.isEmpty(f.logradouro)
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Container>
        )}
      </React.Fragment>
    );
  }

  renderCard(valor, texto, filtro) {
    const { classes } = this.props;
    return (
      <Grid item md={4}>
        <Card className={classes.cardLancamentos}>
          <CardContent>
            <Typography className={classes.cardTitle} color="textSecondary">
              {texto}
            </Typography>
            <Typography color="textPrimary">{valor}</Typography>
            <IconButton
              onClick={() => this.downloadRelatorio(texto, filtro)}
              aria-label="Download relatório">
              <CloudDownload />
            </IconButton>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = ({ filiado: { filiados, loading } }) => ({ filiados, loading });

Financial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getFiliados })
)(withRouter(Financial));
