export const estados = [
  'AC',
  'AL',
  'AM',
  'AP',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MG',
  'MS',
  'MT',
  'PA',
  'PB',
  'PE',
  'PI',
  'PR',
  'RJ',
  'RN',
  'RO',
  'RR',
  'RS',
  'SC',
  'SE',
  'SP',
  'TO',
];

export const orgaoCargo = {
  CAMARA: [
    { nome: 'ANALISTA LEGISLATIVO', ativo: true },
    { nome: 'CONSULTOR LEGISLATIVO', ativo: false },
    { nome: 'CONSULTOR LEGISLATIVO - ASSESSORAMENTO LEGISLATIVO', ativo: true },
    { nome: 'CONSULTOR LEGISLATIVO - ASSESSORAMENTO EM ORÇAMENTOS', ativo: true },
    { nome: 'TÉCNICO LEGISLATIVO', ativo: true },
    { nome: 'TÉCNICO LEGISLATIVO - POLICIAL LEGISLATIVO FEDERAL', ativo: true },
    { nome: 'AUXILIAR LEGISLATIVO', ativo: true },
    { nome: 'CARGO EM COMISSÃO - CNE (CARGO DE NATUREZA ESPECIAL)', ativo: true },
    { nome: 'CARGO EM COMISSÃO - SP (SECRETARIADO PARLAMENTAR)', ativo: true },
  ].sort((a, b) => a.nome.localeCompare(b.nome)),
  TCU: [
    { nome: 'AUDITOR FEDERAL DE CONTROLE EXTERNO', ativo: false },
    { nome: 'AUDITOR FEDERAL DE CONTROLE EXTERNO - CONTROLE EXTERNO', ativo: true },
    { nome: 'AUDITOR FEDERAL DE CONTROLE EXTERNO - APOIO TÉCNICO E ADMINISTRATIVO', ativo: true },
    { nome: 'TÉCNICO FEDERAL DE CONTROLE EXTERNO', ativo: false },
    { nome: 'TÉCNICO FEDERAL DE CONTROLE EXTERNO - CONTROLE EXTERNO', ativo: true },
    { nome: 'TÉCNICO FEDERAL DE CONTROLE EXTERNO - APOIO TÉCNICO E ADMINISTRATIVO', ativo: true },
    { nome: 'AUXILIAR DE CONTROLE EXTERNO', ativo: true },
    { nome: 'CARGO EM COMISSÃO', ativo: true },
  ].sort((a, b) => a.nome.localeCompare(b.nome)),
  SENADO: [
    { nome: 'ADVOGADO', ativo: true },
    { nome: 'CONSULTOR LEGISLATIVO', ativo: false },
    { nome: 'CONSULTOR LEGISLATIVO - ASSESSORAMENTO LEGISLATIVO', ativo: true },
    { nome: 'CONSULTOR LEGISLATIVO - ASSESSORAMENTO EM ORÇAMENTOS', ativo: true },
    { nome: 'ANALISTA LEGISLATIVO', ativo: true },
    { nome: 'TÉCNICO LEGISLATIVO', ativo: true },
    { nome: 'TÉCNICO LEGISLATIVO - POLICIAL LEGISLATIVO FEDERAL', ativo: true },
    { nome: 'AUXILIAR LEGISLATIVO', ativo: true },
    { nome: 'CARGO EM COMISSÃO', ativo: true },
  ].sort((a, b) => a.nome.localeCompare(b.nome)),
};

export const sexos = ['Feminino', 'Masculino', 'Prefiro não responder', 'Outro'];

export const pagamentos = ['Boleto', 'Consignação'];

export const estadoCivil = [
  'Solteiro(a)',
  'Casado(a)',
  'União Estável',
  'Divorciado(a)',
  'Viúvo(a)',
];

export const preferenciaEmail = [
  { value: 'Pessoal', label: 'Pessoal' },
  { value: 'Funcional', label: 'Funcional' },
];

export const escolaridade = [
  'Ensino fundamental',
  'Ensino médio',
  'Ensino superior completo',
  'Ensino superior incompleto',
  'Especialização',
  'Mestrado',
  'Doutorado',
  'Pós-Doutorado',
];

export const showHistorico = [
  { value: 'nome_completo', label: 'Nome' },
  { value: 'cpf', label: 'CPF' },
  { value: 'falecido', label: 'Falecido' },
  { value: 'rg', label: 'RG' },
  { value: 'orgao_expedidor', label: 'Órgão Expedidor' },
  { value: 'UF_orgao_expedidor', label: 'UF Órgão' },
  { value: 'data_nascimento', label: 'Nascimento' },
  { value: 'sexo', label: 'Gênero' },
  { value: 'estado_civil', label: 'Estado Civil' },
  { value: 'nome_mae', label: 'Nome da mãe' },
  { value: 'email', label: 'E-mail Pessoal' },
  { value: 'email_funcional', label: 'E-mail Funcional' },
  { value: 'preferencia_comunicacao', label: 'Prefere receber nossas comunicações no e-mail' },
  { value: 'tel_celular', label: 'Celular' },
  { value: 'whatsapp', label: 'WhatsApp' },
  { value: 'tel_fixo', label: 'Telefone Residencial' },
  { value: 'ramal', label: 'Ramal' },
  { value: 'cep', label: 'CEP' },
  { value: 'logradouro', label: 'Logradouro' },
  { value: 'bairro', label: 'Bairro' },
  { value: 'cidade', label: 'Cidade' },
  { value: 'estado', label: 'Estado' },
  { value: 'empresa', label: 'Casa' },
  { value: 'matricula', label: 'Matrícula' },
  { value: 'situacao_filiado', label: 'Situação' },
  { value: 'escolaridade', label: 'Escolaridade' },
  { value: 'filiado_associacao', label: 'É filiado pela' },
  { value: 'forma_pagamento', label: 'Forma de pagamento' },
  { value: 'data_filiacao', label: 'Filiado em' },
  { value: 'observacao', label: 'Observação' },
  { value: 'anexosFlag', label: 'Data de alteração do anexo' },
  { value: 'anexos', label: 'Anexo' },
  { value: 'data_desfiliacao', label: 'Data de desfiliação' },
  { value: 'cargo', label: 'Cargo' },
  { value: 'associacao', label: 'Associação' },
  { value: 'associacao_outra', label: 'Outra associação' },
  { value: 'nome_falecido', label: 'Nome do servidor titular' },
  { value: 'cpf_falecido', label: 'CPF do titular' },
  { value: 'sexo_outro', label: 'Especificação do sexo' },
];

export const associacoes = {
  CAMARA: ['Ascade', 'AsaCD', 'APCN', 'Astec', 'Infolegis', 'Unalegis', 'Aslegis', 'Outra'],
  SENADO: ['Assefe', 'Ascip', 'Comsefe', 'Alesfe', 'Outra'],
  TCU: ['AsTCU', 'AsapTCU', 'Una-TCU', 'Auditar', 'Aud-TCU', 'Outra'],
};
