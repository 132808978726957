import React from 'react';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import RangeDatePicker from '../commons/RangeDatePicker';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { getExpenses, getReceitasEDespesas } from '../../actions/financial';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import * as routes from '../../constants/routes';
import styles from './styles';
import { downloadXlsx } from '../commons/util';
import COLORS from './colors';
import tableI18n from '../TableLocalization';
import {
  getDataInicial,
  getDataFinal,
  somadorDouble,
  formatarValor,
  calcularEstorno,
  formataData,
} from './util';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';

class GraficosFinanceiro extends React.Component {
  state = {
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    campoFiltro: 'item_despesa',
    legenda: '',
  };

  componentDidMount() {
    this.props.getReceitasEDespesas();
  }
  handleChange = (event) => {
    this.setState({ campoFiltro: event.target.value });
  };

  handleDateChange(date) {
    this.setState(date);
  }

  handleClickLegend(legenda) {
    this.setState({ legenda });
  }

  render() {
    const { classes, loading, location } = this.props;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
            <Grid
              container
              item
              sm={12}
              spacing={1}
              justifyContent="center"
              alignItems="center"
              className={classes.areaOperacoes}>
              <Grid container item md={12} alignItems="center" spacing={1}>
                <Grid container item md={6} justifyContent="center" alignItems="center">
                  <RangeDatePicker
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    onChange={(data) => this.handleDateChange(data)}
                  />
                </Grid>
                <Grid container item md={2} spacing={1} justifyContent="center" alignItems="center">
                  <TextField
                    label="Campo"
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    fullWidth
                    select
                    value={this.state.campoFiltro}
                    onChange={this.handleChange.bind(this)}>
                    <MenuItem value="item_despesa">Item de despesa</MenuItem>
                    <MenuItem value="tipo_despesa">Tipo de despesa</MenuItem>
                    <MenuItem value="centro_custo">Centro de custo</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Card style={{ width: 'calc(100% - 32px)', marginTop: 20 }}>
              <CardContent>
                <Grid container item sm={12} spacing={2}>
                  {this.renderPieGraph()}
                </Grid>
              </CardContent>
            </Card>
            <MaterialTable
              style={{ width: 'calc(100% - 32px)', marginTop: 20 }}
              columns={[
                {
                  title: 'Data de vencimento',
                  field: 'data_vencimento',
                  render: (row) => formataData(row.data_vencimento),
                },
                { title: 'Item de despesa', field: 'item_despesa' },
                { title: 'Descrição', field: 'descricao' },
                { title: 'Centro de custo', field: 'centro_custo' },
                {
                  title: 'Valor da parcela',
                  field: 'valor',
                  render: (row) => <span>{formatarValor(row.valor)}</span>,
                },
                { title: 'Tipo de despesa', field: 'tipo_despesa' },
                { title: 'Beneficiário', field: 'nome_beneficiario' },
                { title: 'CPF/CNPJ', field: 'cpfCnpj' },
              ]}
              data={this.getData()}
              title="Relatório de despesas"
              localization={tableI18n}
              options={{
                exportButton: true,
                exportAllData: true,
                exportDelimiter: ';',
                exportFileName: 'relatorio de despesas',
                exportCsv: (columns, data) => {
                  data.forEach((d) => {
                    d.data_vencimento = formataData(d.data_vencimento);
                  });

                  downloadXlsx(columns, data, 'despesas.xlsx');
                },
                padding: 'dense',
              }}
              icons={{ Filter: () => <span></span> }}
            />
          </Container>
        )}
      </React.Fragment>
    );
  }

  renderPieGraph() {
    const data = this.getPieData();

    return (
      <PieChart width={1000} height={1200}>
        <Tooltip />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
          label={false}
          labelLine={false}
          legendType="square">
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          onClick={({ value }) => this.handleClickLegend(value)}
          layout="vertical"
          formatter={(value, { color, payload }) => (
            <span style={{ color: color }}>
              {`${value}:`}
              {formatarValor(payload.value)}
            </span>
          )}
        />
      </PieChart>
    );
  }

  getData() {
    let data = this.props.expenses.filter(
      (e) =>
        this.state.fromDate.getTime() <= e.data_vencimento &&
        this.state.toDate.getTime() >= e.data_vencimento
    );

    if (!_.isEmpty(this.state.legenda)) {
      data = data.filter((e) => e[this.state.campoFiltro] === this.state.legenda);
    }

    return data;
  }

  getPieData() {
    const chunk = 30;
    const mapa = _.groupBy(this.props.expenses, this.state.campoFiltro);
    let data = Object.keys(mapa).map((keyItem) => {
      const value = mapa[keyItem]
        .filter(
          (e) =>
            this.state.fromDate.getTime() <= e.data_vencimento &&
            this.state.toDate.getTime() >= e.data_vencimento
        )
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);
      return { name: keyItem, value };
    });

    data = _.sortBy(data, 'value').reverse();
    const outros = [...data].slice(chunk + 1, data.length);

    const value = outros.map((o) => o.value).reduce(somadorDouble, 0);
    data = data.slice(0, chunk);
    if (value) {
      data.push({ name: 'Outros', value });
    }
    return data;
  }
}

const mapStateToProps = ({ financial: { expenses, receitas, loading } }) => ({
  expenses,
  receitas,
  loading,
});

GraficosFinanceiro.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getExpenses, getReceitasEDespesas })
)(withRouter(GraficosFinanceiro));
