import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import {
  createTema,
  getTema,
  saveTema,
  setTema,
  setTemaId,
  setTemaStep,
} from '../../actions/politico_sindical';
import { steps, LAST_STEP } from '../../controllers/temaController';
import styles from './styles';
import TemaStepForm from './TemaStepForm';
import TemaStepInfoGeraisForm from './TemaStepInfoGeraisForm';
import TemaStepItensDestaqueForm from './TemaStepItensDestaqueForm';
import TemaStepPessoasForm from './TemaStepPessoasForm';
import TemaStepProcessosForm from './TemaStepProcessosForm';
import TemaStepAcoesSindilegisForm from './TemaStepAcoesSindilegisForm';
import validate from './validate';

class TemaWizardFormDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
  }

  handleNextPage() {
    const { page } = this.props;
    if (page !== LAST_STEP) {
      this.props.setTemaStep(page + 1);
    }
  }

  handlePreviousPage() {
    const { page } = this.props;
    this.props.setTemaStep(page - 1);
  }

  render() {
    const { page } = this.props;
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        {page === steps.TEMA && (
          <TemaStepForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            handleReload={this.props.handleReload}
            canEdit={this.props.canEdit}
          />
        )}
        {page === steps.INFORMACOES_GERAIS && (
          <TemaStepInfoGeraisForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            previousPage={this.handlePreviousPage}
          />
        )}
        {page === steps.ITEM_DESTAQUE && (
          <TemaStepItensDestaqueForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            previousPage={this.handlePreviousPage}
          />
        )}
        {page === steps.PESSOAS_INSTITUICOES && (
          <TemaStepPessoasForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            previousPage={this.handlePreviousPage}
          />
        )}
        {page === steps.PROCESSOS && (
          <TemaStepProcessosForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            previousPage={this.handlePreviousPage}
          />
        )}
        {page === steps.ACOES && (
          <TemaStepAcoesSindilegisForm
            onSubmit={this.handleNextPage}
            handleClose={this.props.handleClose}
            canEdit={this.props.canEdit}
            canDelete={this.props.canDelete}
            previousPage={this.handlePreviousPage}
          />
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = ({ politico_sindical: { tema, temaId, page } }) => ({
  page: page,
  tema: tema,
  temaId: temaId,
  initialValues: tema,
});

TemaWizardFormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getTema, createTema, saveTema, setTema, setTemaId, setTemaStep })
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaWizardFormDialog))
);
