import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography, Paper } from '@material-ui/core';
import { getFiliados } from '../../../actions/filiado';
import { useDispatch, useSelector } from 'react-redux';
import ButtonComponent from '../../commons/ButtonComponent';
import styles from './Calculators.module.css';

const CustoFiliado = () => {
  const dispatch = useDispatch();
  const { filiados } = useSelector((state) => ({
    filiados: state.filiado.filiados,
  }));

  const filiadosAtivos = filiados.filter(
    (filiado) => filiado.situacao_filiado !== 'INATIVO' && !filiado.data_desfiliacao
  );

  useEffect(() => {
    dispatch(getFiliados());
  }, [dispatch]);

  const formatarValor = (valor) => {
    if (!valor) return '';
    const numero = Number(valor.replace(/\D/g, '')) / 100;
    return numero.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const [rc, setRc] = useState('');
  const [cj, setCj] = useState('');
  const [cp, setCp] = useState('');
  const [cgs, setCgs] = useState('');
  const [cb, setCb] = useState('');
  const [result, setResult] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const handleCalcular = () => {
    const rcNumerico = Number(rc.replace(/\D/g, '')) / 100;
    const cjNUmerico = Number(cj.replace(/\D/g, '')) / 100;
    const cpNumerico = Number(cp.replace(/\D/g, '')) / 100;
    const ufNumerico = Number(cgs.replace(/\D/g, '')) / 100;
    const cbNumerico = Number(cb.replace(/\D/g, '')) / 100;
    const totalFiliados = filiadosAtivos.length;

    const calculoResult = (
      rcNumerico / totalFiliados -
      cjNUmerico / totalFiliados -
      cpNumerico / totalFiliados -
      cbNumerico / totalFiliados -
      ufNumerico / totalFiliados
    ).toFixed(2);
    setResult(calculoResult * -1);
  };

  const handleLimpar = () => {
    setCp('');
    setRc('');
    setCgs('');
    setResult(0);
  };
  const handleMouseOver = () => {
    setShowMessage(true);
  };

  const handleMouseOut = () => {
    setShowMessage(false);
  };

  return (
    <Paper elevation={3} className={styles.bodyCalculator}>
      <Typography variant="h5" align="start" gutterBottom>
        Cálculo do Custo-Filiado - R${' '}
        {result.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Filiados Ativos"
            value={filiadosAtivos.length.toLocaleString('pt-BR')}
            fullWidth
            variant="outlined"
            disabled
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
          {showMessage && (
            <Typography variant="body2" color="textSecondary">
              Esse valor representa o número total de filiados do Sindilegis, desconsiderando
              aqueles que estão inativos ou desfiliados.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor arrecadado com aluguel:"
            value={rc.toLocaleString('pt-BR')}
            onChange={(e) => setRc(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor pago ao jurídico:"
            value={cj.toLocaleString('pt-BR')}
            onChange={(e) => setCj(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label=" Valor pago à Porto Seguro:"
            value={cp.toLocaleString('pt-BR')}
            onChange={(e) => setCp(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor dos custos gerais do Sindilegis:"
            value={cgs}
            onChange={(e) => setCgs(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Valor investido no clube de benefícios:"
            value={cb.toLocaleString('pt-BR')}
            onChange={(e) => setCb(formatarValor(e.target.value))}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} container justifyContent="center">
          <Grid item xs={12} sm={3} container justifyContent="center">
            <ButtonComponent variant="insert" size="large" onClick={handleCalcular}>
              Calcular
            </ButtonComponent>
            <ButtonComponent variant="secondary" size="large" onClick={handleLimpar}>
              Limpar
            </ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CustoFiliado;
