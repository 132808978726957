import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  getBeneficiarios,
  setBeneficiarioId,
  setBeneficiario,
  saveBeneficiario,
  deleteBeneficiario,
} from '../../actions/beneficiario';
import { getExpenses } from '../../actions/financial';
import tableI18n from '../TableLocalization';
import BeneficiarioForm from './beneficiario';
import ConfirmDialog from '../commons/ConfirmDialog';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import MenuButton from '../commons/MenuButton';
import * as routes from '../../constants/routes';
import { getRoutes } from '../commons/util';
import ButtonComponent from '../commons/ButtonComponent';

class Beneficiarios extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
  };

  componentDidMount() {
    this.props.getExpenses();
    this.props.getBeneficiarios();
  }

  handleBeneficiarioClickOpen = () => {
    this.setState({ open: true });
  };

  handleBeneficiarioClose = (reload) => {
    this.setState({ open: false });
    this.props.setBeneficiario(null);
    this.props.setBeneficiarioId(null);
    if (reload) {
      this.reloadBeneficiarios();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadBeneficiarios() {
    this.props.getBeneficiarios();
  }

  render() {
    const { classes, beneficiarios, loading, location } = this.props;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          <Grid container item md={12} justifyContent="flex-end" spacing={2}>
            <Grid item>
              <ButtonComponent
                variant="insert"
                size="small"
                onClick={() => this.handleBeneficiarioClickOpen()}>
                Cadastrar beneficiário
              </ButtonComponent>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: 'Tipo', field: 'tipo' },
                  { title: 'CPF/CNPJ', field: 'cpfCnpj' },
                  { title: 'Nome', field: 'nome_beneficiario' },
                  { title: 'Sigla', field: 'sigla' },
                ]}
                data={beneficiarios}
                title="Beneficiários"
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (event, row) =>
                      this.props.setBeneficiarioId(row.id) && this.handleBeneficiarioClickOpen(),
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Excluir',
                    onClick: (event, row) =>
                      this.handleConfirmOpen(
                        () => this.removeBeneficiario(row.id),
                        'Confirma a exclusão permanente do beneficiário?'
                      ),
                  },
                  (row) =>
                    !row.desabilitado
                      ? {
                          icon: 'block',
                          iconProps: {
                            color: 'error',
                          },
                          tooltip: 'Desabilitar',
                          onClick: (event, row) => {
                            this.handleConfirmOpen(
                              () => this.desabilitarBeneficiario(row.id),
                              'Deseja desabilitar o beneficiário?'
                            );
                          },
                        }
                      : {
                          icon: 'add',
                          iconProps: {
                            color: 'primary',
                          },
                          tooltip: 'Habilitar',
                          onClick: (event, row) => {
                            this.handleConfirmOpen(
                              () => this.habilitarBeneficiario(row.id),
                              'Deseja habilitar o beneficiário?'
                            );
                          },
                        },
                ]}
                options={{
                  actionsColumnIndex: -1,
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <BeneficiarioForm open={this.state.open} handleClose={this.handleBeneficiarioClose} />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Paper>
      </div>
    );
  }

  habilitarBeneficiario(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveBeneficiario(id, { desabilitado: false }, () =>
      enqueueSnackbar('Beneficiário habilitado com sucesso.', {
        variant: 'success',
      })
    );
  }

  desabilitarBeneficiario(id) {
    const { enqueueSnackbar } = this.props;
    this.props.saveBeneficiario(id, { desabilitado: true }, () =>
      enqueueSnackbar('Beneficiário desabilitado com sucesso.', {
        variant: 'success',
      })
    );
  }

  removeBeneficiario(id) {
    const { enqueueSnackbar, expenses } = this.props;
    if (expenses.some((e) => e.id_beneficiario === id)) {
      enqueueSnackbar(
        'O Beneficiário está associado a uma ou mais despesas e não pode ser excluído.',
        {
          variant: 'error',
        }
      );
      return;
    }

    this.props.deleteBeneficiario(id);
  }
}

const mapStateToProps = ({
  beneficiario: { beneficiarios },
  financial: { expenses, loading },
}) => ({ beneficiarios, loading, expenses });

Beneficiarios.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getBeneficiarios,
    setBeneficiarioId,
    setBeneficiario,
    saveBeneficiario,
    deleteBeneficiario,
    getExpenses,
  })
)(withRouter(withSnackbar(Beneficiarios)));
