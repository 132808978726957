import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getExpenses } from '../../actions/financial';
import SindilegisBar from '../commons/SindilegisBar';
import RangeDatePicker from '../commons/RangeDatePicker';
import { downloadXlsx } from '../commons/util';
import styles from './styles';
import tableI18n from '../TableLocalization';
import { getDataInicial, getDataFinal, formataData, formatarValor } from './util';
import CustomDialog from '../commons/CustomDialog';
import TooltipText from '../commons/TooltipText';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import * as routes from '../../constants/routes';

class ListaDespesas extends React.Component {
  state = {
    loading: true,
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    filteredExpenses: [],
    dialog: {
      open: false,
      tipo: '',
      conteudo: '',
    },
  };

  componentDidMount() {
    this.props.getExpenses();
  }

  componentWillReceiveProps({ expenses }) {
    if (expenses) {
      this.filterExpenses(this.state.fromDate, this.state.toDate);
    }
  }

  handleDateChange(date) {
    this.setState(date);
    this.filterExpenses(date.fromDate, date.toDate);
  }

  filterExpenses(fromDate, toDate) {
    if (fromDate && toDate) {
      const filteredExpenses = this.props.expenses.filter(
        (e) => fromDate.getTime() <= e.data_vencimento && toDate.getTime() >= e.data_vencimento
      );
      this.setState({ filteredExpenses });
    }
  }

  handleOpenDialog = (tipo, conteudo) => {
    this.setState({
      dialog: {
        open: true,
        tipo,
        conteudo,
      },
    });
  };

  handleCloseDialog = () => {
    this.setState({
      dialog: {
        open: false,
        tipo: '',
        conteudo: '',
      },
    });
  };

  handleOpenItemDespesa = (selectedItemDespesa) => {
    this.handleOpenDialog('Item de despesa', selectedItemDespesa);
  };

  handleOpenDescricao = (selectedDescricao) => {
    this.handleOpenDialog('Descrição', selectedDescricao);
  };

  render() {
    const { classes, location } = this.props;
    const { dialog } = this.state;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <div>
        <SindilegisBar />
        <Container maxWidth="xl">
          <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
          <Grid
            container
            justifyContent="center"
            spacing={4}
            item
            md={4}
            className={classes.areaOperacoes}>
            <RangeDatePicker
              fromDate={this.state.fromDate}
              toDate={this.state.toDate}
              onChange={(data) => this.handleDateChange(data)}
            />
          </Grid>
          <MaterialTable
            columns={[
              {
                title: 'Data de vencimento',
                field: 'data_vencimento',
                render: (row) => formataData(row.data_vencimento),
              },
              {
                title: 'Item de despesa',
                field: 'item_despesa',
                render: (rowData) => (
                  <TooltipText
                    text={rowData.item_despesa}
                    onClick={() => this.handleOpenItemDespesa(rowData.item_despesa)}
                  />
                ),
              },
              {
                title: 'Descrição',
                field: 'descricao',
                render: (rowData) => (
                  <TooltipText
                    text={rowData.descricao}
                    onClick={() => this.handleOpenDescricao(rowData.descricao)}
                  />
                ),
              },
              { title: 'Centro de custo', field: 'centro_custo' },
              {
                title: 'Valor da parcela',
                field: 'valor',
                render: (row) => <span>{formatarValor(row.valor)}</span>,
              },
              { title: 'Tipo de despesa', field: 'tipo_despesa' },
              { title: 'Beneficiário', field: 'nome_beneficiario' },
              { title: 'CPF/CNPJ', field: 'cpfCnpj' },
              { title: 'Nº parcelas', field: 'parcela' },
              { title: 'Meio de pagamento', field: 'meio_pagamento' },
              { title: 'Número do documento', field: 'numero_documento' },
              { title: 'Tipo do documento', field: 'tipo_documento' },
              {
                title: 'Data de liquidação',
                field: 'data_liquidacao_pagamento',
                render: (row) => formataData(row.data_vencimento),
              },
              { title: 'Banco', field: 'banco_liquidacao' },
              { title: 'Número', field: 'numero_pagamento' },
            ]}
            data={this.state.filteredExpenses}
            title="Relatório de despesas"
            localization={tableI18n}
            options={{
              filtering: true,
              exportButton: true,
              exportAllData: true,
              exportDelimiter: ';',
              exportFileName: 'relatorio de despesas',
              exportCsv: (columns, data) => {
                data.forEach((d) => {
                  d.data_vencimento = formataData(d.data_vencimento);
                  d.data_liquidacao_pagamento = formataData(d.data_liquidacao_pagamento);
                });

                downloadXlsx(columns, data, 'despesas.xlsx');
              },
              padding: 'dense',
            }}
            icons={{ Filter: () => <span></span> }}
          />
        </Container>
        <CustomDialog
          open={dialog.open}
          onClose={this.handleCloseDialog}
          title={dialog.tipo}
          content={dialog.conteudo}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ financial }) => ({
  expenses: financial.expenses,
});

ListaDespesas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getExpenses })
)(withRouter(ListaDespesas));
