export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: 20,
    marginTop: 40,
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(1) * 2,
    right: theme.spacing(1) * 2,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  itensDestaqueContainer: {
    marginLeft: 12,
  },
  itensDestaque: {
    padding: 12,
    marginTop: 8,
    borderRadius: 4,
    boxShadow: '0px 5px 5px -1px rgba(214,215,227,0.44)',
  },
  inputFileHidden: {
    display: 'none',
  },
  verticalDivider: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    paddingRight: 32,
  },
  anexarDocumentoLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
  },
  truncateDocumentoAnexoChip: {
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  docsUploadedContainer: {
    marginTop: 4,
  },
  button: {
    marginTop: 10,
    marginBottom: 10,
  },
  media: {
    height: 140,
  },
  title: {
    marginTop: 40,
  },
  subtitle: {
    fontWeight: 500,
  },
  cardTitle: {
    fontSize: 14,
  },
  areaOperacoes: {
    marginTop: 16,
    marginBottom: 16,
  },
  containerBottomPadding: {
    paddingBottom: 80,
  },
  pointer: {
    cursor: 'pointer',
  },
  alertMsg: {
    marginTop: 12,
  },
  mainPaper: {
    marginTop: 40,
  },
  buttonGroup: {
    marginTop: 8,
    marginBottom: 16,
  },
  dadosGeraisContainer: {},
  dadosItensDestaqueContainer: {
    marginTop: 24,
  },
  dadosPessoasContainer: {
    marginTop: 12,
  },
  dadosProcessosContainer: {
    marginTop: 12,
  },
  dadosAcoesSindilegisContainer: {
    marginTop: 12,
  },
  dadosCustosContainer: {
    marginTop: 12,
  },
  leftTitles: {
    fontSize: 15,
    fontWeight: 600,
    width: 150,
    paddingLeft: 40,
    wordBreak: 'break-word',
    textTransform: 'uppercase',
  },
  containerValues: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    paddingLeft: 20,
    paddingRight: 16,
  },
  labelTypography: {
    wordWrap: 'break-word',
    lineHeight: 0.26,
    overflowWrap: 'break-word',
    hyphens: 'auto',
  },
  valueTypography: {
    marginLeft: 8,
    fontWeight: 600,
  },
  selectedButton: {
    backgroundColor: '#F3FAFB',
  },
  currentSection: {
    // backgroundColor: '#F9F9F9',
    boxShadow: '0px 5px 5px -1px rgba(214,215,227,0.44)',
  },
  overrideBtnPrimary: {
    backgroundColor: 'rgba( 0, 0, 0, 0.09 )',
    borderRadius: 4,
    cursor: 'pointer',
    marginTop: 4,
    marginBottom: 4,
    transition: 'all 0.3s',
    '&:hover': {
      backgroundColor: 'rgba( 0, 0, 0, 0.16 )',
    },
  },
  padding16: {
    padding: 16,
  },
});
