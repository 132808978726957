import {
  DOCUMENTOS_TRANSPARENCIA_REQUEST,
  DOCUMENTO_TRANSPARENCIA_UPDATED,
  DOCUMENTO_TRANSPARENCIA_SET,
  DELETE_DOCUMENTO_TRANSPARENCIA,
  DOCUMENTOS_TRANSPARENCIA_SET,
  DOCUMENTO_TRANSPARENCIA_ID_SET,
} from './types';
import { db } from '../firebase';

export const saveDocumentoTransparencia = (id, data, callback) => {
  return (dispatch) => {
    db.doSaveDocumentoTransparencia(id, data).then(() => {
      dispatch({ type: DOCUMENTO_TRANSPARENCIA_UPDATED, payload: { id, data } });
      callback && callback();
    });
  };
};

export const criarDocumentoTransparencia = (data, callback) => {
  return (dispatch) => {
    db.doCreateDocumentoTransparencia(data).then((ref) => {
      callback && callback(ref.key);
    });
  };
};

export const incluirAnexoDocumentoTransparencia = (id, path, email) => {
  return (dispatch) => {
    db.doIncluirAnexoDocumentoTransparencia(id, path, email);
  };
};

export const getDocumentosTransparencia = () => {
  return (dispatch) => {
    dispatch({ type: DOCUMENTOS_TRANSPARENCIA_REQUEST });
    db.onceGetDocumentosTransparencia().then((snapshot) => {
      let documentosTransparencia = snapshot.val();
      if (documentosTransparencia) {
        documentosTransparencia = Object.keys(documentosTransparencia).map((id) => {
          return { ...documentosTransparencia[id], id };
        });
      } else {
        documentosTransparencia = [];
      }
      dispatch({ type: DOCUMENTOS_TRANSPARENCIA_SET, documentosTransparencia });
    });
  };
};

export const getDocumentoTransparencia = (id) => {
  return (dispatch) => {
    db.onceGetDocumentoTransparencia(id).then((snapshot) => {
      const documentoTransparencia = snapshot.val();
      dispatch({ type: DOCUMENTO_TRANSPARENCIA_SET, documentoTransparencia });
    });
  };
};

export const deleteDocumentoTransparencia = (id) => {
  return (dispatch) => {
    db.doDeleteDocumentoTransparencia(id).then(() => {
      dispatch({ type: DELETE_DOCUMENTO_TRANSPARENCIA, payload: { id } });
    });
  };
};

export const setDocumentoTransparencia = (documentoTransparencia) => (dispatch) =>
  dispatch({ type: DOCUMENTO_TRANSPARENCIA_SET, documentoTransparencia });

export const setDocumentoTransparenciaId = (documentoTransparenciaId) => (dispatch) =>
  dispatch({ type: DOCUMENTO_TRANSPARENCIA_ID_SET, documentoTransparenciaId });
