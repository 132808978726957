export default (values) => {
  const errors = {};
  const requiredFields = [
    'item_despesa',
    'valor',
    'centro_custo',
    'data_vencimento',
    'descricao',
    'numero_parcelas',
    'id_beneficiario',
    'tipo_despesa',
    'meio_pagamento',
    'numero_documento',
    'tipo_documento',
  ];

  if (values.data_liquidacao_pagamento !== undefined) {
    requiredFields.push('data_liquidacao_pagamento');
    requiredFields.push('banco_liquidacao');
    requiredFields.push('numero_pagamento');
  }

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};
