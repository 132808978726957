import { createTextMask } from 'redux-form-input-masks';

export const dateMask = createTextMask({
  pattern: '99/99/9999',
  stripMask: false,
});

export const cpfMask = createTextMask({
  pattern: '999.999.999-99',
  allowEmpty: true,
  stripMask: false,
});

export const cnpjMask = createTextMask({
  pattern: '99.999.999/9999-99',
  allowEmpty: true,
  stripMask: false,
});

export const cepMask = createTextMask({
  pattern: '99999-999',
  allowEmpty: true,
  stripMask: false,
});

export const aplicarMascaraCPF = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
};

export const removerMascaraCPF = (value) => {
  return value.replace(/\D/g, '');
};

export const celularMask = createTextMask({
  pattern: '(99)99999-9999',
  allowEmpty: true,
  stripMask: false,
});

export const aplicarMascaraCelular = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1)$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const removerMascaraCelular = (value) => {
  return value.replace(/\D/g, '');
};

export const telefoneMask = createTextMask({
  pattern: '(99)99999-9999',
  allowEmpty: true,
  stripMask: false,
});

export const aplicarMascaraTelefone = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1)$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
};

export const removerMascaraTelefone = (value) => {
  return value.replace(/\D/g, '');
};
