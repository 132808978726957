import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getExpenses, getReceitasEDespesas } from '../../actions/financial';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import styles from './styles';
import { downloadXlsx, getWorkSheet, downloadWorksheets } from '../commons/util';
import {
  somadorDouble,
  formatarValor,
  calcularEstorno,
  formataData,
  mesAnoToPeriodo,
  getMesAnoProjecao,
} from './util';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import * as routes from '../../constants/routes';

const columnsDespesa = [
  { title: 'Data de vencimento', field: 'data_vencimento' },
  { title: 'Item de despesa', field: 'item_despesa' },
  { title: 'Descrição', field: 'descricao' },
  { title: 'Centro de custo', field: 'centro_custo' },
  { title: 'Valor da parcela', field: 'valor' },
  { title: 'Tipo de despesa', field: 'tipo_despesa' },
  { title: 'Beneficiário', field: 'nome_beneficiario' },
  { title: 'CPF/CNPJ', field: 'cpfCnpj' },
  { title: 'Nº parcelas', field: 'parcela' },
  { title: 'Meio de pagamento', field: 'meio_pagamento' },
  { title: 'Número do documento', field: 'numero_documento' },
  { title: 'Tipo do documento', field: 'tipo_documento' },
  { title: 'Data de liquidação', field: 'data_liquidacao_pagamento' },
  { title: 'Banco', field: 'banco_liquidacao' },
  { title: 'Número', field: 'numero_pagamento' },
];
const columnsReceita = [
  { title: 'Item', field: 'item_receita' },
  { title: 'Tipo', field: 'tipo' },
  { title: 'Descrição', field: 'descricao' },
  { title: 'Valor', field: 'valor' },
  { title: 'Data', field: 'data' },
  { title: 'Parcela', field: 'parcela' },
];

const dateFns = new DateFnsUtils({ locale: ptLocale });
class Financial extends React.Component {
  state = {
    inicioDoDia: dateFns.startOfDay(new Date()).getTime(),
    proximaSemana: dateFns.addDays(dateFns.endOfDay(new Date()), 7).getTime(),
    hoje: dateFns.endOfDay(new Date()).getTime(),
  };

  componentDidMount() {
    this.props.getReceitasEDespesas();
  }

  render() {
    const { loading, location } = this.props;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);
    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
            {_.map(this.getData(), (item) => {
              return this.renderAnoCalendario(item);
            })}
          </Container>
        )}
      </React.Fragment>
    );
  }

  renderAnoCalendario(data) {
    return (
      <MaterialTable
        style={{ marginTop: 16, marginBottom: 16 }}
        columns={[
          { title: 'Período', field: 'periodo' },
          {
            title: 'Receitas',
            field: 'receitas',
            render: (row) => (
              <div style={{ textAlign: 'right' }}>{formatarValor(row.receitas)}</div>
            ),
          },
          {
            title: 'Despesas liquidadas',
            field: 'despesas_liquidadas',
            render: (row) => (
              <div style={{ textAlign: 'right' }}>{formatarValor(row.despesas_liquidadas)}</div>
            ),
          },
          {
            title: 'Despesas a liquidar',
            field: 'despesas_liquidar',
            render: (row) => (
              <div style={{ textAlign: 'right' }}>{formatarValor(row.despesas_liquidar)}</div>
            ),
          },
          {
            title: 'Saldo no mês',
            field: 'saldo',
            render: (row) => <div style={{ textAlign: 'right' }}>{formatarValor(row.saldo)}</div>,
          },
          {
            title: 'Saldo acumulado',
            field: 'saldoAcumulado',
            render: (row) => (
              <div style={{ textAlign: 'right' }}>{formatarValor(row.saldoAcumulado)}</div>
            ),
          },
        ]}
        key={data.ano}
        data={_.orderBy(data, [(i) => parseInt(i.periodo.split('/')[0])])}
        title={`${data.ano >= new Date().getFullYear() ? 'Projeção ' : ''} ${data.ano}`}
        localization={tableI18n}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
          sorting: false,
          grouping: false,
          exportButton: true,
          exportAllData: true,
          exportCsv: (columns, data) => {
            downloadXlsx(columns, data, 'projecao geral.xlsx');
          },
          padding: 'dense',
          rowStyle: (row) =>
            row.periodo === 'Total'
              ? {
                  backgroundColor: '#333',
                  color: '#FFF',
                }
              : {},
          headerStyle: {
            backgroundColor: '#01579b',
            color: '#FFF',
          },
        }}
        actions={[
          {
            icon: 'cloud_download',
            tooltip: 'Exportar despesas',
            onClick: (event, { periodo }) => {
              const dataDespesa = this.getDespesaParaExcel(periodo);
              const dataReceita = this.getReceitasParaExcel(periodo);
              const wsDespesa = getWorkSheet(columnsDespesa, dataDespesa);
              const wsReceita = getWorkSheet(columnsReceita, dataReceita);
              downloadWorksheets(
                [
                  { ws: wsDespesa, sheetName: 'Despesas' },
                  { ws: wsReceita, sheetName: 'Receitas' },
                ],
                `projecao_${periodo}.xlsx`
              );
            },
          },
        ]}
      />
    );
  }

  getDespesaParaExcel(periodo) {
    const data =
      periodo === 'Total' ? this.getExpensesByRange() : this.getExpensesByPeriodo(periodo);
    data.forEach((d) => {
      d.data_vencimento = formataData(d.data_vencimento);
      d.data_liquidacao_pagamento = formataData(d.data_liquidacao_pagamento);
    });
    return data;
  }

  getReceitasParaExcel(periodo) {
    const data = periodo === 'Total' ? this.props.receitas : this.getReceitasByPeriodo(periodo);
    data.forEach((d) => {
      d.data = formataData(d.data);
    });
    return data;
  }

  getReceitasByPeriodo(mesAno) {
    const { fromDate, toDate } = mesAnoToPeriodo(mesAno);
    return this.props.receitas.filter(
      (e) => fromDate.getTime() <= e.data && toDate.getTime() >= e.data
    );
  }

  getExpensesByPeriodo(mesAno) {
    const { fromDate, toDate } = mesAnoToPeriodo(mesAno);
    return this.props.expenses.filter(
      (e) => fromDate.getTime() <= e.data_vencimento && toDate.getTime() >= e.data_vencimento
    );
  }
  getExpensesByRange() {
    return this.props.expenses;
  }

  getData() {
    const despesasAgrupadas = _.groupBy(this.props.expenses, (e) =>
      getMesAnoProjecao(e.data_vencimento)
    );
    const receitasAgrupadas = _.groupBy(this.props.receitas, (e) => getMesAnoProjecao(e.data));

    let data = Object.keys(despesasAgrupadas).map((periodo) => {
      const despesas_liquidadas = despesasAgrupadas[periodo]
        .filter((e) => !!e.data_liquidacao_pagamento)
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);
      const despesas_liquidar = despesasAgrupadas[periodo]
        .filter((e) => !e.data_liquidacao_pagamento)
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);
      const receitas = _.map(receitasAgrupadas[periodo], (r) => calcularEstorno(r))
        .reduce(somadorDouble, 0)
        .toFixed(2);
      const saldo = (
        receitas - [despesas_liquidadas, despesas_liquidar].reduce(somadorDouble, 0)
      ).toFixed(2);
      return { periodo, despesas_liquidadas, despesas_liquidar, receitas, saldo };
    });

    data = _.orderBy(data, [(i) => parseInt(i.periodo.split('/')[0])]);
    const grupoPorAno = _.groupBy(data, (i) =>
      i.periodo.substring(i.periodo.length - 4, i.periodo.length)
    );
    Object.keys(grupoPorAno).forEach((g) => this.preencherTotal(grupoPorAno[g]));
    Object.keys(grupoPorAno).map((ano) => (grupoPorAno[ano].ano = ano));
    return _.orderBy(_.values(grupoPorAno), ['ano'], ['desc']);
  }

  preencherTotal(data) {
    data.forEach((i, index) => {
      if (index === 0) {
        i.saldoAcumulado = i.saldo;
        return;
      }
      i.saldoAcumulado = (parseFloat(i.saldo) + parseFloat(data[index - 1].saldoAcumulado)).toFixed(
        1
      );
    });

    const total = {
      periodo: 'Total',
      despesas_liquidadas: data.map((i) => i.despesas_liquidadas).reduce(somadorDouble, 0),
      despesas_liquidar: data.map((i) => i.despesas_liquidar).reduce(somadorDouble, 0),
      receitas: data
        .map((i) => i.receitas)
        .reduce(somadorDouble, 0)
        .toFixed(2),
      saldoAcumulado: data
        .map((i) => i.saldo)
        .reduce(somadorDouble, 0)
        .toFixed(2),
    };
    data.push(total);
  }
}

const mapStateToProps = ({ financial: { expenses, receitas, loading } }) => ({
  expenses,
  receitas,
  loading,
});

Financial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getExpenses, getReceitasEDespesas })
)(withRouter(Financial));
