import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../../commons/InputText';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import {
  adicionarEventoProcesso,
  editarEventoProcesso,
  getProcessos,
  getProcesso,
} from '../../../actions/juridico';
import Switch from '../../commons/Switch';
import AnexoEvento from './anexo_evento';
import _ from 'lodash';
import * as roles from '../../../constants/roles';

class EventoProcessoForm extends React.Component {
  state = { anexos: [] };

  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.props.getProcesso();
    if (this.props.evento && this.props.evento.anexos) {
      this.setState({ anexos: this.props.evento.anexos });
    }
  }

  componentWillReceiveProps({ processoId }) {
    if (processoId) {
      this.props.getProcesso(processoId);
    }
  }

  save(evento) {
    const { processo, adicionarEventoProcesso, eventoId, editarEventoProcesso } = this.props;
    const { anexos } = this.state;

    if (evento.id) {
      editarEventoProcesso(processo, eventoId, evento, anexos, () => this.successUpdateHandler());
    } else {
      adicionarEventoProcesso(processo, evento, anexos, () => this.successHandler());
    }
    this.props.getProcessos();
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Evento adicionado com sucesso!', {
      variant: 'success',
    });
    this.props.reset();
    handleClose(reload);
  }

  successUpdateHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Evento atualizado com sucesso!', {
      variant: 'success',
    });
    this.props.reset();
    handleClose(reload);
  }

  cancelar() {
    this.props.reset();
    this.props.handleClose();
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar evento.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, invalid, submitting, authUser } = this.props;
    const isJuridico = _.includes(authUser.roles, roles.JURIDICO);

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Novo evento processual</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field name="titulo" component={InputText} multiline={false} label="Título" />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field
                  name="descricao"
                  component={InputText}
                  multiline={true}
                  rows={3}
                  label="Descrição"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
              {this.props.processo && this.props.processo.filiados && (
                <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
                  <Grid item sm={4}>
                    <Field name="comunicacao" component={Switch} label="Comunicar filiados" />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item sm={12} style={{ paddingTop: 12 }}>
              <AnexoEvento
                onChange={(anexos) => {
                  this.setState({ anexos }, () => {
                    this.props.change('anexosFlag', new Date().getTime());
                  });
                }}
                canDelete={isJuridico}
                anexos={this.props.evento && this.props.evento.anexos}
              />
              <Field name="anexosFlag" component="input" type="hidden" />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.cancelar.bind(this)} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting || invalid}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

EventoProcessoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const { evento } = ownProps;
  const { processo } = state.juridico;

  return {
    processo,
    initialValues: evento,
  };
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['titulo', 'descricao'];
  requiredFields.forEach((field) => {
    if (!values[field] || (Array.isArray(values[field]) && values[field].length === 0)) {
      errors[field] = ' Obrigatório';
    }
  });

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    adicionarEventoProcesso,
    editarEventoProcesso,
    getProcessos,
    getProcesso,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'eventoProcesso',
    validate,
    enableReinitialize: true,
  })(withSnackbar(EventoProcessoForm))
);
