import React, { Component } from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

export default class CheckboxGroup extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
    row: PropTypes.string,
  };

  field = ({ input, meta, options, title, row }) => {
    const { name, onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value;

    const checkboxes = options.map(({ label, value }, index) => {
      const handleChange = (event) => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(value);
        } else {
          arr.splice(arr.indexOf(value), 1);
        }
        return onChange(arr);
      };
      const checked = inputValue.includes(value);
      return (
        <FormControlLabel
          key={`checkbox-${index}`}
          label={label}
          control={
            <Checkbox
              name={`${name}[${index}]`}
              checked={checked}
              onChange={handleChange}
              value={value}
              size="small"
            />
          }
        />
      );
    });

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <FormGroup row={row}>{checkboxes}</FormGroup>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  };

  render() {
    return <Field {...this.props} type="checkbox" component={this.field} />;
  }
}
