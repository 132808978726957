import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import RangeDatePicker from '../../components/commons/RangeDatePicker';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import * as routes from '../../constants/routes';
import { getExpenses, getReceitasEDespesas } from '../../actions/financial';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import styles from './styles';
import {
  getDataInicial,
  getDataFinal,
  somadorDouble,
  formatarValor,
  calcularEstorno,
  getMesAno,
} from './util';
import ButtonComponent from '../commons/ButtonComponent';
import { getRoutes } from '../commons/util';
import MenuButton from '../commons/MenuButton';

const dateFns = new DateFnsUtils({ locale: ptLocale });
const COLORS = ['#ff5822', '#01bcd2'];
class Financial extends React.Component {
  state = {
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    filteredExpenses: [],
    filteredReceitas: [],
    despesasVencidas: 0,
    despesasDaSemana: 0,
    despesasDoDia: 0,
    inicioDoDia: dateFns.startOfDay(new Date()).getTime(),
    proximaSemana: dateFns.addDays(dateFns.endOfDay(new Date()), 7).getTime(),
    hoje: dateFns.endOfDay(new Date()).getTime(),
  };

  componentDidMount() {
    this.props.getReceitasEDespesas();
  }

  componentWillReceiveProps({ expenses, receitas }) {
    const { inicioDoDia, proximaSemana, hoje } = this.state;

    if (expenses) {
      const despesasDaSemana = expenses
        .filter(
          (e) =>
            !e.data_liquidacao_pagamento &&
            e.data_vencimento >= inicioDoDia &&
            e.data_vencimento <= proximaSemana
        )
        .map((e) => calcularEstorno(e))
        .reduce((a, b) => a + b, 0);
      const despesasDoDia = expenses
        .filter(
          (e) =>
            !e.data_liquidacao_pagamento &&
            e.data_vencimento >= inicioDoDia &&
            e.data_vencimento <= hoje
        )
        .map((e) => calcularEstorno(e))
        .reduce((a, b) => a + b, 0);
      const despesasVencidas = expenses
        .filter((e) => !e.data_liquidacao_pagamento && e.data_vencimento < inicioDoDia)
        .map((e) => calcularEstorno(e))
        .reduce((a, b) => a + b, 0);
      this.setState({ despesasDaSemana, despesasDoDia, despesasVencidas });
    }
    this.filterExpenses(this.state.fromDate, this.state.toDate);
  }
  handleDateChange(date) {
    this.setState(date);
    this.filterExpenses(date.fromDate, date.toDate);
  }

  filterExpenses(fromDate, toDate) {
    if (fromDate && toDate) {
      const filteredExpenses = this.props.expenses.filter(
        (e) => fromDate.getTime() <= e.data_vencimento && toDate.getTime() >= e.data_vencimento
      );

      const filteredReceitas = this.props.receitas.filter(
        (e) => fromDate.getTime() <= e.data && toDate.getTime() >= e.data
      );

      this.setState({ filteredExpenses, filteredReceitas });
    }
  }

  clickHandleDespesasLiquidar(start, end) {
    return () => this.props.history.push(`financial/despesas/${start}/${end}`);
  }

  render() {
    const { classes, loading, history, location } = this.props;
    const { inicioDoDia, hoje, proximaSemana } = this.state;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="flex-start"
              className={classes.areaOperacoes}
              style={{ marginLeft: 4 }}>
              <Grid container item md={6} justifyContent="center" spacing={2}>
                {this.renderCard(
                  this.state.despesasVencidas,
                  'Despesas vencidas',
                  this.clickHandleDespesasLiquidar(0, inicioDoDia)
                )}
                {this.renderCard(
                  this.state.despesasDoDia,
                  'Despesas do dia',
                  this.clickHandleDespesasLiquidar(inicioDoDia, hoje)
                )}
                {this.renderCard(
                  this.state.despesasDaSemana,
                  'Despesas próximos 7 dias',
                  this.clickHandleDespesasLiquidar(inicioDoDia, proximaSemana)
                )}
              </Grid>
            </Grid>
            <Grid
              container
              item
              sm={12}
              spacing={2}
              justifyContent="center"
              alignItems="center"
              className={classes.areaGrafico}>
              <Card style={{ width: 'calc(100% - 32px)' }}>
                <CardContent>
                  <Grid
                    container
                    item
                    md={4}
                    spacing={4}
                    justifyContent="center"
                    alignItems="center">
                    <RangeDatePicker
                      fromDate={this.state.fromDate}
                      toDate={this.state.toDate}
                      onChange={(data) => this.handleDateChange(data)}
                    />
                  </Grid>
                  <Grid container item sm={12} spacing={2}>
                    <Grid container item md={6} justifyContent="center">
                      {this.renderPieGraph()}
                    </Grid>

                    <Grid container item md={6} justifyContent="center">
                      {this.renderBarChart()}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              item
              md={12}
              justifyContent="flex-end"
              spacing={2}
              style={{ padding: 8 }}>
              <Grid item>
                <ButtonComponent
                  variant="default"
                  size="small"
                  onClick={() => history.push(routes.SUPRIMENTOS)}>
                  Suprimentos
                </ButtonComponent>
              </Grid>
            </Grid>
          </Container>
        )}
      </React.Fragment>
    );
  }

  renderCard(valor, texto, action) {
    const { classes } = this.props;

    return (
      <Grid item md={4}>
        <Card onClick={action} className={classes.cardLancamentos}>
          <CardContent>
            <Typography className={classes.cardTitle} color="textSecondary">
              {texto}
            </Typography>
            <Typography color="textPrimary">{formatarValor(valor)}</Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  renderBarChart() {
    const data = this.getColumnData();

    return (
      <BarChart
        width={600}
        height={400}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="receitas" name="Receitas" fill="#00b700" />
        <Bar dataKey="despesas_liquidadas" name="Depesas liquidadas" fill="#01b7cf" />
        <Bar dataKey="despesas_liquidar" name="Depesas a liquidar" fill="#ff5822" />
        <Bar dataKey="saldo" name="Saldo" fill="#3F51B5" />
      </BarChart>
    );
  }

  renderPieGraph() {
    const data = this.getPieData();

    return (
      <PieChart width={400} height={400}>
        <Tooltip />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          fill="#8884d8"
          label={false}
          labelLine={false}
          legendType="square">
          {data.map((entry, index) => (
            <Cell key={index} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          formatter={(value, { color, payload }) => (
            <span style={{ color: color }}>
              {`${value}:`}
              {formatarValor(payload.value)}
            </span>
          )}
        />
      </PieChart>
    );
  }

  getPieData() {
    const liquidados = this.props.expenses
      .filter(
        (e) =>
          this.state.fromDate.getTime() <= e.data_vencimento &&
          this.state.toDate.getTime() >= e.data_vencimento
      )
      .filter((e) => !!e.data_liquidacao_pagamento)
      .map((e) => calcularEstorno(e))
      .reduce(somadorDouble, 0);
    const pagamentos = this.props.expenses
      .filter(
        (e) =>
          this.state.fromDate.getTime() <= e.data_vencimento &&
          this.state.toDate.getTime() >= e.data_vencimento
      )
      .filter((e) => !e.data_liquidacao_pagamento)
      .map((e) => calcularEstorno(e))
      .reduce(somadorDouble, 0);
    const data = [
      { name: 'Total de gastos não liquidados', value: pagamentos },
      { name: 'Total de gastos liquidados', value: liquidados },
    ];
    return data;
  }

  getColumnData() {
    const despesasAgrupadas = _.groupBy(
      this.props.expenses.filter(
        (e) =>
          this.state.fromDate.getTime() <= e.data_vencimento &&
          this.state.toDate.getTime() >= e.data_vencimento
      ),
      (e) => getMesAno(e.data_vencimento)
    );
    const receitasAgrupadas = _.groupBy(
      this.props.receitas.filter(
        (e) => this.state.fromDate.getTime() <= e.data && this.state.toDate.getTime() >= e.data
      ),
      (e) => getMesAno(e.data)
    );

    const data = Object.keys(despesasAgrupadas).map((name) => {
      const despesas_liquidadas = despesasAgrupadas[name]
        .filter((e) => !!e.data_liquidacao_pagamento)
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);

      const despesas_liquidar = despesasAgrupadas[name]
        .filter((e) => !e.data_liquidacao_pagamento)
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);
      const receitas = _.map(receitasAgrupadas[name], (r) => calcularEstorno(r)).reduce(
        somadorDouble,
        0
      );
      const saldo = (
        receitas - [despesas_liquidadas, despesas_liquidar].reduce(somadorDouble, 0)
      ).toFixed(2);
      return { name, despesas_liquidadas, despesas_liquidar, receitas, saldo };
    });
    return data;
  }
}

const mapStateToProps = ({ financial: { expenses, receitas, loading } }) => ({
  expenses,
  receitas,
  loading,
});

Financial.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getExpenses, getReceitasEDespesas })
)(withRouter(Financial));
