import React from 'react';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return <NumberFormat {...other} getInputRef={inputRef} allowNegative={false} decimalScale={0} />;
}

export default (props) => {
  const {
    input,
    label,
    meta: { touched, error },
    ...custom
  } = props;
  return (
    <TextField
      label={label}
      InputLabelProps={{ shrink: true }}
      error={touched && !!error}
      {...input}
      {...custom}
      margin="dense"
      helperText={error}
      fullWidth
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};
