import React, { useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFiliados } from '../../actions/filiado';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import BarCharts from './Charts/ChartsBar';
import { paletaAuxiliarMedio } from '../commons/SindilegisColors';
import StackedBarChart from './Charts/StackedBarChart';
import { calcularIdade } from '../commons/util';
import * as routes from '../../constants/routes';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  media: {
    height: 140,
  },
  title: {
    marginTop: theme.spacing(5),
  },
  cardTitle: {
    fontSize: 14,
  },
  areaOperacoes: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  containerBottomPadding: {
    paddingBottom: theme.spacing(10),
  },
  gradientLine: {
    height: '4px',
    background: 'linear-gradient(to right, #fcd8da, #c4eaf5, #d7e9d1, #fbf6b4)',
    marginTop: '-5px',
  },
}));

const EMPRESAS = ['SENADO', 'CAMARA', 'TCU'];

const SITUACOES = ['ATIVO', 'APOSENTADO', 'PENSIONISTA'];

const calcularFaixaEtaria = (idade) => {
  if (idade === null || idade === undefined || idade === '' || idade < 0) return 'Não informado';
  if (idade <= 20) return '0-20';
  if (idade <= 40) return '20-40';
  if (idade <= 60) return '40-60';
  if (idade <= 80) return '60-80';
  if (idade <= 100) return '80-100';
  return '+100';
};

const contarGenerosPorEmpresa = (filiados) => {
  const resultados = {};
  filiados.forEach((filiado) => {
    const empresa = EMPRESAS.includes(filiado.empresa) ? filiado.empresa : 'Não informado';
    const sexo =
      filiado.sexo === 'Masculino' || filiado.sexo === 'Feminino' ? filiado.sexo : 'Não informado';
    if (!resultados[empresa]) {
      resultados[empresa] = { Masculino: 0, Feminino: 0, 'Não informado': 0 };
    }
    resultados[empresa][sexo] = (resultados[empresa][sexo] || 0) + 1;
  });
  return Object.keys(resultados).map((empresa) => ({ empresa, ...resultados[empresa] }));
};

const idadePorCasa = (filiados) => {
  const filiadosComIdade = filiados.map((filiado) => ({
    ...filiado,
    idade: calcularIdade(filiado.data_nascimento),
  }));

  const resultados = {};
  filiadosComIdade.forEach((filiado) => {
    const empresa = EMPRESAS.includes(filiado.empresa) ? filiado.empresa : 'Não informado';
    const faixaEtaria = calcularFaixaEtaria(filiado.idade);

    if (!resultados[empresa]) {
      resultados[empresa] = {
        '0-20': 0,
        '20-40': 0,
        '40-60': 0,
        '60-80': 0,
        '80-100': 0,
        '+100': 0,
        'Não informado': 0,
      };
    }

    resultados[empresa][faixaEtaria] = (resultados[empresa][faixaEtaria] || 0) + 1;
  });

  return Object.keys(resultados).map((empresa) => ({ empresa, ...resultados[empresa] }));
};

const contarSituacoesPorEmpresa = (filiados) => {
  const resultados = {};
  filiados.forEach((filiado) => {
    const empresa = EMPRESAS.includes(filiado.empresa) ? filiado.empresa : 'Não informado';
    const situacao = SITUACOES.includes(filiado.situacao_filiado)
      ? filiado.situacao_filiado
      : 'Não informado';
    if (!resultados[empresa]) {
      resultados[empresa] = { ATIVO: 0, APOSENTADO: 0, PENSIONISTA: 0, 'Não informado': 0 };
    }
    resultados[empresa][situacao] = (resultados[empresa][situacao] || 0) + 1;
  });
  return Object.keys(resultados).map((empresa) => ({ empresa, ...resultados[empresa] }));
};

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filiados, loading } = useSelector((state) => ({
    filiados: state.filiado.filiados,
    loading: state.filiado.loading,
  }));
  const filiadosAtivos = filiados.filter((filiado) => !filiado.data_desfiliacao);

  useEffect(() => {
    dispatch(getFiliados());
  }, [dispatch]);

  const empresaMap = {
    SENADO: 'Senado Federal',
    CAMARA: 'Câmara dos Deputados',
    TCU: 'Tribunal de Contas da União',
  };

  const dataCards = useMemo(() => {
    const empresasCalculadas = EMPRESAS.map((empresa) => {
      const filteredFiliados = filiadosAtivos.filter((filiado) => filiado?.empresa === empresa);
      const situacoes = SITUACOES.reduce((situacaoAcc, situacao) => {
        situacaoAcc[situacao] = filteredFiliados.filter(
          (filiado) => filiado?.situacao_filiado === situacao
        ).length;
        return situacaoAcc;
      }, {});

      const total = Object.values(situacoes).reduce((acc, value) => acc + value, 0);

      const empresaNome = empresaMap[empresa] || empresa;

      return { name: empresaNome, ...situacoes, total };
    });

    const totalSindilegis = empresasCalculadas.reduce(
      (acc, empresa) => {
        Object.keys(acc).forEach((key) => {
          acc[key] += empresa[key] || 0;
        });
        return acc;
      },
      { ATIVO: 0, APOSENTADO: 0, PENSIONISTA: 0, total: 0 }
    );

    empresasCalculadas.push({ name: 'Sindilegis', ...totalSindilegis });

    return empresasCalculadas;
  }, [filiadosAtivos, empresaMap]);

  const generoXCasa = useMemo(() => contarGenerosPorEmpresa(filiadosAtivos), [filiadosAtivos]);
  const idadeXCasa = useMemo(() => idadePorCasa(filiadosAtivos), [filiadosAtivos]);
  const situacaoXCasa = useMemo(() => contarSituacoesPorEmpresa(filiadosAtivos), [filiadosAtivos]);

  const dataKeyBarGenero = ['Feminino', 'Masculino', 'Não informado'];
  const dataKeyBarIdade = ['0-20', '20-40', '40-60', '60-80', '80-100', '+100', 'Não informado'];
  const dataKeyBarSituacao = ['ATIVO', 'APOSENTADO', 'PENSIONISTA', 'Não informado'];
  const location = useLocation();
  const currentPath = location.pathname;
  const buttonRoutes = getRoutes(routes, currentPath);

  return (
    <>
      <SindilegisBar />
      <Loading loading={loading} />
      {!loading && (
        <Container maxWidth="xl" style={{ overflow: 'hidden' }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.areaOperacoes}>
            <MenuButton buttonsRoutes={buttonRoutes} location={location} />
          </Grid>
          <Container container spacing={8} direction="column" alignItems="center">
            <Grid item container alignItems="center" justifyContent="center">
              <Grid item sm={3} style={{ display: 'flex', justifyContent: 'start' }}>
                <Typography variant="h5">Sindilegis em números</Typography>
              </Grid>
              <Grid item sm={9} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box className={classes.gradientLine} style={{ width: '100%', height: '4px' }} />
              </Grid>
              <Paper
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: 20,
                  width: '100%',
                }}>
                <TableContainer component={Paper} style={{ width: '100%' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">Ativos</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">Aposentados</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">Pensionistas</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="body1">Total</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(dataCards).map((row) => {
                        let cellColor;

                        if (row.name === 'Senado Federal') {
                          cellColor = '#34a8cf';
                        } else if (row.name === 'Câmara dos Deputados') {
                          cellColor = '#3ab654';
                        } else if (row.name === 'Tribunal de Contas da União') {
                          cellColor = '#edd535';
                        } else {
                          cellColor = '#f58198';
                        }
                        return (
                          <TableRow key={row.name}>
                            <TableCell align="right">
                              <Typography variant="h6">{row.name}</Typography>
                            </TableCell>
                            <TableCell align="center" style={{ color: cellColor }}>
                              <Typography variant="h6">
                                {row.ATIVO.toLocaleString('pt-BR')}{' '}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ color: cellColor }}>
                              <Typography variant="h6">
                                {row.APOSENTADO.toLocaleString('pt-BR')}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ color: cellColor }}>
                              <Typography variant="h6">
                                {row.PENSIONISTA.toLocaleString('pt-BR')}
                              </Typography>
                            </TableCell>
                            <TableCell align="center" style={{ color: cellColor }}>
                              <Typography variant="h6">
                                {row.total.toLocaleString('pt-BR')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid container style={{ marginTop: 20 }}>
                  <Grid item xs={12} sm={6} md={4}>
                    <BarCharts
                      titulo="Gênero x Casa"
                      data={generoXCasa}
                      dataKeyBar={dataKeyBarGenero}
                      dataKeyX="empresa"
                      colors={paletaAuxiliarMedio}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <StackedBarChart
                      titulo="Idade x Casa"
                      data={idadeXCasa}
                      dataKeyBar={dataKeyBarIdade}
                      dataKeyX="empresa"
                      colors={paletaAuxiliarMedio}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <BarCharts
                      titulo="Situação x Casa"
                      data={situacaoXCasa}
                      dataKeyBar={dataKeyBarSituacao}
                      dataKeyX="empresa"
                      colors={paletaAuxiliarMedio}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  filiados: PropTypes.array.isRequired,
  getFiliados: PropTypes.func.isRequired,
};

export default Dashboard;
