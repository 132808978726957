import _ from 'lodash';
import { storage } from '../firebase';

export const ACCEPT_INPUT_FILE = `video/*,.doc,.docx,application/pdf`;
export const MAX_LIMIT_FILE_5MB = 5e6;

export const DADOS_GERAIS = 'DADOS_GERAIS';
export const ITENS = 'ITENS';
export const PESSOAS_INSTITUICOES = 'PESSOAS_INSTITUICOES';
export const PROCESSOS = 'PROCESSOS';
export const ACOES = 'ACOES';
export const CUSTOS = 'CUSTOS';

export const LABELS_TEMA = {
  [DADOS_GERAIS]: 'Dados Gerais',
  [ITENS]: 'Itens do Tema',
  [PESSOAS_INSTITUICOES]: 'Pessoas e Instituições',
  [PROCESSOS]: 'Processos',
  [ACOES]: 'Ações do Sindilegis',
  [CUSTOS]: 'Custos',
};

export const SITUACAO_TEMA_EM_ACOMPANHAMENTO = 'EM_ACOMPANHAMENTO';
export const SITUACAO_TEMA_ENCERRADO = 'ENCERRADO';

export const LABELS_SITUACAO_TEMA = {
  [SITUACAO_TEMA_EM_ACOMPANHAMENTO]: 'Em acompanhamento',
  [SITUACAO_TEMA_ENCERRADO]: 'Encerrado',
};

export const isSituacaoTemaEncerrado = (situacao) => situacao === SITUACAO_TEMA_ENCERRADO;

export const tipos = {
  PROJETO_LEI_ORDINARIA: 'Projeto de Lei Ordinária',
  PROJETO_LEI_COMPLEMENTAR: 'Projeto de Lei Complementar',
  MEDIDA_PROVISORIA: 'Medida Provisória',
  PROPOSTA_EMENDA_CONSTITUICAO: 'Proposta de Emenda à Constituição',
  ADMINISTRATIVO: 'Administrativo',
};

export const tiposLink = {
  PROPOSICAO: 'Proposição',
  ARTIGO: 'Artigo',
  ESTUDO: 'Estudo',
  PROJETO: 'Projeto',
  PRODUCAO_CONHECIMENTO: 'Produção de conhecimento',
  OUTRO: 'Outros',
};

export const interessados = [
  { label: 'SF', value: 'Senado Federal' },
  { label: 'CD', value: 'Câmara dos Deputados' },
  { label: 'TCU', value: 'Tribunal de Contas da União' },
  { label: 'SERVIDOR PÚBLICO', value: 'Servidor Público' },
  { label: 'SOCIEDADE', value: 'Sociedade' },
];

export const posicionamentos = {
  FAVORAVEL: 'Favorável',
  PARCIALMENTE_FAVORAVEL: 'Parcialmente favorável',
  CONTRARIO: 'Contrário',
  PARCIALMENTE_CONTRARIO: 'Parcialmente contrário',
};

export const tags = [
  { id: 'saude', nome: 'Saúde' },
  { id: 'remuneracao', nome: 'Remuneração' },
  { id: 'aposentados_pensionistas', nome: 'Aposentados e Pensionistas' },
  { id: 'relacoes_trabalho', nome: 'Relações de Trabalho' },
  { id: 'sindical', nome: 'Sindical' },
];

export const prioridades = [
  { value: 'Máxima', label: 'Máxima' },
  { value: 'Média', label: 'Média' },
  { value: 'Mínima', label: 'Mínima' },
];

export const classificacaoTema = [
  { value: 'Interno', label: 'Interno' },
  { value: 'Externo', label: 'Externo' },
];

export const classificacaoAcoes = [
  { value: 'Interna', label: 'Interna' },
  { value: 'Externa', label: 'Externa' },
];

export const steps = {
  TEMA: 1,
  INFORMACOES_GERAIS: 2,
  ITEM_DESTAQUE: 3,
  PESSOAS_INSTITUICOES: 4,
  PROCESSOS: 5,
  ACOES: 6,
};

export const LAST_STEP = _.last(_.values(steps));

export const initialValuesTema = {
  existe_itens_destaque: true,
  existe_pessoas_instituicoes: true,
  existe_processos: true,
  existe_acoes_sindilegis: true,
  situacao: SITUACAO_TEMA_EM_ACOMPANHAMENTO,
};

export const getDocumentosBy = (object) =>
  _.isNil(object) ? {} : object.documentos || object.anexos;

export const getObjectByKey = (rootObject, key) => (_.isNil(rootObject) ? {} : rootObject[key]);

export const hasDocumentoByName = (docs, searchName) =>
  _.some(docs, (doc) => doc.nome === searchName);

export const isAcceptableFileSize = (fileSize) => fileSize <= MAX_LIMIT_FILE_5MB;

export const fileUploadCheck = (docs, currentFile) => {
  let errors = [];
  if (hasDocumentoByName(docs, currentFile.name)) {
    errors.push({
      msg: `O Arquivo ${currentFile.name} já foi carregado para este contexto.`,
      variant: 'warning',
    });
  }
  if (!isAcceptableFileSize(currentFile.size)) {
    errors.push({
      msg: `O Arquivo ${currentFile.name} ultrapassa o limite permitido de 5 Megabytes.`,
      variant: 'warning',
    });
  }
  return errors;
};

export const deleteDocumentosTemaByNestedField = (tema, field) => {
  _.forEach(_.values(tema[field]), (item) => {
    _.forEach(_.values(item['documentos']), ({ url }) => {
      storage.removerAnexo(url);
    });
  });
};

export const getItensAsArray = (itens) => {
  if (_.isNil(itens)) {
    return [];
  }
  const itensAsArray = _.map(_.keys(itens), (id) => {
    itens[id].id = id;
    return itens[id];
  });
  return itensAsArray;
};

export const sanearFieldArrayTema = (tema, field) => {
  const itens = tema[field];
  if (_.isNil(itens)) {
    return tema;
  }
  return {
    ...tema,
    [field]: _.zipObject(
      _.map(itens, (i) => i.id),
      itens
    ),
  };
};

export const sanearFieldsArrayTema = (tema) => {
  const fields = ['itens_destaque', 'pessoas_instituicoes', 'processos', 'acoes_sindilegis'];
  let temaSaneado = { ...tema };
  _.forEach(fields, (field) => {
    const itens = temaSaneado[field];
    if (!_.isNil(itens)) {
      temaSaneado = {
        ...temaSaneado,
        [field]: _.zipObject(
          _.map(itens, (i) => i.id),
          itens
        ),
      };
    }
  });

  return temaSaneado;
};

export const hasCurrentItemChanged = (tema, currentItem, fieldToSeek) => {
  if (_.isNil(tema) || _.isNil(tema[fieldToSeek])) {
    return false;
  }
  const itens = tema[fieldToSeek];
  const itemFound = _.find(itens, ({ id }) => id === currentItem.id);
  return !_.isEqual(currentItem, itemFound);
};

export const isValidHttpUrl = (strUrl) => {
  try {
    const url = new URL(strUrl);
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
};

export const smoothScrolling = {
  timer: null,

  stop: function () {
    clearTimeout(this.timer);
  },

  scrollTo: function (id, callback) {
    let settings = {
      duration: 1000,
      easing: {
        outQuint: function (x, t, b, c, d) {
          return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
        },
      },
    };
    try {
      let percentage;
      let startTime;
      let node = document.getElementById(id);
      if (!node) throw new Error(`ID ${id} não existe na pagina.`);
      let nodeTop = node.offsetTop;
      let nodeHeight = node.offsetHeight;
      let body = document.body;
      let html = document.documentElement;
      let height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      let windowHeight = window.innerHeight;
      let offset = window.pageYOffset;
      let delta = nodeTop - offset;
      let bottomScrollableY = height - windowHeight;
      let targetY =
        bottomScrollableY < delta
          ? bottomScrollableY - (height - nodeTop - nodeHeight + offset)
          : delta;

      startTime = Date.now();
      percentage = 0;

      if (this.timer) {
        clearInterval(this.timer);
      }

      function step() {
        let yScroll;
        let elapsed = Date.now() - startTime;

        if (elapsed > settings.duration) {
          clearTimeout(this.timer);
        }

        percentage = elapsed / settings.duration;

        if (percentage > 1) {
          clearTimeout(this.timer);

          if (callback) {
            callback();
          }
        } else {
          yScroll = settings.easing.outQuint(0, elapsed, offset, targetY, settings.duration);
          window.scrollTo(0, yScroll);
          this.timer = setTimeout(step, 10);
        }
      }

      this.timer = setTimeout(step, 10);
    } catch (e) {
      console.error('Impossível mover o scroll para o ID solicitado', e);
    }
  },
};
