export const paletaPrincipal = ['#8F7389', '#F58A75', '#78CDD1', '#82B450', '#EBD539', '#939598'];

export const paletaAuxiliarSuave = ['#c4eaf5', '#fcd8da', '#fbf6b4', '#e0d3e7', '#d7e9d1'];

export const paletaAuxiliarMedio = ['#79ccd0', '#f68975', '#e9d439', '#91748a', '#82b451'];

export const paletaAuxiliarIntenso = [
  '#34a8cf',
  '#f58198',
  '#edd535',
  '#8777b6',
  '#3ab654',
  '#79ccd0',
  '#f68975',
];
