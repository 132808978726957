import {
  SUPRIMENTOS_SET,
  SUPRIMENTO_ID_SET,
  SUPRIMENTO_UPDATED,
  SUPRIMENTO_SET,
  DELETE_SUPRIMENTO,
} from '../actions/types';

const INITIAL_STATE = {
  suprimentos: [],
  suprimentoId: null,
  suprimento: null,
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUPRIMENTOS_SET:
      return { ...state, suprimentos: action.suprimentos };
    case SUPRIMENTO_ID_SET:
      return { ...state, suprimentoId: action.suprimentoId };
    case SUPRIMENTO_SET:
      return { ...state, suprimento: action.suprimento };
    case DELETE_SUPRIMENTO: {
      const { suprimentos } = state;
      const index = suprimentos.findIndex((r) => r.id === action.payload.id);
      suprimentos.splice(index, 1);
      return { ...state, suprimentos: [...suprimentos] };
    }
    case SUPRIMENTO_UPDATED: {
      const { suprimentos } = state;
      const { suprimento } = action;
      const index = suprimentos.findIndex((f) => f.id === suprimento.id);
      const expense = { ...suprimentos[index], ...suprimento };
      suprimentos.splice(index, 1, expense);
      return { ...state, suprimentos: [...suprimentos] };
    }
    default:
      return state;
  }
}
