import React, { useState } from 'react';
import { Grid, Menu, MenuItem, IconButton } from '@material-ui/core';
import ButtonComponent from './ButtonComponent';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

const MenuButton = ({ buttonsRoutes, maxButtons = 3, location, additionalButtons = [] }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const allButtons = [...buttonsRoutes, ...additionalButtons.filter(({ condition }) => condition)];
  const limitedButtons = allButtons.slice(0, maxButtons);
  const extraButtons = allButtons.slice(maxButtons);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      container
      item
      md={`auto`}
      justifyContent="flex-end"
      spacing={1}
      style={{ paddingTop: 10 }}>
      {limitedButtons.map(
        ({ label, route, onClick }) =>
          location.pathname !== route && (
            <Grid item key={label}>
              <ButtonComponent variant="primary" size="small" to={route} onClick={onClick}>
                {label}
              </ButtonComponent>
            </Grid>
          )
      )}

      {extraButtons.length > 0 && (
        <Grid item>
          <IconButton
            variant="default"
            size="small"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}>
            <MoreHoriz fontSize="small" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            {extraButtons.map(({ label, route, onClick }) => (
              <MenuItem
                key={label}
                onClick={() => {
                  handleClose();
                  if (onClick) onClick();
                }}>
                <ButtonComponent variant="menu" size="small" to={route} onClick={onClick}>
                  {label}
                </ButtonComponent>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};

export default MenuButton;
