import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Select from '../commons/Select';
import { withSnackbar } from 'notistack';
import styles from './styles';
import { getRoles, saveRole, removeRole, setRoles } from '../../actions/role';
import { rolesDescription } from '../../constants/roles';

class EditarPerfis extends React.Component {
  componentWillReceiveProps({ userId }) {
    if (userId) {
      this.props.getRoles(userId);
    }
  }

  componentWillUnmount() {
    this.props.setRoles([]);
  }

  save(values) {
    const { userId, saveRole } = this.props;
    saveRole(userId, values);
  }

  delete(role) {
    const { userId, removeRole } = this.props;
    removeRole(userId, role);
  }

  render() {
    const { classes, reset, roles } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Perfis</DialogTitle>
        <DialogContent>
          {this.renderForm()}
          <Typography variant="subtitle1" className={classes.title}>
            Perfis associados
          </Typography>
          <List dense={true}>{roles && roles.map((item) => this.renderItem(item))}</List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => reset() && this.props.handleClose(null)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderItem(item) {
    const text = rolesDescription[item];
    return (
      <ListItem key={item} role={undefined} alignItems="flex-start" dense>
        <ListItemText primary={text} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => this.delete(item)} aria-label="Excluir">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  renderForm() {
    const { classes, handleSubmit, pristine, submitting, roles } = this.props;
    return (
      <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Field name="role" component={Select} label="Selecionar perfil">
              <MenuItem value="">
                <em>Selecione</em>
              </MenuItem>
              {Object.keys(rolesDescription).map((key) => {
                return (
                  !_.includes(roles, key) && (
                    <MenuItem key={key} value={key}>
                      {rolesDescription[key]}
                    </MenuItem>
                  )
                );
              })}
            </Field>
          </Grid>
          <Grid item xs>
            <Button
              type="submit"
              disabled={pristine || submitting}
              variant="contained"
              color="primary"
              size="small"
              className={classes.addPerfil}>
              Adicionar
              <Icon className={classes.rightIcon}>add</Icon>
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.role.roles,
  userId: state.userState.userId,
});

EditarPerfis.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getRoles, saveRole, removeRole, setRoles })
)(
  reduxForm({
    form: 'roles',
    enableReinitialize: true,
  })(withSnackbar(EditarPerfis))
);
