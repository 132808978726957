import React from 'react';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default (props) => {
  const {
    input,
    label,
    trueValue,
    meta: { touched, error },
    ...custom
  } = props;
  return (
    <FormControlLabel
      control={
        <Switch
          error={touched && error ? error : undefined}
          {...input}
          {...custom}
          checked={input.value ? true : false}
          onChange={input.onChange}
          margin="dense"
        />
      }
      label={label}
    />
  );
};
