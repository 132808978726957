import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { getFiliados, setFiliado, setFiliadoId } from '../../../actions/filiado';
import { getUser } from '../../../actions/user';
import tableI18n from '../../TableLocalization';
import AtendimentoForm from './atendimento';
import RealizacaoAtendimento from './realizacaoAtendimento';
import ConclusaoAtendimento from './conclusaoAtendimento';
import ConfirmDialog from '../../commons/ConfirmDialog';
import SindilegisBar from '../../commons/SindilegisBar';
import * as roles from '../../../constants/roles';
import styles from '../styles';
import Loading from '../../commons/Loading';
import { downloadXlsx, isDataAnterior } from '../../commons/util';
import { formataData, formataHora, dataHoraSort } from '../../Financial/util';
import {
  cancelarAtendimento,
  cancelarConclusaoAtendimento,
  getAtendimentos,
  getEscritoriosAdvocacia,
  setAtendimento,
  setAtendimentoId,
  setEscritorioAdvocacia,
  setEscritorioAdvocaciaId,
  SITUACAO_ATENDIMENTO_CONCLUIDO,
  SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO,
  SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO,
} from '../../../actions/juridico';
import { getColumns } from './config';
import ComentarioAtendimento from './comentarioAtendimento';
import { JURIDICO } from '../../../constants/routes';
import ButtonComponent from '../../commons/ButtonComponent';

class Atendimentos extends React.Component {
  state = {
    open: false,
    registrarRealizacaoOpen: false,
    conclusaoOpen: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    comentarioOpen: false,
  };

  componentDidMount() {
    this.props.getFiliados();
    this.props.getEscritoriosAdvocacia();
    this.props.getAtendimentos();
    if (this.props.authUser) {
      this.props.getUser(this.props.authUser.uid);
    }
  }

  handleAtendimentoClickOpen = () => {
    this.setState({ open: true });
  };

  handleConclusaoAtendimentoClickOpen = () => {
    this.setState({ ...this.state, conclusaoOpen: true });
  };

  handleAtendimentoClose = (reload) => {
    this.setState({ open: false });
    this.props.setFiliadoId(null);
    if (reload) {
      this.props.getAtendimentos();
    }
  };

  handleRegistrarRealizacaoClose = (reload) => {
    this.setState({ registrarRealizacaoOpen: false });
    this.props.setFiliadoId(null);
    if (reload) {
      this.props.getAtendimentos();
    }
  };

  handleConclusaoClose = (reload) => {
    this.setState({ conclusaoOpen: false });
    this.props.setFiliadoId(null);
    if (reload) {
      this.props.getAtendimentos();
    }
  };

  handleComentarioClose = (reload) => {
    this.setState({ comentarioOpen: false });
    if (reload) {
      setTimeout(() => this.props.getAtendimentos(), 400);
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
    }
    this.setState({ confirmOpen: false });
  };

  cancelarAtendimento(atendimento) {
    const { enqueueSnackbar } = this.props;
    this.props.cancelarAtendimento(atendimento);
    enqueueSnackbar('Atendimento cancelado com sucesso!', {
      variant: 'success',
    });
  }

  cancelarConclusaoAtendimento(atendimento) {
    const { enqueueSnackbar } = this.props;
    this.props.cancelarConclusaoAtendimento(atendimento);
    enqueueSnackbar('Conclusão de atendimento cancelada com sucesso!', {
      variant: 'success',
    });
  }

  incluirComentarioAtendimento(atendimento) {
    const { setAtendimento } = this.props;
    setAtendimento(atendimento);
    this.setState({ ...this.state, comentarioOpen: true });
  }

  useQuery() {
    const { location } = this.props;
    return new URLSearchParams(location.search);
  }

  render() {
    const { classes, atendimentos, loading, authUser, user } = this.props;
    const isJuridico = _.includes(authUser.roles, roles.JURIDICO);
    const isVisualizadorJuridico = _.includes(authUser.roles, roles.VISUALIZADOR_JURIDICO);
    const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);

    const query = this.useQuery();
    let situacao = query.get('situacao');
    const vencidos = query.get('vencidos');
    const hoje = query.get('hoje');
    const fromDate = query.get('fromDate');
    const toDate = query.get('toDate');

    let atendimentosFiltrados = Object.keys(atendimentos)
      .map((key) => _.values(atendimentos[key]))
      .reduce((a, b) => a.concat(b), []) //flat
      .filter(
        (a) =>
          situacao == null || this.filterByQueryParam(a, situacao, vencidos, hoje, fromDate, toDate)
      );

    if (!isJuridico && !isVisualizadorJuridico) {
      atendimentosFiltrados = atendimentosFiltrados.filter((a) =>
        user.escritorios?.includes(a.escritorio.id)
      );
    }

    const ACAO_EDITAR = {
      icon: 'edit',
      tooltip: 'Editar',
      onClick: (event, row) =>
        this.props.setAtendimento(atendimentosFiltrados.find((a) => a.id === row.id)) &&
        this.handleAtendimentoClickOpen(),
    };

    const ACAO_CANCELAR = {
      icon: 'close',
      tooltip: 'Cancelar',
      onClick: (event, row) =>
        this.handleConfirmOpen(
          () => {
            this.cancelarAtendimento(atendimentosFiltrados.find((a) => a.id === row.id));
          },
          `Confirma o cancelamento do atendimento no escritório ${row.escritorio.nome} ao(s) filiado(s) ${row.filiados?.map((it) => it.nome_completo).join(', ')} do tipo ${row.tipoAtendimento} marcado para ${row.dataHora}?`
        ),
    };

    const ACAO_REGISTRAR_REALIZACAO = {
      icon: 'grading',
      tooltip: 'Registrar realização',
      onClick: (event, row) => {
        this.props.setAtendimento(atendimentosFiltrados.find((a) => a.id === row.id));
        this.setState({ ...this.state, registrarRealizacaoOpen: true });
      },
    };

    const ACAO_CONCLUIR = {
      icon: 'done',
      tooltip: 'Concluir',
      onClick: (event, row) => {
        if (row.seraConstituidoNovoProcesso === 'Não') {
          this.handleConfirmOpen(() => {
            this.props.setAtendimento(atendimentosFiltrados.find((a) => a.id === row.id));
            this.setState({ ...this.state, conclusaoOpen: true });
          }, `Não existem processos ou procedimentos, constituídos em data posterior à de realização deste atendimento, relacionados a ele. Esse fato pode indicar que o encaminhamento dado à demanda está errado OU que não foram adotadas as providências devidas OU que os processos ou procedimentos, embora constituídos, ainda não tenham sido relacionados ao atendimento. Sugerimos verificar qual é a situação deste atendimento antes de prosseguir. Deseja continuar mesmo assim?`);
        } else {
          this.props.setAtendimento(atendimentosFiltrados.find((a) => a.id === row.id));
          this.setState({ ...this.state, conclusaoOpen: true });
        }
      },
    };

    const ACAO_CANCELAR_CONCLUSAO = {
      icon: 'close',
      tooltip: 'Cancelar conclusão',
      onClick: (event, row) =>
        this.handleConfirmOpen(
          () => {
            this.cancelarConclusaoAtendimento(atendimentosFiltrados.find((a) => a.id === row.id));
          },
          `Confirma o cancelamento da conclusão do atendimento no escritório ${row.escritorio.nome} ao(s) filiado(s) ${row.filiados?.map((it) => it.nome_completo).join(', ')} do tipo ${row.tipoAtendimento} marcado para ${row.dataHora}?`
        ),
    };

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Paper className={classes.paper} elevation={1} hidden={loading}>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <Grid item style={{ display: 'flex', justifyContent: 'flex-end', padding: 10 }}>
                <ButtonComponent variant="insert" onClick={() => this.props.history.push(JURIDICO)}>
                  Voltar
                </ButtonComponent>
              </Grid>
              <MaterialTable
                columns={getColumns(situacao, this.props.filiados)}
                data={atendimentosFiltrados
                  .map((atendimento) => this.formatarAtendimento(atendimento))
                  .sort(dataHoraSort)}
                title={situacao}
                actions={
                  isVisualizadorJuridico
                    ? null
                    : [
                        (rowData) => isJuridico && ACAO_EDITAR,
                        (rowData) => {
                          if (
                            isJuridico &&
                            rowData.situacao === SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO &&
                            new Date().getTime() < new Date(rowData.data).getTime()
                          ) {
                            return ACAO_CANCELAR;
                          }
                          return null;
                        },
                        (rowData) => {
                          if (rowData.situacao === SITUACAO_ATENDIMENTO_PENDENTE_REALIZACAO) {
                            return ACAO_REGISTRAR_REALIZACAO;
                          }
                          return null;
                        },
                        (rowData) => {
                          if (
                            isJuridico &&
                            rowData.situacao === SITUACAO_ATENDIMENTO_PENDENTE_CONCLUSAO
                          ) {
                            return ACAO_CONCLUIR;
                          }
                          return null;
                        },
                        (rowData) => {
                          if (isJuridico && rowData.situacao === SITUACAO_ATENDIMENTO_CONCLUIDO) {
                            return ACAO_CANCELAR_CONCLUSAO;
                          }
                          return null;
                        },
                        (rowData) => {
                          if (isJuridico) {
                            return {
                              icon: 'comment',
                              tooltip: 'Adicionar comentário',
                              onClick: () => this.incluirComentarioAtendimento(rowData),
                            };
                          }
                          return null;
                        },
                      ]
                }
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: canExport,
                  exportAllData: true,
                  exportDelimiter: ';',
                  exportFileName: 'relatorio de atendimentos',
                  exportCsv: (columns, data) => {
                    const dadosFormatados = data.map((row) => ({
                      ...row,
                      data: formataData(row.data),
                    }));
                    downloadXlsx(
                      _.filter(columns, (item) => !item.noExport),
                      dadosFormatados,
                      'atentimentos.xlsx'
                    );
                  },
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
        </Paper>
        <AtendimentoForm
          open={this.state.open}
          handleClose={this.handleAtendimentoClose}
          acao="Editar"
          authUser={authUser}
        />
        <RealizacaoAtendimento
          open={this.state.registrarRealizacaoOpen}
          authUser={authUser}
          handleClose={this.handleRegistrarRealizacaoClose}
        />
        <ConclusaoAtendimento
          open={this.state.conclusaoOpen}
          handleClose={this.handleConclusaoClose}
          authUser={authUser}
        />
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
        <ComentarioAtendimento
          open={this.state.comentarioOpen}
          handleClose={(reload) => this.handleComentarioClose(reload)}
        />
      </div>
    );
  }

  formatarAtendimento(atendimento) {
    return {
      ...atendimento,
      dataHora:
        formataData(atendimento.data) +
        ' - ' +
        formataHora(atendimento.horaInicio) +
        ' às ' +
        formataHora(atendimento.horaFim),
      seraConstituidoNovoProcesso: atendimento.conclusao
        ? atendimento.conclusao.seraConstituidoNovoProcesso
        : '',
      ocorreuAtendimento: atendimento.conclusao ? atendimento.conclusao.ocorreuAtendimento : '',
      nomeFiliados: atendimento.filiados
        ? atendimento.filiados.map((f) => f.nome_completo).join(', ')
        : '',
      todosComentarios: atendimento.comentarios
        ? atendimento.comentarios.map((row) => row.comentario).join(', ')
        : '',
      nomeEscritorio: atendimento.escritorio.nome,
      comentariosConclusao:
        atendimento.conclusao && atendimento.conclusao.comentarios
          ? atendimento.conclusao.comentarios.map((row) => row.comentario).join(', ')
          : '',
    };
  }

  filterByQueryParam(atendimento, situacao, vencidos, hoje, fromDate, toDate) {
    if (hoje) {
      const dataAtendimento = new Date(atendimento.data);
      return (
        atendimento.situacao === situacao &&
        dataAtendimento >= fromDate &&
        dataAtendimento <= toDate
      );
    }
    if (vencidos) {
      const dataAtendimento = new Date(atendimento.data);
      const dataToDate = new Date();
      const isVencido = isDataAnterior(dataAtendimento, dataToDate);
      const isWithinDataRange = dataAtendimento >= fromDate && dataAtendimento <= toDate;
      return atendimento.situacao === situacao && isVencido && isWithinDataRange;
    }
    if (fromDate && toDate) {
      const dataAtendimento = new Date(atendimento.data);
      return (
        atendimento.situacao === situacao &&
        dataAtendimento >= fromDate &&
        dataAtendimento <= toDate
      );
    }
  }
}

const mapStateToProps = ({
  juridico: { atendimentos, escritoriosAdvocacia },
  filiado: { filiados, loading },
  userState: { user },
}) => ({
  atendimentos,
  escritoriosAdvocacia,
  filiados,
  loading,
  user,
});

Atendimentos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAtendimentos,
    getEscritoriosAdvocacia,
    setAtendimentoId,
    setAtendimento,
    setEscritorioAdvocaciaId,
    setEscritorioAdvocacia,
    cancelarAtendimento,
    cancelarConclusaoAtendimento,
    getFiliados,
    setFiliado,
    setFiliadoId,
    getUser,
  })
)(withRouter(withSnackbar(Atendimentos)));
