import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import * as roles from '../../constants/roles';
import * as routes from '../../constants/routes';
import { listDependentes } from '../../actions/dependent';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import { downloadXlsx } from '../commons/util';
import { formataData } from '../Financial/util';
import MenuButtom from '../commons/MenuButton';
import { getRoutes } from '../commons/util';

class RelatorioDependentes extends React.Component {
  componentDidMount() {
    this.props.listDependentes();
  }

  render() {
    const { classes, dependentesComTitular, authUser, loading, location } = this.props;
    const canExport = _.includes(authUser.roles, roles.GESTOR_USUARIO);

    dependentesComTitular.forEach((d) => {
      d.data_cadastro = formataData(d.data_cadastro);
    });

    const currentPath = location.pathname;
    const buttonRoutes = getRoutes(routes, currentPath);

    return (
      <React.Fragment>
        <SindilegisBar />
        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            <Grid item style={{ padding: '10px 0 10px 0' }}>
              <MenuButtom buttonsRoutes={buttonRoutes} location={location} />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.demo}>
                <MaterialTable
                  columns={[
                    { title: 'Nome Completo', field: 'nome_completo' },
                    { title: 'CPF', field: 'cpf' },
                    { title: 'Data de nascimento', field: 'data_nascimento' },
                    { title: 'Grau de parentesco', field: 'parentesco' },
                    { title: 'Nome da mãe', field: 'nome_mae' },
                    { title: 'Nome do titular', field: 'nome_titular' },
                    { title: 'CPF do titular', field: 'cpf_titular' },
                    { title: 'Data de cadastro', field: 'data_cadastro' },
                  ]}
                  data={dependentesComTitular}
                  title="Relatório de dependentes"
                  localization={tableI18n}
                  options={{
                    filtering: true,
                    exportButton: canExport,
                    exportAllData: true,
                    exportDelimiter: ';',
                    exportFileName: 'relatorio de dependentes',
                    exportCsv: (columns, data) => {
                      downloadXlsx(columns, data, 'dependentes.xlsx');
                    },
                    padding: 'dense',
                  }}
                  icons={{ Filter: () => <span></span> }}
                />
              </div>
            </Grid>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ dependent: { dependentesComTitular, loading } }) => ({
  dependentesComTitular,
  loading,
});

RelatorioDependentes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listDependentes })
)(RelatorioDependentes);
