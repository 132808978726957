import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import { setFiliadoId } from '../../../actions/filiado';
import {
  concluirAtendimento,
  getProcessos,
  setAtendimento,
  setAtendimentoId,
  setEscritorioAdvocacia,
} from '../../../actions/juridico';
import ProcedimentoForm from '../Processo/procedimento';
import Typography from '@material-ui/core/Typography';
import { formataData, formataHora } from '../../Financial/util';
import UploadArquivosAtendimento from './uploadArquivosAtendimento';
import AsyncAutoCompleteSelect from '../../commons/AsyncAutoComplete';

class ConclusaoAtendimento extends React.Component {
  state = {
    anexos: [],
    procedimentoOpen: false,
  };

  constructor(props) {
    super(props);
    this.concluirAtendimento = this.concluirAtendimento.bind(this);
  }

  componentDidMount() {
    this.props.getProcessos();
    if (this.props.atendimento && this.props.atendimento.anexos) {
      this.setState({ anexos: this.props.atendimento.anexos });
    }
  }

  handleProcedimentoClose = (reload) => {
    this.setState({ procedimentoOpen: false });
    if (reload) {
      this.props.getProcessos();
    }
  };

  concluirAtendimento(conclusaoAtendimento) {
    const { atendimento } = this.props;
    atendimento.anexos = this.state.anexos;
    this.props.concluirAtendimento(atendimento, conclusaoAtendimento, () =>
      this.successHandler(true)
    );
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Atendimento concluído com sucesso!', {
      variant: 'success',
    });
    this.props.setEscritorioAdvocacia(null);
    this.props.setAtendimentoId(null);
    this.props.setAtendimento(null);
    this.props.reset();
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao concluir atendimento.', {
      variant: 'error',
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      atendimento,
      open,
      handleClose,
      invalid,
      authUser,
    } = this.props;

    return (
      atendimento && (
        <React.Fragment>
          <Dialog
            open={open}
            onClose={handleClose.bind(this, null)}
            fullWidth
            maxWidth="md"
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              Assessoria jurídica - Concluir atendimento
            </DialogTitle>
            <form onSubmit={handleSubmit(this.concluirAtendimento)}>
              <DialogContent>
                <Grid
                  container
                  spacing={2}
                  hidden={false}
                  alignItems="center"
                  justifyContent="center">
                  <Grid item sm={12}>
                    <Typography>Número: {atendimento.numero}</Typography>
                    <Typography>{`Filiados: ${
                      atendimento.filiados &&
                      atendimento.filiados.map((f) => f.nome_completo).join(', ')
                    }`}</Typography>
                    <Typography>Tipo: {atendimento.tipoAtendimento}</Typography>
                    {atendimento.escritorio && (
                      <Typography>Escritório: {atendimento.escritorio.nome}</Typography>
                    )}
                    <Typography>
                      Dia e horário: {formataData(atendimento.data)} -{' '}
                      {formataHora(atendimento.horaInicio)} às {formataHora(atendimento.horaFim)}
                    </Typography>
                    {atendimento.descricao && (
                      <Typography>Descrição: {atendimento.descricao}</Typography>
                    )}
                  </Grid>
                </Grid>
                {atendimento.conclusao &&
                  atendimento.conclusao.seraConstituidoNovoProcesso === 'Sim' && (
                    <Grid
                      container
                      spacing={2}
                      hidden={false}
                      alignItems="flex-start"
                      justifyContent="flex-start"
                      style={{ marginTop: '2em' }}>
                      <Grid item sm={8}>
                        <Field
                          defaultOptions={[]}
                          isMulti={true}
                          loadOptions={(input) => {
                            return Promise.resolve(
                              this.props.processos
                                .filter(
                                  (p) =>
                                    p.numeroProcesso &&
                                    p.numeroProcesso.toLowerCase().includes(input.toLowerCase())
                                )
                                .map((p) => ({
                                  label:
                                    p.numeroProcesso + ' - ' + p.tipo + ' - ' + p.tribunalOrgao,
                                  value: p,
                                }))
                                .slice(0, 100)
                            );
                          }}
                          name="processos"
                          component={AsyncAutoCompleteSelect}
                          label="Processos e procedimentos"
                        />
                      </Grid>
                    </Grid>
                  )}
                <Grid
                  container
                  spacing={2}
                  hidden={false}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  style={{ marginTop: '2em' }}>
                  <Grid item sm={12}>
                    <UploadArquivosAtendimento
                      onChange={(anexos) => {
                        this.setState({ anexos });
                      }}
                      anexos={this.props.atendimento && this.props.atendimento.anexos}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() =>
                    this.props.setAtendimentoId(null) &&
                    this.props.setAtendimento(null) &&
                    reset() &&
                    this.props.handleClose()
                  }
                  color="default">
                  Cancelar
                </Button>
                <Button onClick={() => this.setState({ procedimentoOpen: true })} color="default">
                  Novo procedimento
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    (atendimento.conclusao &&
                      atendimento.conclusao.seraConstituidoNovoProcesso === 'Sim' &&
                      pristine) ||
                    invalid ||
                    submitting
                  }
                  color="primary">
                  Concluir
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          <ProcedimentoForm
            open={this.state.procedimentoOpen}
            handleClose={this.handleProcedimentoClose}
            authUser={authUser}
          />
        </React.Fragment>
      )
    );
  }
}

ConclusaoAtendimento.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  form: { conclusaoAtendimento },
  juridico: { atendimento, processos, atendimentoId, escritorioAdvocacia },
  filiado: { filiado, filiadoId },
}) => ({
  conclusaoAtendimento,
  filiado,
  filiadoId,
  atendimento,
  processos,
  atendimentoId,
  escritorioAdvocacia,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['processos'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    concluirAtendimento,
    getProcessos,
    setFiliadoId,
    setAtendimento,
    setAtendimentoId,
    setEscritorioAdvocacia,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'conclusaoAtendimento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(ConclusaoAtendimento))
);
