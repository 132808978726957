import { ROLES_SET, REMOVE_ROLE } from './types';
import { db } from '../firebase';
import { reset } from 'redux-form';

export const saveRole = (userId, values) => {
  return (dispatch) => {
    db.doSaveRole(userId, values.role).then(() => {
      dispatch(reset('roles'));
    });
  };
};

export const getRoles = (id) => {
  return (dispatch) => {
    db.onceGetRoles(id).then((snapshot) => {
      let roles = snapshot.val();
      roles = roles ? Object.keys(roles) : [];
      dispatch({ type: ROLES_SET, roles });
    });
  };
};

export const setRoles = (roles) => (dispatch) => dispatch({ type: ROLES_SET, roles });

export const removeRole = (userId, role) => {
  return (dispatch) => {
    db.doDeleteRole(userId, role).then(() => {
      dispatch({ type: REMOVE_ROLE, role });
    });
  };
};
