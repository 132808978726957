import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

export default (props) => {
  const {
    input,
    label,
    meta: { touched, error },
  } = props;
  return (
    <FormControl error={touched && !!error} fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        {...props}
        {...input}
        onChange={(event, value) => input.onChange(value)}
        margin="dense"
        row
      />
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );
};
