import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { getFiliados } from '../../actions/filiado';
import SindilegisBar from '../commons/SindilegisBar';
import styles from './styles';
import Loading from '../commons/Loading';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import InputMask from 'react-input-mask';
import Search from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import { logBySearchFiliado, formataCPF } from '../commons/util';

const Verificador = ({ filiados, loading, getFiliados }) => {
  const [cpfSearch, setCpfSearch] = useState('');
  const [foundFiliado, setFoundFiliado] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);

  useEffect(() => {
    getFiliados();
  }, [getFiliados]);

  const handleCpfChange = (event) => {
    const value = event.target.value;
    setCpfSearch(value);
    if (!value) {
      setFoundFiliado(null);
      setSearchPerformed(false);
    }
  };

  const handleSearch = () => {
    const value = cpfSearch.replace(/[^\d]/g, '');

    if (value) {
      const filiado = filiados.find(
        (item) => item.cpf && item.cpf.toString().replace(/[^\d]/g, '').includes(value)
      );
      setFoundFiliado(filiado);
      setSearchPerformed(true);
      logBySearchFiliado(`Consultou o CPF ${formataCPF(value)}`);
    } else {
      setFoundFiliado(null);
      setSearchPerformed(false);
    }
  };

  const filiadosAtivos = filiados.filter(
    (filiado) => filiado.situacao_filiado !== 'INATIVO' && !filiado.data_desfiliacao
  );

  const filiadosDF = filiadosAtivos.filter((filiado) => filiado?.estado === 'DF');

  return (
    <React.Fragment>
      <SindilegisBar />
      <Loading loading={loading} />
      {!loading && (
        <Container maxWidth="xl" style={{ paddingTop: 20, margin: 'auto' }}>
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: '100vh', backgroundColor: `#fefefe` }}>
            <Grid item xs={12}>
              <Grid style={{ textAlign: 'center', marginBottom: 20 }}>
                <Typography variant="h5">
                  Insira o CPF da pessoa para verificar se ela está filiada ao Sindilegis.
                </Typography>
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                {foundFiliado && (
                  <Paper elevation={3} style={{ padding: 20, textAlign: 'center', width: '50%' }}>
                    <Typography variant="h6">Filiado(a) encontrado(a)</Typography>
                    <Typography variant="body1">Nome: {foundFiliado.nome_completo}</Typography>
                    <Typography variant="body1">Casa: {foundFiliado.empresa}</Typography>
                    {foundFiliado.situacao_filiado === 'INATIVO' && (
                      <Typography variant="body2" style={{ color: 'orange' }}>
                        FILIADO CONSTA COMO INATIVO. PROCURE O ATENDIMENTO DO SINDILEGIS PARA
                        VERIFICAR A SITUAÇÃO.
                      </Typography>
                    )}
                  </Paper>
                )}
                {searchPerformed && !foundFiliado && cpfSearch && (
                  <Paper
                    elevation={3}
                    style={{ padding: 20, textAlign: 'center', width: '50%', color: 'red' }}>
                    <Typography variant="h6">CPF não consta como filiado</Typography>
                  </Paper>
                )}
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                <InputMask mask="999.999.999-99" value={cpfSearch} onChange={handleCpfChange}>
                  {() => (
                    <TextField
                      label="Pesquisar CPF"
                      variant="outlined"
                      style={{ width: '200px' }}
                    />
                  )}
                </InputMask>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  style={{ marginLeft: 10 }}>
                  <Search />
                </Button>
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
                <Typography variant='h6'>Total de Filiados: {filiadosAtivos.length.toLocaleString('pt-BR')}</Typography>
                <Typography variant='h6'>Total de Filiados no DF: {filiadosDF.length.toLocaleString('pt-BR')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = ({ filiado: { filiados, loading } }) => ({ filiados, loading });

Verificador.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), connect(mapStateToProps, { getFiliados }))(Verificador);
