import React from 'react';
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import {
  getObjetosProcesso,
  setObjetoProcessoId,
  setObjetoProcesso,
  saveObjetoProcesso,
  deleteObjetoProcesso,
} from '../../../actions/objetoProcesso';
import tableI18n from '../../TableLocalization';
import ObjetoProcessoForm from './objeto';
import ConfirmDialog from '../../commons/ConfirmDialog';
import SindilegisBar from '../../commons/SindilegisBar';
import styles from './styles';
import Loading from '../../commons/Loading';
import { removerMascaraCPF } from '../../commons/masks';
import ButtonComponent from '../../commons/ButtonComponent';
import * as routes from '../../../constants/routes';
import MenuButton from '../../commons/MenuButton';

class ObjetosProcesso extends React.Component {
  state = {
    open: false,
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
  };

  componentDidMount() {
    this.props.getObjetosProcesso();
  }

  handleObjetoProcessoClickOpen = () => {
    this.setState({ open: true });
  };

  handleObjetoProcessoClose = (reload) => {
    this.setState({ open: false });
    this.props.setObjetoProcesso(null);
    this.props.setObjetoProcessoId(null);
    if (reload) {
      this.reloadObjetosProcesso();
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  reloadObjetosProcesso() {
    this.props.getObjetosProcesso();
  }

  render() {
    const { classes, objetosProcesso, loading, location, authUser } = this.props;
    const isAuth = Boolean(authUser);
    const BUTTON_ROUTES = [
      { label: 'Jurídico', route: routes.JURIDICO },
      { label: 'Gerenciar processos', route: routes.JURIDICO_PROCESSOS },
      { label: 'Relatório de processos', route: routes.JURIDICO_PROCESSOS_RELATORIO },
    ];

    const CONDITIONAL_BUTTON = [
      { label: 'Escritórios', route: routes.ESCRITORIOS, condition: isAuth },
    ];

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <MenuButton
            buttonsRoutes={BUTTON_ROUTES}
            location={location}
            additionalButtons={CONDITIONAL_BUTTON}
          />
          <Grid style={{ marginLeft: 10 }} container justifyContent="flex-end" spacing={1}>
            <Grid item>
              <ButtonComponent
                variant="insert"
                onClick={() => this.handleObjetoProcessoClickOpen()}>
                Cadastrar objeto de processo
              </ButtonComponent>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" className={classes.title} />
            <div className={classes.demo}>
              <MaterialTable
                columns={[
                  { title: 'Código', field: 'codigo' },
                  { title: 'Descrição', field: 'descricao' },
                ]}
                data={objetosProcesso || []}
                title="Objetos de Processo"
                actions={[
                  {
                    icon: 'edit',
                    tooltip: 'Editar',
                    onClick: (event, row) =>
                      this.props.setObjetoProcessoId(removerMascaraCPF(row.id)) &&
                      this.handleObjetoProcessoClickOpen(),
                  },
                  {
                    icon: 'delete',
                    tooltip: 'Excluir',
                    onClick: (event, row) =>
                      this.handleConfirmOpen(
                        () => this.removeObjetoProcesso(row.id),
                        'Confirma a exclusão permanente do objeto de processo?'
                      ),
                  },
                ]}
                options={{
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100],
                  exportButton: true,
                  exportAllData: true,
                }}
                localization={tableI18n}
              />
            </div>
          </Grid>
          <ObjetoProcessoForm open={this.state.open} handleClose={this.handleObjetoProcessoClose} />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </div>
    );
  }

  removeObjetoProcesso(id) {
    this.props.deleteObjetoProcesso(id);
  }
}

const mapStateToProps = ({ objetoProcesso: { objetosProcesso, loading } }) => ({
  objetosProcesso,
  loading,
});

ObjetosProcesso.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getObjetosProcesso,
    setObjetoProcessoId,
    setObjetoProcesso,
    saveObjetoProcesso,
    deleteObjetoProcesso,
  })
)(withRouter(withSnackbar(ObjetosProcesso)));
