export const tipos = [
  'Assistência jurídica',
  'Assistência contábil',
  'Atendimento odontológico',
  'Participação em eventos',
  'Clube de vantagens',
  'Aula de informática',
];

export const classificacao = [
  { value: 'Interna', label: 'Interna' },
  { value: 'Externa', label: 'Externa' },
];
