import React, { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { getHistorico, setHistorico } from '../../actions/filiado';
import { formataData } from '../Financial/util';
import { showHistorico } from './config';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const dateFns = new DateFnsUtils({ locale: ptLocale });

const HistoricoFiliado = ({ classes, id }) => {
  const dispatch = useDispatch();
  const historico = useSelector((state) => state.filiado.historico);

  useEffect(() => {
    if (id) {
      dispatch(getHistorico(id));
    }

    return () => {
      dispatch(setHistorico(null));
    };
  }, [id, dispatch]);

  if (_.isEmpty(historico)) {
    return null;
  }

  return (
    <Accordion style={{ width: '100%', marginTop: '30px' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>Histórico</Typography>
      </AccordionSummary>
      <AccordionDetails>{renderItem(historico, classes)}</AccordionDetails>
    </Accordion>
  );
};

const renderItem = (historicoFiltrado, classes) => (
  <Grid style={{ width: '100%' }}>
    <Grid>
      <Toolbar>
        <Typography variant="h6" id="tableTitle">
          Histórico de alterações
        </Typography>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Data</TableCell>
            <TableCell>Alteração</TableCell>
            <TableCell>Responsável</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {historicoFiltrado.map((row) => (
            <TableRow key={`${row.data}-${row.nome_responsavel}`}>
              <TableCell>{dateFns.format(row.data, 'dd/MM/yyyy')}</TableCell>
              <TableCell>{renderAlteracoes(row.diff)}</TableCell>
              <TableCell>{row.nome_responsavel}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);

const getLabel = (key) => {
  const field = showHistorico.find((item) => item.value === key);
  return field ? field.label : key;
};

const renderAlteracoes = (diff) => {
  if (!diff || Object.keys(diff).length === 0) {
    return <Typography variant="body2">Nenhuma alteração registrada.</Typography>;
  }

  return Object.keys(diff).map((key) => {
    const value = diff[key];

    if (Array.isArray(value)) {
      if (key === 'anexos') {
        return value.map((anexo, index) => (
          <Typography variant="body2" key={index}>
            {`Nome do anexo ${index + 1}: ${anexo.nome}`}
          </Typography>
        ));
      }
    }

    return (
      <Typography variant="body2" key={key}>
        {`${getLabel(key)}: ${formatDiffValue(key, value)}`}
      </Typography>
    );
  });
};

const formatDiffValue = (key, value) => {
  if (key === 'anexosFlag') {
    return formataData(value);
  }

  if (key === 'data_desfiliacao') {
    return formataData(value);
  }
  return value;
};

HistoricoFiliado.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
};

export default withStyles(styles)(HistoricoFiliado);
