import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import DatePicker from '../commons/DatePicker';
import { withSnackbar } from 'notistack';
import { storage } from '../../firebase';
import styles from './styles';
import AnexoDocumento from './anexo_documento';
import {
  getDocumento,
  criarDocumento,
  saveDocumento,
  setDocumentoId,
} from '../../actions/documento';

class DocumentoForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ documentoId }) {
    if (documentoId) {
      this.props.getDocumento(documentoId);
    }
  }

  save(documento) {
    const { documentoId } = this.props;
    if (documentoId) {
      this.props.saveDocumento(documentoId, documento, () => this.successHandler());
    } else {
      this.props.criarDocumento(documento, (key) => {
        this.props.setDocumentoId(key);
        this.props.getDocumento(key);
        this.successHandler();
      });
    }
  }

  deleteAnexo(anexo) {
    const { documentoId } = this.props;
    this.props.saveDocumento(documentoId, { anexo: null });
    storage.removerAnexo(anexo.url);
  }

  successHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Documento salvo com sucesso!', {
      variant: 'success',
    });
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar documento.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, pristine, submitting, reset, documentoId, documento, email } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="sm"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cadastrar documento</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} hidden={false} alignItems="center" justifyContent="center">
              <Grid item sm={12}>
                <Field name="nome" component={InputText} label="Nome" />
              </Grid>
              <Grid item sm={6}>
                <Field
                  name="data_inicio_vigencia"
                  component={DatePicker}
                  label="Data início vigência"
                />
              </Grid>
              <Grid item sm={6}>
                <Field name="data_fim_vigencia" component={DatePicker} label="Data fim vigência" />
              </Grid>
              <Grid item sm={12}>
                {documento && (
                  <AnexoDocumento
                    email={email}
                    id={documentoId}
                    anexo={documento.anexo}
                    handleDelete={(anexo) => this.deleteAnexo(anexo)}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && this.props.handleClose(true)} color="default">
              Fechar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

DocumentoForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ documento: { documento, documentoId } }) => ({
  documento: documento,
  documentoId: documentoId,
  initialValues: documento,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['nome', 'url', 'data_inicio_vigencia', 'data_fim_vigencia'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

export default compose(
  connect(mapStateToProps, { getDocumento, criarDocumento, saveDocumento, setDocumentoId }),
  withStyles(styles)
)(
  reduxForm({
    form: 'documento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(DocumentoForm))
);
