export default (theme) => ({
  main: {
    width: 'auto',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(400 + theme.spacing(1) * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
    borderRadius: '16px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1) * 3,
  },
  backGorundLogIn: {
    zIndex: -1,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background:
      ' url(./img/logo-ilustrativo-sindilegis.svg) , linear-gradient(128deg, rgba(120,205,209,1) 0%, rgba(43,167,206,1) 100%)',
    backgroundSize: '80px auto, auto',
  },
});
