import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, reset, formValues } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../commons/InputText';
import Select from '../commons/Select';
import { withSnackbar } from 'notistack';
import styles from './styles';
import validate from './validate';
import AnexoDependente from './anexo_dependente';
import {
  getDependents,
  removeDependent,
  saveDependent,
  setDependents,
  setDependente,
  atualizarDependente,
} from '../../actions/dependent';
import { Divider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { cpfMask, dateMask } from '../commons/masks';
import { storage } from '../../firebase';
import Parentescos from './config';

const sexos = ['Masculino', 'Feminino', 'Prefiro não responder', 'Outro'];

const SexoOutro = formValues('sexo')(({ sexo }) => {
  return sexo === 'Outro' ? (
    <Grid item sm={4}>
      <Field name="sexo_outro" component={InputText} label="Especifique" />
    </Grid>
  ) : null;
});

class DependentList extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentWillReceiveProps({ filiadoId }) {
    if (filiadoId) {
      this.props.getDependents(filiadoId);
    }
  }

  componentWillUnmount() {
    this.props.setDependents([]);
    reset('dependent');
  }

  save(values) {
    const { filiadoId, saveDependent, setDependente, atualizarDependente } = this.props;
    if (values.id) {
      atualizarDependente(filiadoId, values.id, values, () => {
        setDependente(null);
        this.successHandler();
      });
    } else {
      values.data_cadastro = new Date().getTime();
      saveDependent(filiadoId, values, () => this.successHandler());
    }
  }

  successHandler() {
    this.props.enqueueSnackbar('Dependente salvo com sucesso.', {
      variant: 'success',
    });
  }

  edit(dependent) {
    this.props.setDependente(dependent);
  }

  delete(id) {
    const { filiadoId, removeDependent } = this.props;
    removeDependent(filiadoId, id);
  }

  deleteAnexo(dependente, anexo) {
    if (dependente.anexos && dependente.anexos[anexo.id]) {
      delete dependente.anexos[anexo.id];
    }
    const { filiadoId } = this.props;
    const anexos = dependente.anexos;
    this.props.atualizarDependente(filiadoId, dependente.id, { anexos });
    storage.removerAnexo(anexo.url);
  }

  render() {
    const { classes, reset, dependents, canEdit } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Dependentes</DialogTitle>
        <DialogContent>
          <List className={classes.root}>
            {dependents && dependents.map((item) => this.renderItem(item, canEdit))}
          </List>
          {canEdit && this.renderForm()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => reset() && this.props.handleClose(null)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderItem(item, canEdit) {
    const { filiadoId } = this.props;

    return (
      <React.Fragment key={item.id}>
        <ListItem role={undefined} alignItems="flex-start" dense>
          <ListItemText primary={item.nome_completo} />
          <ListItemText primary={item.sexo === 'Outro' ? item.sexo_outro : item.sexo} />
          <ListItemText primary={item.data_nascimento} />
          <ListItemText
            primary={<NumberFormat value={item.cpf} displayType={'text'} format="###.###.###-##" />}
          />
          <ListItemText primary={item.parentesco} />
          <ListItemText primary={item.nome_mae} />
          <ListItemSecondaryAction>
            <IconButton onClick={() => this.edit(item)} aria-label="Editar" disabled={!canEdit}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => this.delete(item.id)}
              aria-label="Excluir"
              disabled={!canEdit}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem role={undefined} style={{ padding: 20 }}>
          <AnexoDependente
            id={`${filiadoId}/${item.id}`}
            anexos={item.anexos}
            handleDelete={(anexo) => this.deleteAnexo(item, anexo)}
            canEdit={canEdit}
          />
        </ListItem>
      </React.Fragment>
    );
  }

  renderForm() {
    const { classes, handleSubmit, pristine, submitting } = this.props;

    return (
      <div>
        <Divider />
        <Typography variant="subtitle1" className={classes.title}>
          Novo dependente
        </Typography>
        <form className={classes.container} onSubmit={handleSubmit(this.save)}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Field name="nome_completo" component={InputText} label="Nome" />
            </Grid>
            <Grid item sm={3}>
              <Field
                name="data_nascimento"
                component={InputText}
                label="Data de nascimento"
                {...dateMask}
              />
            </Grid>
            <Grid item sm={3}>
              <Field name="sexo" component={Select} label="Gênero">
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {sexos.map((s) => (
                  <MenuItem key={s} value={s}>
                    {s}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <SexoOutro />
            <Grid item sm={2}>
              <Field name="parentesco" component={Select} label="Parentesco">
                <MenuItem value="">
                  <em>Selecione</em>
                </MenuItem>
                {Parentescos.map((p) => (
                  <MenuItem key={p} value={p}>
                    {p}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item sm={3}>
              <Field name="cpf" component={InputText} label="CPF" {...cpfMask} />
            </Grid>
            <Grid item sm={6}>
              <Field name="nome_mae" component={InputText} label="Nome da mãe" />
            </Grid>
            <Grid item sm={2}>
              <Button
                type="submit"
                disabled={pristine || submitting}
                variant="contained"
                color="primary"
                className={classes.button}>
                {this.props.dependent ? 'Salvar' : 'Adicionar'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ dependent, filiado }) => ({
  dependents: dependent.dependents,
  filiadoId: filiado.filiadoId,
  dependent: dependent.dependent,
  initialValues: dependent.dependent,
});

DependentList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getDependents,
    removeDependent,
    saveDependent,
    setDependents,
    setDependente,
    atualizarDependente,
  })
)(
  reduxForm({
    form: 'dependent',
    validate,
    enableReinitialize: true,
  })(withSnackbar(DependentList))
);
