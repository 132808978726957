import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';

import ConfirmDialog from '../commons/ConfirmDialog';
import {
  saveTema,
  getTema,
  createProcessoTema,
  saveProcessoTema,
  deleteProcessoTema,
  getDadosIdentificacaoProcessos,
} from '../../actions/politico_sindical';
import { steps, initialValuesTema, hasCurrentItemChanged } from '../../controllers/temaController';
import Switch from '../commons/Switch';
import AutoCompleteSelect from '../commons/AutoComplete';
import styles from './styles';
import validate from './validate';
import InputText from '../commons/InputText';
import RadioButton from '../commons/RadioButton';

class TemaStepProcessosForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmOpen: false,
      confirmAction: Function,
      confirmMessage: '',
    };
  }

  componentDidMount() {
    const { temaId, processos } = this.props;
    if (temaId) {
      this.props.getTema(temaId);
    }
    if (_.isNil(processos)) {
      this.props.getDadosIdentificacaoProcessos();
    }
  }

  save(values) {
    const { handleSubmit, temaId } = this.props;
    handleSubmit && handleSubmit();
    if (temaId) {
      const hasSomeNewItem = _.some(values['processos'], ({ id }) => _.isNil(id));
      if (hasSomeNewItem) {
        _.forEach(values['processos'], (processo) => {
          _.isNil(processo.id)
            ? this.props.createProcessoTema(temaId, processo)
            : this.props.saveProcessoTema(processo.id, temaId, processo);
        });
        this.props.saveTema(temaId, { page: steps.PROCESSOS, existe_processos: true }, () =>
          this.successHandler(true)
        );
      } else {
        this.props.saveTema(temaId, values, () => this.successHandler(true));
      }
    }
  }

  handleSaveProcesso(processo) {
    const { temaId } = this.props;
    if (temaId && !_.isEmpty(processo)) {
      const { id } = processo;
      _.isNil(id)
        ? this.props.createProcessoTema(temaId, processo)
        : this.props.saveProcessoTema(id, temaId, processo);

      this.props.getTema(temaId);
    }
  }

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleDeleteProcesso(processo, removeFieldCallback) {
    const { temaId } = this.props;
    if (!_.isNil(processo) && !_.isNil(processo.id)) {
      this.handleConfirmOpen(() => {
        this.props.deleteProcessoTema(processo.id, temaId);
        removeFieldCallback && removeFieldCallback();
      }, `Confirma exclusão do processo ${processo.identificacao_processo} relacionado ao tema?`);
    } else {
      removeFieldCallback && removeFieldCallback();
    }
  }

  handleChangeProcesso(numProcesso, field) {
    const processo = this.props.processos.find((p) => p.numeroProcesso === numProcesso);
    const processoFieldUuid = `${field}.processo_uid`;
    const processoFieldTribunal = `${field}.tribunalOrgao`;
    if (processo) {
      const { id, tribunalOrgao } = processo;
      id && this.props.change(processoFieldUuid, id);
      tribunalOrgao && this.props.change(processoFieldTribunal, tribunalOrgao);
    } else {
      this.props.change(processoFieldUuid, null);
      this.props.change(processoFieldTribunal, null);
    }
  }

  handleProcessoSindilegisHasChanged(field) {
    this.props.change(field, '');
  }

  successHandler(reload) {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Tema salvo com sucesso!', {
      variant: 'success',
    });
  }

  renderProcessos({ fields, meta: { error, submitFailed } }) {
    const { classes, processos, tema } = this.props;
    return (
      Boolean(!_.isNil(processos)) && (
        <Grid container className={classes.itensDestaqueContainer}>
          <Grid item xs={12}>
            <Button
              type="button"
              variant={'outlined'}
              color={'primary'}
              endIcon={<Add />}
              onClick={() => fields.push({})}>
              Novo item
            </Button>
          </Grid>
          <Grid item xs={12}>
            {submitFailed && error && (
              <Alert className={classes.alertMsg} severity="warning">
                {error}
              </Alert>
            )}
          </Grid>
          {fields.map((item, index) => (
            <ProcessoTema
              key={index}
              item={item}
              index={index}
              fields={fields}
              processos={processos}
              tema={tema}
              handleSaveProcesso={this.handleSaveProcesso.bind(this)}
              handleDeleteProcesso={this.handleDeleteProcesso.bind(this)}
              handleChangeProcesso={this.handleChangeProcesso.bind(this)}
              handleProcessoSindilegisHasChanged={this.handleProcessoSindilegisHasChanged.bind(
                this
              )}
            />
          ))}
        </Grid>
      )
    );
  }

  renderDadosProcessosGrid() {
    const { hasProcesso } = this.props;
    return (
      hasProcesso && (
        <React.Fragment>
          <Grid item sm={12}>
            <Typography variant={'body1'}>Processo</Typography>
          </Grid>
          <FieldArray name="processos" component={this.renderProcessos.bind(this)} />
        </React.Fragment>
      )
    );
  }

  render() {
    const { reset, classes, canEdit, submitting, handleSubmit, previousPage } = this.props;

    return (
      <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
        <DialogTitle id="form-dialog-title">Inclusão de novo tema</DialogTitle>
        <DialogContent style={{ minHeight: '50vh' }}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Field
                name="existe_processos"
                component={Switch}
                label="Existem processos judiciais ou administrativos relacionados ao tema?"
              />
            </Grid>
            {this.renderDadosProcessosGrid()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => reset() && this.props.handleClose(null)} color="default">
            Cancelar
          </Button>
          <Button onClick={previousPage} color="default">
            Voltar
          </Button>
          <Button type="submit" disabled={!canEdit || submitting} color="primary">
            Próximo passo
          </Button>
        </DialogActions>
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </form>
    );
  }
}

const selector = formValueSelector('tema');

const mapStateToProps = (state) => {
  const {
    politico_sindical: { tema, temaId, processos },
  } = state;
  return {
    tema: tema,
    temaId: temaId,
    processos: processos,
    initialValues: { ...initialValuesTema, ...tema, page: steps.PROCESSOS },
    hasProcesso: selector(state, 'existe_processos') || false,
  };
};

TemaStepProcessosForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    saveTema,
    getTema,
    createProcessoTema,
    saveProcessoTema,
    deleteProcessoTema,
    getDadosIdentificacaoProcessos,
  })
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepProcessosForm))
);

let ProcessoTema = ({
  classes,
  tema,
  item,
  index,
  fields,
  processos,
  isProcessoSindilegis,
  handleSaveProcesso,
  handleDeleteProcesso,
  handleChangeProcesso,
  handleProcessoSindilegisHasChanged,
}) => {
  const processoField = fields.get(index);
  const hasNoItemId = _.isNil(processoField.id);

  const renderGridActionsIconButtons = () => {
    const saveBtnDisabled = _.isEmpty(fields.get(index).processo_sindilegis);
    const shouldToSaveItem =
      hasNoItemId || hasCurrentItemChanged(tema, fields.get(index), 'processos');
    return (
      <Grid container item xs={2} justifyContent="center">
        <Grid item>
          <Tooltip title="Salvar processo" arrow>
            <span>
              <IconButton
                color={'primary'}
                aria-label={'Salvar'}
                disabled={saveBtnDisabled}
                onClick={() => handleSaveProcesso(fields.get(index))}>
                {shouldToSaveItem ? <SaveIcon /> : <CheckCircleIcon />}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Deletar processo" arrow>
            <IconButton
              color={'secondary'}
              aria-label={'Remover'}
              onClick={() => handleDeleteProcesso(fields.get(index), () => fields.remove(index))}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid item container key={index} className={classes.itensDestaque}>
      <Grid container item justifyContent="space-between">
        <Typography variant={'body2'}>Processo #{index + 1}</Typography>
        {renderGridActionsIconButtons()}
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={4}>
          <Field
            name={`${item}.processo_sindilegis`}
            component={RadioButton}
            label="Processo Sindilegis?"
            onChange={() => handleProcessoSindilegisHasChanged(`${item}.identificacao_processo`)}>
            <FormControlLabel value="true" control={<Radio />} label="Sim" />
            <FormControlLabel value="false" control={<Radio />} label="Não" />
          </Field>
        </Grid>
        <Grid item xs={8}>
          {!_.isNil(isProcessoSindilegis) ? (
            Boolean(isProcessoSindilegis === 'true') ? (
              <Field
                name={`${item}.identificacao_processo`}
                options={processos.map((p) => ({
                  label: p.numeroProcesso,
                  value: p.numeroProcesso,
                }))}
                component={AutoCompleteSelect}
                label="Identificação do processo (número)"
                onChange={(numProc) => handleChangeProcesso(numProc, item)}
              />
            ) : (
              <Field
                name={`${item}.identificacao_processo`}
                component={InputText}
                label="Identificação do processo"
                onChange={(numProc) => handleChangeProcesso(numProc, item)}
              />
            )
          ) : (
            <Typography variant={'caption'}>
              {' '}
              Por favor, informe se é um processo Sindilegis...{' '}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ProcessoTema = compose(
  withStyles(styles),
  connect((state, props) => ({
    isProcessoSindilegis: selector(state, `${props.item}.processo_sindilegis`),
  }))
)(ProcessoTema);
