import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { Field } from 'redux-form';
import RenderTextField from './RenderTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';

const DynamicFields = ({
  fields,
  fieldConfig,
  addButtonText = '',
  onAdd = () => fields.push({}),
  onRemove = (index) => fields.remove(index),
  input,
}) => {
  const handleCheckboxChange = (index) => {
    fields.forEach((name, i) => {
      if (i !== index) {
        fields.get(i).atual = false;
      }
    });
    fields.get(index).atual = !fields.get(index).atual;
  };

  return (
    <div>
      {fields.map((name, index) => (
        <Box key={index} mb={2}>
          <Grid container spacing={2}>
            {fieldConfig.map((field, fieldIndex) => (
              <Grid item sm={field.sm || 5} key={fieldIndex}>
                <Field
                  name={`${name}.${field.name}`}
                  component={RenderTextField}
                  label={field.label}
                  type={field.type}
                  onChange={() => field.type === 'checkbox' && handleCheckboxChange(index)}
                />
              </Grid>
            ))}
            {fieldConfig.some((field) => field.type === 'checkbox') && (
              <Grid
                item
                container
                sm={1}
                style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Button>
                  <DeleteIcon
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => onRemove(index)}></DeleteIcon>
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      ))}
      <Grid>
        <Button color="primary" size="small" onClick={() => onAdd()}>
          <AddBoxIcon />
        </Button>
        {addButtonText}
      </Grid>
    </div>
  );
};

export default DynamicFields;
