import React from 'react';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LinkIcon from '@material-ui/icons/Link';
import SaveIcon from '@material-ui/icons/Save';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';

import InputText from '../commons/InputText';
import ConfirmDialog from '../commons/ConfirmDialog';
import {
  setTema,
  getTema,
  saveTema,
  saveItemDestaqueTema,
  createItemDestaqueTema,
  deleteItemDestaqueTema,
  incluirDocumentoItemDestaqueTema,
  incluirLinkItemDestaqueTema,
} from '../../actions/politico_sindical';
import {
  fileUploadCheck,
  posicionamentos,
  steps,
  getDocumentosBy,
  initialValuesTema,
  hasCurrentItemChanged,
  ACCEPT_INPUT_FILE,
  getObjectByKey,
} from '../../controllers/temaController';
import Switch from '../commons/Switch';
import Select from '../commons/Select';
import styles from './styles';
import validate from './validate';
import { storage } from '../../firebase';
import TemaLinkFormDialog from './TemaLinkFormDialog';
import LinkChip from './LinkChip';

class TemaStepItensDestaqueForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rootItem: null,
      confirmOpen: false,
      confirmAction: Function,
      confirmMessage: '',
    };
  }

  componentDidMount() {
    const { temaId } = this.props;
    if (temaId) {
      this.props.getTema(temaId);
    }
  }

  handleFileCapture = (event, itemDestaque) => {
    const { temaId } = this.props;
    const file = event.target.files[0];

    const documentos = getDocumentosBy(itemDestaque);
    const errorsCheck = fileUploadCheck(documentos, file);
    if (_.isEmpty(errorsCheck)) {
      storage
        .uploadDocumentoItemDestaqueTema(temaId, `${itemDestaque.id}/${file.name}`, file)
        .then(({ metadata }) => {
          const documento = { nome: file.name, url: metadata.fullPath };
          this.props.incluirDocumentoItemDestaqueTema(temaId, itemDestaque.id, documento);
          this.props.getTema(temaId);
        });
    } else {
      const { enqueueSnackbar } = this.props;
      _.each(errorsCheck, (err) => {
        enqueueSnackbar(err.msg, { variant: err.variant });
      });
    }
  };

  handleDialogLinkOpen = (itemDestaque) => {
    this.setState({ open: true });
    this.setState({ rootItem: itemDestaque });
  };

  handleDialogLinkClose = () => {
    this.setState({ open: false });
    this.setState({ rootItem: null });
  };

  handleSaveLinkItemDestaqueTema = (link, itemDestaque, callback) => {
    const { temaId } = this.props;
    if (temaId) {
      this.props.incluirLinkItemDestaqueTema(temaId, itemDestaque.id, link);
      this.handleDialogLinkClose();
      this.props.getTema(temaId);
    }
    callback && callback();
  };

  handleDeleteLink(link, itemDestaque) {
    const { temaId } = this.props;
    let linksAtualizados = {};
    this.handleConfirmOpen(() => {
      if (itemDestaque.links && itemDestaque.links[link.id]) {
        linksAtualizados = _.omit(itemDestaque.links, [link.id]);
      }
      this.props.saveItemDestaqueTema(itemDestaque.id, temaId, { links: linksAtualizados });
      this.props.getTema(temaId);
    }, `Confirma exclusão do link ${link.nome} do item de destaque ${itemDestaque.descricao_item}?`);
  }

  save(values) {
    const { handleSubmit, temaId } = this.props;
    handleSubmit && handleSubmit();
    if (temaId) {
      const hasSomeNewItem = _.some(values['itens_destaque'], ({ id }) => _.isNil(id));
      if (hasSomeNewItem) {
        _.forEach(values['itens_destaque'], (itemDestaque) => {
          _.isNil(itemDestaque.id)
            ? this.props.createItemDestaqueTema(temaId, itemDestaque)
            : this.props.saveItemDestaqueTema(itemDestaque.id, temaId, itemDestaque);
        });
        this.props.saveTema(
          temaId,
          { page: steps.ITEM_DESTAQUE, existe_itens_destaque: true },
          () => this.successHandler(true)
        );
      } else {
        this.props.saveTema(temaId, values, () => this.successHandler(true));
      }
    }
  }

  handleSaveItemDestaque(itemDestaque) {
    const { temaId } = this.props;
    if (temaId && !_.isEmpty(itemDestaque)) {
      const { id } = itemDestaque;
      _.isNil(id)
        ? this.props.createItemDestaqueTema(temaId, itemDestaque)
        : this.props.saveItemDestaqueTema(id, temaId, itemDestaque);

      this.props.getTema(temaId);
    }
  }

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleDeleteDocumento(documento, itemDestaque) {
    const { temaId } = this.props;
    let documentosAtualizados = {};
    this.handleConfirmOpen(() => {
      if (itemDestaque.documentos && itemDestaque.documentos[documento.id]) {
        documentosAtualizados = _.omit(itemDestaque.documentos, [documento.id]);
      }

      this.props.saveItemDestaqueTema(itemDestaque.id, temaId, {
        documentos: documentosAtualizados,
      });
      storage.removerAnexo(documento.url);
      this.props.getTema(temaId);
    }, `Confirma exclusão do documento ${documento.nome} do item de destaque ${itemDestaque.descricao_item}?`);
  }

  handleDeleteItemDestaque(itemDestaque, removeFieldCallback) {
    const { temaId } = this.props;
    const documentos = getDocumentosBy(itemDestaque);
    if (!_.isNil(itemDestaque) && !_.isNil(itemDestaque.id)) {
      this.handleConfirmOpen(() => {
        this.props.deleteItemDestaqueTema(itemDestaque.id, temaId);
        _.forEach(documentos, ({ url }) => storage.removerAnexo(url));
        removeFieldCallback && removeFieldCallback();
      }, `Confirma exclusão completa do item de destaque ${itemDestaque.descricao_item}?`);
    } else {
      removeFieldCallback && removeFieldCallback();
    }
  }

  successHandler(reload) {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Tema salvo com sucesso!', {
      variant: 'success',
    });
  }

  renderGridActionsIconButtons(fields, index, hasNoItemId) {
    const { tema } = this.props;
    const saveBtnDisabled =
      _.isEmpty(fields.get(index).descricao_item) || _.isEmpty(fields.get(index).posicionamento);
    const shouldToSaveItem =
      hasNoItemId || hasCurrentItemChanged(tema, fields.get(index), 'itens_destaque');
    return (
      <Grid container item xs={2} justifyContent="center">
        <Grid item>
          <Tooltip title="Salvar item de destaque" arrow>
            <span>
              <IconButton
                color={'primary'}
                aria-label={'Salvar'}
                disabled={saveBtnDisabled}
                onClick={() => this.handleSaveItemDestaque(fields.get(index))}>
                {shouldToSaveItem ? <SaveIcon /> : <CheckCircleIcon />}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Deletar item de destaque" arrow>
            <IconButton
              color={'secondary'}
              aria-label={'Remover'}
              onClick={() =>
                this.handleDeleteItemDestaque(fields.get(index), () => fields.remove(index))
              }>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  renderDocumentoAnexoChip(item, id, itemDestaque) {
    const { canDelete, classes } = this.props;
    return canDelete ? (
      <Chip
        className={classes.truncateDocumentoAnexoChip}
        key={id}
        size="small"
        onDelete={() => this.handleDeleteDocumento({ ...item, id }, itemDestaque)}
        onClick={() => storage.downloadAnexo(item.url)}
        label={item.nome}
        clickable
        variant="outlined"
      />
    ) : (
      <Chip
        className={classes.truncateDocumentoAnexoChip}
        key={id}
        size="small"
        onClick={() => storage.downloadAnexo(item.url)}
        label={item.nome}
        clickable
        variant="outlined"
      />
    );
  }

  renderFileUploadGrid(fields, index, hasNoItemId) {
    const { classes, canDelete } = this.props;
    const documentos = getDocumentosBy(fields.get(index));
    const links = getObjectByKey(fields.get(index), 'links');
    const itemDestaque = fields.get(index);
    return (
      <React.Fragment>
        <Grid item xs={2}>
          <Tooltip
            title={
              hasNoItemId
                ? 'Salve o item de destaque para anexar documentos/links ao item'
                : 'Informações relevantes sobre o item de destaque'
            }
            arrow>
            <Grid item>
              <span className={classes.verticalDivider}>
                <input
                  accept={ACCEPT_INPUT_FILE}
                  className={classes.inputFileHidden}
                  id={index}
                  disabled={hasNoItemId}
                  onChange={(event) => this.handleFileCapture(event, itemDestaque)}
                  type="file"
                />
                <label htmlFor={index}>
                  <Typography className={classes.anexarDocumentoLabel} variant={'caption'}>
                    <IconButton
                      color="primary"
                      component="span"
                      aria-label={'File upload'}
                      disabled={hasNoItemId}>
                      <CloudUploadIcon />
                    </IconButton>
                  </Typography>
                </label>

                <Typography className={classes.anexarDocumentoLabel} variant={'caption'}>
                  <IconButton
                    color="primary"
                    component="span"
                    aria-label={'Add link'}
                    disabled={hasNoItemId}
                    onClick={() => this.handleDialogLinkOpen(itemDestaque)}>
                    <LinkIcon />
                  </IconButton>
                </Typography>
              </span>
            </Grid>
          </Tooltip>
        </Grid>
        <Grid item container sm={10} spacing={1} className={classes.docsUploadedContainer}>
          {_.map(documentos, (item, key) => (
            <div key={key}>
              <Tooltip title={item.nome} arrow>
                {this.renderDocumentoAnexoChip(item, key, itemDestaque)}
              </Tooltip>
            </div>
          ))}
          {_.map(links, (item, key) => (
            <LinkChip
              key={key}
              item={item}
              id={key}
              canDelete={canDelete}
              size="small"
              rootItem={itemDestaque}
              handleDeleteLink={this.handleDeleteLink.bind(this)}
            />
          ))}
        </Grid>
      </React.Fragment>
    );
  }

  renderItensDestaque({ fields, meta: { error, submitFailed } }) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.itensDestaqueContainer}>
        <Grid item xs={12}>
          <Button
            type="button"
            variant={'outlined'}
            color={'primary'}
            endIcon={<Add />}
            onClick={() => fields.push({})}>
            Novo Item
          </Button>
        </Grid>
        <Grid item xs={12}>
          {submitFailed && error && (
            <Alert className={classes.alertMsg} severity="warning">
              {error}
            </Alert>
          )}
        </Grid>
        {fields.map((item, index) => {
          const hasNoItemId = _.isNil(fields.get(index).id);
          return (
            <Grid item container key={index} className={classes.itensDestaque}>
              <Grid container item justifyContent="space-between">
                <Typography variant={'body2'}>Item #{index + 1}</Typography>
                {this.renderGridActionsIconButtons(fields, index, hasNoItemId)}
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={8}>
                  <Field
                    name={`${item}.descricao_item`}
                    component={InputText}
                    label="Descrição do item"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Field
                    name={`${item}.posicionamento`}
                    component={Select}
                    label="Posicionamento institucional a respeito">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {_.map(_.keys(posicionamentos), (key) => (
                      <MenuItem key={key} value={posicionamentos[key]}>
                        {posicionamentos[key]}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                {this.renderFileUploadGrid(fields, index, hasNoItemId)}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  renderDadosItensDestaqueGrid() {
    const { hasItemDestaque } = this.props;
    return (
      hasItemDestaque && (
        <React.Fragment>
          <Grid item sm={12}>
            <Typography variant={'body1'}>Itens de destaque do tema</Typography>
          </Grid>
          <FieldArray name="itens_destaque" component={this.renderItensDestaque.bind(this)} />
        </React.Fragment>
      )
    );
  }

  render() {
    const { classes, handleSubmit, reset, submitting, previousPage, canEdit, canDelete } =
      this.props;

    return (
      <React.Fragment>
        <form className={classes.container} onSubmit={handleSubmit(this.save.bind(this))}>
          <DialogTitle id="form-dialog-title">Inclusão de novo tema</DialogTitle>
          <DialogContent style={{ minHeight: '50vh' }}>
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <Field
                  name="existe_itens_destaque"
                  component={Switch}
                  label="Existem itens de destaque do tema?"
                />
              </Grid>
              {this.renderDadosItensDestaqueGrid()}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && this.props.handleClose(null)} color="default">
              Cancelar
            </Button>
            <Button onClick={previousPage} color="default">
              Voltar
            </Button>
            <Button type="submit" disabled={!canEdit || submitting} color="primary">
              Próximo passo
            </Button>
          </DialogActions>
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </form>
        <TemaLinkFormDialog
          open={this.state.open}
          rootItem={this.state.rootItem}
          handleClose={this.handleDialogLinkClose}
          handleSave={this.handleSaveLinkItemDestaqueTema}
          handleReload={this.handleReload}
          canEdit={canEdit}
          canDelete={canDelete}
        />
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('tema');

const mapStateToProps = (state) => {
  const {
    politico_sindical: { tema, temaId },
  } = state;
  return {
    tema: tema,
    temaId: temaId,
    initialValues: { ...initialValuesTema, ...tema, page: steps.ITEM_DESTAQUE },
    hasItemDestaque: selector(state, 'existe_itens_destaque') || false,
  };
};

TemaStepItensDestaqueForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setTema,
    getTema,
    saveTema,
    saveItemDestaqueTema,
    createItemDestaqueTema,
    deleteItemDestaqueTema,
    incluirLinkItemDestaqueTema,
    incluirDocumentoItemDestaqueTema,
  })
)(
  reduxForm({
    form: 'tema',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
  })(withSnackbar(TemaStepItensDestaqueForm))
);
