import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';
import { storage } from '../../firebase';
import { incluirAnexoDocumento } from '../../actions/documento';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
});

class AnexoDocumento extends Component {
  state = { anexo: {} };

  componentDidMount() {
    if (this.props.anexo) {
      this.setState({ anexo: this.props.anexo });
    }
  }

  componentWillUnmount() {
    this.setState({ anexo: null });
  }

  handleCapture = (event) => {
    const file = event.target.files[0];
    const { id, email } = this.props;
    storage.uploadAnexoDocumento(`${id}/${file.name}`, file).then(({ metadata }) => {
      const anexo = { nome: file.name, url: metadata.fullPath };
      this.props.incluirAnexoDocumento(id, anexo, email);
      this.setState({ anexo });
    });
  };

  render() {
    const { classes, id } = this.props;
    const { anexo } = this.state;

    if (anexo && anexo.url) {
      return (
        <Grid item container sm={12} spacing={2}>
          <Chip
            size="small"
            key={anexo.url}
            onDelete={() => this.handleDelete({ ...anexo, id })}
            onClick={() => storage.downloadAnexo(anexo.url)}
            label={anexo.nome}
            clickable
            variant="outlined"
          />
        </Grid>
      );
    } else {
      return (
        <Grid item container sm={12} spacing={2}>
          <input
            accept="application/pdf"
            className={classes.input}
            id={id}
            onChange={(event) => this.handleCapture(event)}
            type="file"
          />
          <label htmlFor={id}>
            <Button color="primary" component="span" size="small" style={{ marginRight: 8 }}>
              <AttachFile /> Anexar Documento
            </Button>
          </label>
        </Grid>
      );
    }
  }

  handleDelete(anexo) {
    this.setState({ anexo: null });
    this.props.handleDelete(anexo);
  }
}

export default connect(null, { incluirAnexoDocumento })(
  withStyles(styles, { withTheme: true })(AnexoDocumento)
);
