import React from 'react';
import { Field } from 'redux-form';
import InputText from '../commons/InputText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { formataData } from '../commons/util';

export default (props) => {
  const { fields, email } = props;
  return (
    <React.Fragment>
      {fields.map((item, index) => (
        <Grid
          key={index}
          container
          spacing={2}
          hidden={false}
          alignItems="center"
          justifyContent="center">
          <Grid item sm={8}>
            <Field
              name={`${item}.comentario`}
              component={InputText}
              multiline={true}
              minRows={2}
              label={`Comentário`}
            />
          </Grid>
          <Grid item sm={3}>
            <Field
              name={`${item}.email`}
              component="input"
              type={`text`}
              disabled={true}
              style={{ background: 'none', border: 'none' }}
            />
            <Field
              name={`${item}.data`}
              component="input"
              type={`text`}
              disabled={true}
              style={{ background: 'none', border: 'none' }}
            />
          </Grid>
          <Grid item sm={1}>
            <IconButton onClick={() => fields.remove(index)} aria-label="Excluir">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={() => {
          fields.push({ data: formataData(new Date()), email });
        }}>
        Adicionar comentário
      </Button>
    </React.Fragment>
  );
};
