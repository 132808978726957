import React from 'react';
import { Field, formValues } from 'redux-form';
import Grid from '@material-ui/core/Grid';
import CheckboxGroup from '../../commons/CheckboxGroup';
import InputText from '../../commons/InputText';
import * as config from './config';

export const Orgaos = formValues('filiadosIndeterminados')(({ filiadosIndeterminados }) => {
  if (filiadosIndeterminados) {
    return (
      <React.Fragment>
        <Grid item sm={6}>
          <CheckboxGroup
            name="orgaos"
            title="Órgãos"
            options={config.tribunaisOrgaos.map((o) => ({
              label: o,
              value: o,
            }))}
          />
        </Grid>
      </React.Fragment>
    );
  }
  return null;
});

export const Titulo = formValues('publicarPortalSindilegis')(({ publicarPortalSindilegis }) => {
  if (publicarPortalSindilegis) {
    return (
      <React.Fragment>
        <Grid item sm={3}>
          <Field name="titulo" component={InputText} label="Título" />
        </Grid>
      </React.Fragment>
    );
  }
  return null;
});
