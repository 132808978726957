import React from 'react';
import styles from './Card.module.css';
import ButtonComponent from '../commons/ButtonComponent';

export default function Card(props) {
  return (
    <div className={styles.card}>
      <div className={styles.top}>
        <img className={styles.circleImg} src={props.img} alt="avatar_img" />
      </div>
      <div className={styles.bottom}>
        <div className={styles.btn}>
          <ButtonComponent
            className={styles.button}
            size="medium"
            variant="insert"
            onClick={props.onEdit}>
            Editar
          </ButtonComponent>
        </div>
        <div className={styles.nameContainer}>
          <h2 className={styles.name}>{props.name}</h2>
          <div className={styles.cargoContainer}>
            <div className={styles.cargo}>
              {typeof props.cargo === 'object' && props.cargo !== 'Sem cargo' ? (
                <p>
                  {props.cargo.cargo} - {props.cargo.tempo}
                </p>
              ) : (
                <p>{props.cargo}</p>
              )}
            </div>
            <p className={styles.colegio_eleitoral}>{props.colegioEleitoral}</p>
          </div>
        </div>
        <p className={styles.atualizacao}>{props.atualizacao}</p>
      </div>
    </div>
  );
}
