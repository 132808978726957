export default (values) => {
  const errors = {};
  const requiredFields = [
    'nome_completo',
    'cpf',
    'situacao_filiado',
    'matricula',
    'empresa',
    'email',
    'data_filiacao',
  ];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail inválido';
  }
  return errors;
};
