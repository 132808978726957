import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import Chip from '@material-ui/core/Chip';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

export default function FiliadoPopover(props) {
  const { filiado } = props;
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <div>
          <Chip
            label={filiado.nome_completo}
            clickable
            size="small"
            color={filiado?.data_desfiliacao ? 'secondary' : 'default'}
            {...bindTrigger(popupState)}
          />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}>
            <Box p={2}>
              <Typography>{`Telefone: ${filiado?.tel_celular || 'N/A'}`}</Typography>
              <Typography>{`Email: ${filiado?.email || 'N/A'}`}</Typography>
              <Typography>{`CPF: ${filiado?.cpf || 'N/A'}`}</Typography>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}
