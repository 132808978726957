import { functions } from './firebase';

export const changeUserEmail = (userId, email) =>
  functions.httpsCallable('changeUserEmail')({ email: email, uid: userId });

export const createFiliado = (data) => functions.httpsCallable('createFiliados')(data);

export const createUser = (data) => functions.httpsCallable('createUser')(data);

export const deleteUser = (uid) => functions.httpsCallable('deleteUser')(uid);

export const deleteUserFromFirebaseAuthentication = (uid) => functions.httpsCallable('deleteUserFromFirebaseAuthentication')(uid);

export const enviarComunicacaoPorEmail = (data) =>
  functions.httpsCallable('enviarComunicacaoPorEmail')(data);

export const enviarPesquisaDeSatisfacaoPorEmail = (data) =>
  functions.httpsCallable('enviarPesquisaDeSatisfacaoPorEmail')(data);

export const enviarComunicacaoPorWhatsapp = (data) =>
  functions.httpsCallable('enviarComunicacaoPorWhatsapp')(data);

export const verificarConsignacao = (year, month, idConsignacao) =>
  functions.httpsCallable('verificarConsignacao')({ year: year, month: month, idConsignacao });

export const inativarFiliados = (data) => functions.httpsCallable('inativarFiliados')(data);

export const parseDocx = (data) => functions.httpsCallable('parseDocx')(data);
