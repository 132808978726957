import { auth } from './firebase';
import { onceGetRoles } from './db';

export const doCreateUserWithEmailAndPassword = (email, password) =>
  auth.createUserWithEmailAndPassword(email, password);

export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password);

export const doSignOut = () => auth.signOut();

export const doPasswordReset = (email) => auth.sendPasswordResetEmail(email);

export const doPasswordUpdate = (password) => auth.currentUser.updatePassword(password);

export const getCurrentUserEmail = () => auth.currentUser?.email;

export const onAuthUserListener = (next, fallback) =>
  auth.onAuthStateChanged((authUser) => {
    if (authUser) {
      authUser = getRoles(authUser, next);
    } else {
      fallback();
    }
  });

export const getRoles = (authUser, next) => {
  onceGetRoles(authUser.uid).then((snapshot) => {
    let roles = snapshot.val();
    if (!roles) {
      roles = [];
    }
    authUser = {
      uid: authUser.uid,
      email: authUser.email,
      roles: Object.keys(roles),
    };
    next(authUser);
  });
  return authUser;
};
