import React from 'react';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Select from '../../commons/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Comentarios from '../comentarios';
import { withSnackbar } from 'notistack';
import styles from '../styles';
import { setFiliadoId } from '../../../actions/filiado';
import {
  registrarRealizacaoAtendimento,
  setAtendimento,
  setAtendimentoId,
  setEscritorioAdvocacia,
} from '../../../actions/juridico';
import Typography from '@material-ui/core/Typography';
import { formataData, formataHora } from '../../Financial/util';
import RadioButton from '../../commons/RadioButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import UploadArquivosAtendimento from './uploadArquivosAtendimento';
import { getUsersAndRoles } from '../../../actions/user';
import _ from 'lodash';

class RealizacaoAtendimento extends React.Component {
  state = { anexos: [] };

  constructor(props) {
    super(props);
    this.registrarRealizacaoAtendimento = this.registrarRealizacaoAtendimento.bind(this);
  }

  componentDidMount() {
    if (this.props.atendimento && this.props.atendimento.anexos) {
      this.setState({ anexos: this.props.atendimento.anexos });
    }
    this.props.getUsersAndRoles();
  }

  registrarRealizacaoAtendimento(conclusaoAtendimento) {
    const { atendimento } = this.props;
    atendimento.anexos = this.state.anexos;
    this.props.registrarRealizacaoAtendimento(atendimento, conclusaoAtendimento, () =>
      this.successHandler(true)
    );
  }

  successHandler(reload) {
    const { handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Atendimento atualizado com sucesso!', {
      variant: 'success',
    });
    this.props.setEscritorioAdvocacia(null);
    this.props.setAtendimentoId(null);
    this.props.setAtendimento(null);
    this.props.reset();
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao atualizar atendimento.', {
      variant: 'error',
    });
  }

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      reset,
      atendimento,
      open,
      handleClose,
      authUser,
      users,
    } = this.props;

    const ocorreuAtendimento =
      this.props.realizacaoAtendimento &&
      this.props.realizacaoAtendimento.values &&
      this.props.realizacaoAtendimento.values.ocorreuAtendimento;
    const advogadosEscritorio =
      _.size(users) && atendimento && atendimento.escritorio
        ? _.filter(users, (user) => _.includes(user.escritorios, atendimento.escritorio.id))
        : [];
    return (
      atendimento && (
        <Dialog
          open={open}
          onClose={handleClose.bind(this, null)}
          fullWidth
          maxWidth="md"
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            Assessoria jurídica - Registrar resultado de atendimento
          </DialogTitle>
          <form onSubmit={handleSubmit(this.registrarRealizacaoAtendimento)}>
            <DialogContent>
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="center"
                justifyContent="center">
                <Grid item sm={12}>
                  <Typography>Número: {atendimento.numero}</Typography>
                  <Typography>{`Filiados: ${atendimento.filiados && atendimento.filiados.map((f) => f.nome_completo).join(', ')}`}</Typography>
                  <Typography>Tipo: {atendimento.tipoAtendimento}</Typography>
                  {atendimento.escritorio && (
                    <Typography>Escritório: {atendimento.escritorio.nome}</Typography>
                  )}
                  <Typography>
                    Dia e horário: {formataData(atendimento.data)} -{' '}
                    {formataHora(atendimento.horaInicio)} às {formataHora(atendimento.horaFim)}
                  </Typography>
                  {atendimento.descricao && (
                    <Typography>Descrição: {atendimento.descricao}</Typography>
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="center"
                justifyContent="center">
                <Grid item sm={12}>
                  <Field
                    name="advogadoResponsavel"
                    component={Select}
                    label="Advogado responsável pelo atendimento">
                    <MenuItem value="">
                      <em>Selecione</em>
                    </MenuItem>
                    {advogadosEscritorio.map((t) => (
                      <MenuItem key={t.id} value={t}>
                        {t.displayName}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ marginTop: '2em' }}>
                <Grid item sm={3}>
                  <Field
                    name="ocorreuAtendimento"
                    component={RadioButton}
                    label="Ocorreu atendimento?">
                    <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                    <FormControlLabel value="Não" control={<Radio />} label="Não" />
                  </Field>
                </Grid>
                {ocorreuAtendimento === 'Sim' && (
                  <Grid item sm={9}>
                    <Field
                      name="seraConstituidoNovoProcesso"
                      component={RadioButton}
                      label="Será constituído novo processo ou procedimento para tratar a demanda deste atendimento?">
                      <FormControlLabel value="Sim" control={<Radio />} label="Sim" />
                      <FormControlLabel value="Não" control={<Radio />} label="Não" />
                    </Field>
                  </Grid>
                )}
              </Grid>
              <Grid
                container
                spacing={2}
                hidden={false}
                alignItems="flex-start"
                justifyContent="flex-start"
                style={{ marginTop: '2em' }}>
                <Grid item sm={12}>
                  <UploadArquivosAtendimento
                    onChange={(anexos) => {
                      this.setState({ anexos });
                    }}
                    anexos={this.props.atendimento && this.props.atendimento.anexos}
                  />
                </Grid>
                <Grid item sm={12}>
                  <Typography variant="h6">Comentários registrados</Typography>
                  <FieldArray name="comentarios" component={Comentarios} email={authUser?.email} />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  this.props.setAtendimentoId(null) &&
                  this.props.setAtendimento(null) &&
                  reset() &&
                  this.props.handleClose()
                }
                color="default">
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={pristine || submitting}
                color="primary">
                Registrar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )
    );
  }
}

RealizacaoAtendimento.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({
  form: { realizacaoAtendimento },
  juridico: { atendimento, atendimentoId, escritorioAdvocacia },
  filiado: { filiado, filiadoId },
  userState: { users },
}) => ({
  realizacaoAtendimento,
  filiado,
  filiadoId,
  atendimento,
  atendimentoId,
  escritorioAdvocacia,
  users,
});

const validate = (values) => {
  const errors = {};
  const requiredFields = ['ocorreuAtendimento'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });

  return errors;
};

export default compose(
  connect(mapStateToProps, {
    registrarRealizacaoAtendimento,
    setFiliadoId,
    setAtendimento,
    setAtendimentoId,
    setEscritorioAdvocacia,
    getUsersAndRoles,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'realizacaoAtendimento',
    validate,
    enableReinitialize: true,
  })(withSnackbar(RealizacaoAtendimento))
);
