import React, { useState, useEffect, Fragment } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
const dateFns = new DateFnsUtils({ locale: ptLocale });

function RenderDateInput(props) {
  const {
    input,
    label,
    name,
    minDate,
    meta: { touched, error },
  } = props;
  const [selectedDate, setValue] = useState(null);

  useEffect(() => {
    if (typeof input.value === 'string' && input.value.match(/\d\d\/\d\d\/\d\d\d\d/g)) {
      setValue(dateFns.parse(input.value, 'dd/MM/yyyy').getTime());
    } else if (input.value) {
      setValue(new Date(input.value));
    }
  }, [props]);

  useEffect(() => {
    if (selectedDate) {
      const time = new Date(selectedDate).getTime();
      input.onChange(time);
    }
  }, [selectedDate]);

  return (
    <Fragment>
      <KeyboardDatePicker
        autoOk
        {...input}
        name={name}
        label={label}
        minDate={minDate ? minDate : new Date(0)}
        invalidDateMessage="Data inválida"
        onClear={() => {}}
        fullWidth
        variant="inline"
        value={selectedDate}
        onChange={setValue}
        format={'dd/MM/yyyy'}
        InputLabelProps={{ shrink: true }}
      />
      {touched && error && <span className="error_msg">{error}</span>}
    </Fragment>
  );
}

export default RenderDateInput;
