import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Container, Grid, Typography } from '@material-ui/core';
import styles from './styles';
import {
  getAutoridadePolitica,
  criarAutoridadePolitica,
  saveAutoridadePolitica,
  removerAnexoAutoridade,
  setAutoridadePolitica,
  setAutoridadePoliticaId,
} from '../../actions/autoridadePolitica';
import Card from './Card';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import AutoridadeForm from './autoridade';
import { storage } from '../../firebase';
import { AUTORIDADES } from '../../constants/routes';
import PerfilPolitico from './Perfil';
import ArquivosAutoridadePolitica from './Arquivos/tableArquivos';
import ConfirmDialog from '../commons/ConfirmDialog';
import { CircularProgress } from '@material-ui/core';
import { IMG_DEFAULT } from './consts';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';
import * as routes from '../../constants/routes';

class CardPerfilAutoridadeForm extends React.Component {
  state = {
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    open: false,
    imgUrl: null,
    politicoId: null,
    arquivosOpen: false,
    loadingImg: false,
  };

  componentDidMount() {
    const { politicoId } = this.props.location.state || null;
    if (politicoId) {
      this.props.getAutoridadePolitica(politicoId).then(() => {
        this.fetchImageUrl();
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.politicoId !== prevProps.politicoId) {
      this.fetchPolitico();
    }
  }

  fetchPolitico = () => {
    const { politicoId } = this.props;
    if (politicoId) {
      this.props.getAutoridadePolitica(politicoId).then(() => {
        this.fetchImageUrl();
      });
    }
  };

  fetchImageUrl = () => {
    const { politico } = this.props;
    if (politico && politico.photo) {
      const photoPath = politico.photo.url;
      this.setState({ loadingImg: true });

      storage
        .fetchImageUrlStorage(photoPath)
        .then((url) => {
          this.setState({ imgUrl: url, loadingImg: false });
          this.props.change('photoUrl', url);
        })
        .catch((error) => {
          console.error('Erro ao recuperar a URL da imagem:', error);
          this.setState({ imgUrl: null, loadingImg: false });
        });
    }
  };

  save = (politico) => {
    const { politicoId } = this.props;
    if (politicoId) {
      this.props.saveAutoridadePolitica(politicoId, politico, () => this.successHandler());
    } else {
      this.props.criarAutoridadePolitica(politico, () => this.successHandler(true));
    }
  };

  handlePerfilClickOpen = (politicoId) => {
    this.setState({ open: true, politicoId: politicoId });
  };

  handlePerfilClickClose = () => {
    this.setState({ open: false });
    this.fetchPolitico();
  };

  handleClickBack = () => {
    this.props.setAutoridadePolitica(null);
    this.props.setAutoridadePoliticaId(null);
    this.resetState();
    this.props.history.push(AUTORIDADES);
  };

  resetState = () => {
    this.setState({ imgUrl: null, politicoId: null });
    this.props.reset('perfil');
  };

  handleClose = (reload) => {
    if (reload) {
      this.fetchPolitico();
    }
    this.setState({ open: false });
  };

  successHandler = (reload) => {
    const { reset, enqueueSnackbar } = this.props;

    enqueueSnackbar('Político salvo com sucesso!', { variant: 'success' });
    reset('perfil');
    this.handleClose(reload);
  };

  errorHandler = () => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar político.', { variant: 'error' });
  };

  renderPoliticoInfo = (label, value) =>
    value ? (
      <Grid item sm={6} key={label}>
        <Typography variant="body1">
          <strong>{label}:</strong> {value}
        </Typography>
      </Grid>
    ) : null;

  handleArquivosOpen = (politicoId) => {
    this.setState({ arquivosOpen: true, politicoId });
  };

  handleArquivosClose = () => {
    this.setState({ arquivosOpen: false, politicoId: null });
  };

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm && this.state.confirmAction) {
      this.state.confirmAction();
    }
    this.setState({ confirmOpen: false });
  };

  handleDeleteArquivo = (anexoId, politicoId) => {
    this.handleConfirmOpen(() => {
      this.props
        .removerAnexoAutoridade(anexoId, politicoId)
        .then(() => {
          this.props.getAutoridadePolitica(politicoId);
          this.props.enqueueSnackbar('Arquivo excluído com sucesso!', { variant: 'success' });
        })
        .catch(() => {
          this.props.enqueueSnackbar('Erro ao excluir arquivo.', { variant: 'error' });
        });
    }, `Confirma a exclusão do arquivo?`);
  };

  renderImage() {
    const { loadingImg, imgUrl } = this.state;
    if (loadingImg) {
      return <CircularProgress size={50} />;
    }
    return imgUrl ? imgUrl : IMG_DEFAULT;
  }

  render() {
    const { classes, loading, politico, politicoId, location } = this.props;
    const { arquivosOpen } = this.state;

    const atualizacao =
      politico && (politico.data_atualizacao || politico.data_criacao)
        ? `Última atualização: ${politico.data_atualizacao || politico.data_criacao}`
        : 'Data não disponível';

    const cargos =
      politico && politico.cargos ? politico.cargos.filter((cargo) => cargo.atual) : [];
    const cargo = cargos.length > 0 ? cargos[0].cargo : 'Sem cargo';
    const colegio = cargos.length > 0 ? cargos[0].colegio_eleitoral : '';
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <div className={classes.root}>
        <SindilegisBar />
        <Loading loading={loading} />
        <Container maxWidth="xl">
          <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
          <Card
            img={this.renderImage()}
            name={politico?.nome_politico}
            atualizacao={atualizacao}
            cargo={cargo}
            colegioEleitoral={colegio}
            onEdit={() => this.handlePerfilClickOpen(politico.id)}
          />
          <PerfilPolitico
            id={politico?.id}
            nome={politico?.nome}
            nomePolitico={politico?.nome_politico}
            idade={politico?.idade}
            filiacao={politico?.filiacao_partidaria}
            biografia={politico?.biografia}
            exerceMandato={politico?.exerce_mandato}
            cargos={politico?.cargos}
            atividades={politico?.atividades_politicas}
            noticias={politico?.noticias}
            tel1={politico?.tel_primario}
            tel2={politico?.tel_secundario}
            email1={politico?.email_primario}
            email2={politico?.email_secundario}
            redesSociais={politico?.redes_sociais}
            almaMater={politico?.alma_mater}
            premios={politico?.premios}
            projetos={politico?.projetos_de_lei_propostos}
            comissoes={politico?.comissoes}
            votacoes={politico?.historico_de_votacao}
            interesses={politico?.interesses_politicos_especificos}
            afiliacoes={politico?.afiliacoes_a_grupos_ou_blocos_politicos}
            publicacoes={politico?.publicacoes}
            indices={politico?.indices_de_aprovacao}
            endossos={politico?.endosso_de_organizacoes}
            parentesco={politico?.parentesco}
            aliados={politico?.aliados_politicos}
            religiao={politico?.religiao}
            linguas={politico?.linguas_faladas}
            formacao={politico?.formacao_adicional}
            atividadesProfissionais={politico?.atividades_profissionais}
            agendaSindilegis={politico?.agendas_com_o_sindilegis}
            agendaFutura={politico?.possivel_agenda_futura_com_o_sindilegis}
            curiosidades={politico?.curiosidades}
            anexos={politico?.anexos}
            handleArquivosOpen={() => this.handleArquivosOpen(politicoId)}
            handleArquivosClose={this.handleArquivosClose}
          />
        </Container>
        <AutoridadeForm
          open={this.state.open}
          handleClose={this.handlePerfilClickClose}
          isCreatingNew={false}
          initialValues={this.props.initialValues}
        />
        <ArquivosAutoridadePolitica
          open={arquivosOpen}
          handleClose={this.handleArquivosClose}
          politicoId={politicoId}
          handleDelete={this.handleDeleteArquivo}
        />
        <ConfirmDialog
          open={this.state.confirmOpen}
          message={this.state.confirmMessage}
          onClose={this.handleConfirmClose}
        />
      </div>
    );
  }
}

CardPerfilAutoridadeForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['nome', 'nome_politico'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = ({ autoridade_politica: { politico } }) => ({
  politico,
  politicoId: politico?.id,
  initialValues: politico,
});

export default compose(
  connect(mapStateToProps, {
    getAutoridadePolitica,
    criarAutoridadePolitica,
    saveAutoridadePolitica,
    removerAnexoAutoridade,
    setAutoridadePolitica,
    setAutoridadePoliticaId,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'perfil',
    validate,
    enableReinitialize: true,
  })(withSnackbar(CardPerfilAutoridadeForm))
);
