import {
  FILIADOS_SET,
  FILIADO_SET,
  FILIADO_ID_SET,
  HISTORICO_SET,
  FILIADOS_REQUEST,
  FILIADO_UPDATED,
  DELETE_FILIADO,
} from '../actions/types';

const INITIAL_STATE = {
  filiados: [],
  filiado: null,
  filiadoId: null,
  historico: [],
  loading: false,
};

function filiadoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FILIADOS_SET: {
      return { ...state, filiados: action.filiados, loading: false };
    }
    case FILIADOS_REQUEST: {
      return { ...state, loading: true };
    }
    case FILIADO_SET: {
      return { ...state, filiado: action.filiado, loading: false };
    }
    case FILIADO_ID_SET: {
      return { ...state, filiadoId: action.filiadoId };
    }
    case HISTORICO_SET: {
      return { ...state, historico: action.historico };
    }
    case FILIADO_UPDATED: {
      const { filiados } = state;
      const { id, data } = action.payload;

      const index = filiados.findIndex((f) => f.id === id);
      const filiado = { ...filiados[index], ...data };
      filiados.splice(index, 1, filiado);
      return { ...state, filiados: [...filiados] };
    }
    case DELETE_FILIADO: {
      const { filiados } = state;
      const index = filiados.findIndex((f) => f.id === action.payload.id);
      filiados.splice(index, 1);
      return { ...state, filiados: [...filiados] };
    }
    default:
      return state;
  }
}

export default filiadoReducer;
