import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

function NotFoundPage(props) {
  const { classes } = props;
  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography variant="h5" gutterBottom>
          404 Página não encontrada
        </Typography>
        <Button fullWidth variant="contained" color="primary" component={Link} to="/">
          Retornar
        </Button>
      </Paper>
    </main>
  );
}

export default withStyles(styles)(NotFoundPage);
