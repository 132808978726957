import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import styles from './styles';
import MaterialTable from 'material-table';
import { withRouter } from 'react-router-dom';
import tableI18n from '../../TableLocalization';
import { Grid, Link } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { storage } from '../../../firebase';
import { getAutoridadePolitica } from '../../../actions/autoridadePolitica';
import ImageIcon from '@material-ui/icons/Image';
import { removerAnexo } from '../../../firebase/storage';

class ArquivosAutoridadePolitica extends React.Component {
  state = {
    documentos: [],
  };

  componentDidMount() {
    this.loadDocumentos();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.politico !== this.props.politico) {
      this.loadDocumentos();
    }
  }

  loadDocumentos() {
    const { politico } = this.props;
    if (politico && politico.anexos) {
      this.setState({ documentos: Object.values(politico.anexos) });
    }
  }

  handleDelete = (item) => {
    const { politicoId } = this.props;
    removerAnexo(item.url).then(() => {
      this.props.handleDelete(item.id, politicoId);
    });
  };

  showIcon(row) {
    var name = row?.nome;
    let icon = <DescriptionIcon />;
    if (name.includes('.png') || name.includes('.jpg')) {
      icon = <ImageIcon />;
    }
    return icon;
  }

  render() {
    const { handleClose, politicoId, classes } = this.props;
    const { documentos } = this.state;
    const titulo = this.props.politico ? this.props.politico.nome_politico : '';

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} fullWidth maxWidth="xl">
        <DialogTitle>{titulo}</DialogTitle>
        <DialogContent>
          <div className={classes.demo}>
            <MaterialTable
              columns={[
                {
                  title: '',
                  field: 'nome',
                  render: (rowData) => (
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => storage.downloadAnexo(rowData.url)}>
                      <Grid container alignItems="center" spacing={1}>
                        {this.showIcon(rowData)}
                        {rowData.nome}
                      </Grid>
                    </Link>
                  ),
                },
              ]}
              data={documentos}
              title={'Arquivos'}
              options={{
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: 'delete',
                  tooltip: 'Excluir',
                  onClick: (event, row) => {
                    this.handleDelete(row);
                  },
                },
              ]}
              localization={tableI18n}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(politicoId)} color="default">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ autoridade_politica: { politico } }) => ({
  politico,
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getAutoridadePolitica })
)(withRouter(ArquivosAutoridadePolitica));
