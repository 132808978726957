import {
  OBJETOS_PROCESSO_SET,
  OBJETO_PROCESSO_SET,
  OBJETO_PROCESSO_ID_SET,
  OBJETOS_PROCESSO_REQUEST,
  OBJETO_PROCESSO_UPDATED,
  DELETE_OBJETO_PROCESSO,
} from '../actions/types';

const INITIAL_STATE = {
  objetosProcesso: [],
  objetoProcesso: null,
  objetoProcessoId: null,
  historico: [],
  loading: false,
};

function objetoProcessoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case OBJETOS_PROCESSO_SET: {
      return { ...state, objetosProcesso: action.objetosProcesso, loading: false };
    }
    case OBJETOS_PROCESSO_REQUEST: {
      return { ...state, loading: true };
    }
    case OBJETO_PROCESSO_SET: {
      return { ...state, objetoProcesso: action.objetoProcesso, loading: false };
    }
    case OBJETO_PROCESSO_ID_SET: {
      return { ...state, objetoProcessoId: action.objetoProcessoId };
    }
    case OBJETO_PROCESSO_UPDATED: {
      const { objetosProcesso } = state;
      const { id, data } = action.payload;
      const index = objetosProcesso.findIndex((f) => f.id === id);
      const objetoProcesso = { ...objetosProcesso[index], ...data };
      objetosProcesso.splice(index, 1, objetoProcesso);
      return { ...state, objetosProcesso: [...objetosProcesso] };
    }
    case DELETE_OBJETO_PROCESSO: {
      const { objetosProcesso } = state;
      const index = objetosProcesso.findIndex((f) => f.id === action.payload.id);
      objetosProcesso.splice(index, 1);
      return { ...state, objetosProcesso: [...objetosProcesso] };
    }
    default:
      return state;
  }
}

export default objetoProcessoReducer;
