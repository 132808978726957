import React from 'react';
import _ from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import Container from '@material-ui/core/Container';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { db } from '../../firebase';
import { getExpenses } from '../../actions/financial';
import tableI18n from '../TableLocalization';
import SindilegisBar from '../commons/SindilegisBar';
import Loading from '../commons/Loading';
import styles from './styles';
import {
  somadorDouble,
  formatarValor,
  calcularEstorno,
  getMesAnoProjecao,
  subtrair,
  converterParaNumero,
} from './util';
import { Box, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getRoutes } from '../commons/util';
import MenuButton from '../commons/MenuButton';
import * as routes from '../../constants/routes';

const anos = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027];

const meses = [
  { nome: 'Janeiro', valor: 1 },
  { nome: 'Fevereiro', valor: 2 },
  { nome: 'Março', valor: 3 },
  { nome: 'Abril', valor: 4 },
  { nome: 'Maio', valor: 5 },
  { nome: 'Junho', valor: 6 },
  { nome: 'Julho', valor: 7 },
  { nome: 'Agosto', valor: 8 },
  { nome: 'Setembro', valor: 9 },
  { nome: 'Outubro', valor: 10 },
  { nome: 'Novembro', valor: 11 },
  { nome: 'Dezembro', valor: 12 },
];

const dateFns = new DateFnsUtils({ locale: ptLocale });

const defaultYear = 2023;
const defaultMonth = 1;

class AcompanhamentoOrcamentario extends React.Component {
  state = {
    inicioDoDia: dateFns.startOfDay(new Date()).getTime(),
    proximaSemana: dateFns.addDays(dateFns.endOfDay(new Date()), 7).getTime(),
    hoje: dateFns.endOfDay(new Date()).getTime(),
    previsoes: {},
    anoSelecionado: defaultYear,
    mesSelecionado: defaultMonth,
  };

  componentDidMount() {
    db.onceGetPrevisaoOrcamentaria(this.state.anoSelecionado, this.state.mesSelecionado).then(
      (snap) => {
        const previsoes = snap.val() || {};
        this.setState({ previsoes });
      }
    );

    this.props.getExpenses();
  }

  render() {
    const { loading, location } = this.props;
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <React.Fragment>
        <SindilegisBar />
        <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
        <Box style={{ gap: '4px' }} display={'flex'} margin={'auto auto auto 30px'}>
          <Box>
            <InputLabel id="label">Ano</InputLabel>
            <Select
              style={{ width: '300px' }}
              value={this.state.anoSelecionado}
              onChange={(event) => this.setState({ anoSelecionado: event.target.value })}
              labelId="label"
              id="select">
              {anos.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <InputLabel id="label">Mês</InputLabel>
            <Select
              labelId="label"
              onChange={(event) => this.setState({ mesSelecionado: event.target.value })}
              value={this.state.mesSelecionado}
              style={{ width: '300px' }}>
              id="select"
              {meses.map((mes) => (
                <MenuItem value={mes.valor} key={mes.valor}>
                  {mes.nome}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Loading loading={loading} />
        {!loading && (
          <Container maxWidth="xl">
            {_.map(this.getData(), (item) => {
              return this.renderCentroCusto(item);
            })}
          </Container>
        )}
      </React.Fragment>
    );
  }

  renderCentroCusto(data) {
    return (
      <MaterialTable
        style={{ marginTop: 16, marginBottom: 16 }}
        columns={[
          {
            title: 'Item de despesa',
            field: 'item_despesa',
            editable: 'never',
          },
          {
            title: 'Previsão',
            field: 'previsao',
            render: (row) => <div style={{ textAlign: 'left' }}>{formatarValor(row.previsao)}</div>,
          },
          {
            title: 'Executado',
            field: 'executado',
            editable: 'never',
            render: (row) => (
              <div style={{ textAlign: 'left' }}>{formatarValor(row.executado)}</div>
            ),
          },
          {
            title: 'Variação',
            field: 'variacao',
            editable: 'never',
            render: (row) => (
              <div style={{ textAlign: 'left' }}>
                {formatarValor(!row.previsao ? 0 : subtrair(row.previsao, row.executado))}
              </div>
            ),
          },
        ]}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              data.valoresPorItem[oldData.tableData.id].previsao = converterParaNumero(
                newData.previsao
              );
              db.doSavePrevisaoOrcamentaria(
                data.ano,
                data.mes,
                data.centro_custo,
                newData.item_despesa,
                newData.previsao
              )
                .then(() => resolve())
                .catch((e) => console.error(e));
            }),
        }}
        key={data.centro_custo}
        data={data.valoresPorItem}
        title={data.centro_custo}
        localization={tableI18n}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          search: false,
        }}
      />
    );
  }

  getData() {
    const despesasAnuais = _.groupBy(this.props.expenses, (e) =>
      getMesAnoProjecao(e.data_vencimento)
    );

    const currentYear = despesasAnuais[`${this.state.mesSelecionado}/${this.state.anoSelecionado}`];

    const centrosDeCusto = _.groupBy(currentYear, (e) => e.centro_custo);

    const calculado = Object.keys(centrosDeCusto).map((centro_custo) => {
      const itemsDespesa = _.groupBy(centrosDeCusto[centro_custo], (e) => e.item_despesa);

      const valoresPorItem = Object.keys(itemsDespesa).map((item_despesa) => ({
        item_despesa: item_despesa,
        previsao: this.state.previsoes[db.encode(centro_custo)]?.[db.encode(item_despesa)] || 0,
        executado: itemsDespesa[item_despesa]
          .map((e) => calcularEstorno(e))
          .reduce(somadorDouble, 0),
      }));

      const valorTotal = centrosDeCusto[centro_custo]
        .map((e) => calcularEstorno(e))
        .reduce(somadorDouble, 0);

      return {
        valor: valorTotal,
        centro_custo,
        valoresPorItem,
        ano: this.state.anoSelecionado,
        mes: this.state.mesSelecionado,
      };
    });
    return calculado;
  }

  preencherTotal(data) {
    data.forEach((i, index) => {
      if (index === 0) {
        i.saldoAcumulado = i.saldo;
        return;
      }
      i.saldoAcumulado = (parseFloat(i.saldo) + parseFloat(data[index - 1].saldoAcumulado)).toFixed(
        1
      );
    });

    const total = {
      periodo: 'Total',
      despesas_liquidadas: data.map((i) => i.despesas_liquidadas).reduce(somadorDouble, 0),
      despesas_liquidar: data.map((i) => i.despesas_liquidar).reduce(somadorDouble, 0),
      saldoAcumulado: data
        .map((i) => i.saldo)
        .reduce(somadorDouble, 0)
        .toFixed(2),
    };
    data.push(total);
  }
}

const mapStateToProps = ({ financial: { expenses, loading } }) => ({
  expenses,
  loading,
});

AcompanhamentoOrcamentario.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getExpenses })
)(withRouter(AcompanhamentoOrcamentario));
