import React from 'react';
import { Tooltip } from '@material-ui/core';

const TooltipText = ({ text, onClick }) => (
  <Tooltip title={text === 'Não há comentários' ? '' : 'Clique para ler'} arrow>
    <div
      style={{
        width: '200px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: text === 'Não há comentários' ? null : 'pointer',
      }}
      onClick={onClick}>
      {text}
    </div>
  </Tooltip>
);

export default TooltipText;
