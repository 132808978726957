import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AttachFile from '@material-ui/icons/AttachFile';

import * as storage from '../../../firebase/storage';
import { v4 } from 'uuid';

const styles = (theme) => ({
  input: {
    display: 'none',
  },
});

class UploadArquivosProcesso extends Component {
  state = { anexos: [] };

  componentDidMount() {
    if (this.props.anexos) {
      this.setState({ anexos: this.props.anexos });
    }
  }

  handleCapture = (event) => {
    const file = event.target.files[0];
    const uuid = v4();
    storage
      .uploadAnexoProcessos(`${uuid}`, file)
      .then(({ metadata }) => {
        const anexo = { nome: file.name, url: metadata.fullPath };
        const { anexos } = this.state;
        anexos.push(anexo);
        this.setState({ anexos });
        this.props.onChange(anexos);
      })
      .catch((e) => console.error(e));
  };

  render() {
    const { classes } = this.props;
    const { anexos } = this.state;

    return (
      <Fragment>
        <Grid item container sm={12} spacing={2}>
          {_.map(anexos, (item, index) => this.renderAnexo(item, index))}
        </Grid>
        <Grid item container sm={12} spacing={2}>
          <input
            accept="application/pdf"
            className={classes.input}
            id="upload-processo"
            onChange={(event) => this.handleCapture(event)}
            type="file"
          />
          <label htmlFor="upload-processo">
            <Button
              color="primary"
              component="span"
              size="medium"
              style={{ marginRight: 8, marginTop: 10 }}>
              <AttachFile /> Anexar Documentos
            </Button>
          </label>
        </Grid>
      </Fragment>
    );
  }

  renderAnexo(item, id) {
    const { pagamentoLiquidado, canDelete } = this.props;

    if (!canDelete && pagamentoLiquidado) {
      return (
        <Chip
          key={id}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    } else {
      return (
        <Chip
          key={id}
          onDelete={() => this.handleDelete({ ...item, id })}
          onClick={() => storage.downloadAnexo(item.url)}
          label={item.nome}
          clickable
          variant="outlined"
        />
      );
    }
  }

  handleDelete(item) {
    storage.removerAnexo(item.url);
    const novosAnexos = this.state.anexos.filter((a) => a.url !== item.url);
    this.setState({ anexos: novosAnexos });
    this.props.onChange(novosAnexos);
  }
}

export default connect(null, {})(withStyles(styles, { withTheme: true })(UploadArquivosProcesso));
