import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Container from '@material-ui/core/Container';
import tableI18n from '../TableLocalization';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  getExpenses,
  setExpenseId,
  setExpense,
  deleteExpense,
  atualizarDespesa,
  parseDocx,
} from '../../actions/financial';
import { getBeneficiarios } from '../../actions/beneficiario';
import SindilegisBar from '../commons/SindilegisBar';
import RangeDatePicker from '../../components/commons/RangeDatePicker';
import ConfirmDialog from '../commons/ConfirmDialog';
import ExpenseForm from './expense';
import LiquidacaoPagamento from './liquidacao_pagamento';
import EstornoDespesa from './estorno_despesa';
import FileUpload from '../commons/FileUpload';
import { storage } from '../../firebase';
import * as routes from '../../constants/routes';
import * as roles from '../../constants/roles';
import {
  getDataInicial,
  getDataFinal,
  getCorDespesa,
  formatarValor,
  calcularEstorno,
  formataData,
  dataPorExtenso,
} from './util';
import * as situacoes from './situacoes';
import _ from 'lodash';
import styles from './styles';
import * as numero from 'numero-por-extenso';
import ButtonComponent from '../commons/ButtonComponent';
import MenuButton from '../commons/MenuButton';
import { getRoutes } from '../commons/util';

class ListaDespesas extends React.Component {
  state = {
    loading: true,
    open: false,
    fromDate: getDataInicial(),
    toDate: getDataFinal(),
    filteredExpenses: [],
    confirmOpen: false,
    confirmAction: Function,
    confirmMessage: '',
    isDataParametrizada: false,
  };

  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    const { data_final } = this.props.match.params;
    const isDataParametrizada = !_.isEmpty(data_final);
    this.setState({ isDataParametrizada });
    this.props.getExpenses();
    this.props.getBeneficiarios();
  }

  componentWillReceiveProps({ expenses }) {
    if (expenses) {
      if (this.state.isDataParametrizada) {
        const { data_inicial, data_final } = this.props.match.params;
        this.filterExpenses(data_inicial, data_final);
        return;
      }
      this.filterExpenses(this.state.fromDate.getTime(), this.state.toDate.getTime());
    }
  }

  editExpense(event, id) {
    this.props.setExpenseId(id);
    this.setState({ open: true });
    event.stopPropagation();
  }

  handleOpen() {
    this.props.setExpenseId(null);
    this.props.setExpense(null);
    this.setState({ open: true });
    this.props.getExpenses();
  }

  handleClose() {
    this.setState({ open: false });
    this.props.getExpenses();
  }

  handleDateChange(date) {
    this.setState(date);
    this.filterExpenses(date.fromDate.getTime(), date.toDate.getTime());
  }

  handleConfirmOpen = (confirmAction, confirmMessage) => {
    this.setState({ confirmOpen: true, confirmAction, confirmMessage });
  };

  handleConfirmClose = (confirm) => {
    if (confirm) {
      this.state.confirmAction();
      this.setState({ confirmOpen: false });
    } else {
      this.setState({ confirmOpen: false });
    }
  };

  filterExpenses(fromDate, toDate) {
    let filteredExpenses = this.props.expenses.filter((e) => {
      return fromDate <= e.data_vencimento && toDate >= e.data_vencimento;
    });
    if (this.state.isDataParametrizada) {
      filteredExpenses = filteredExpenses.filter((e) => !e.data_liquidacao_pagamento);
    }
    this.setState({ filteredExpenses });
  }

  deleteAnexo(despesa, anexo) {
    this.handleConfirmOpen(() => {
      if (despesa.anexos && despesa.anexos[anexo.id]) {
        delete despesa.anexos[anexo.id];
      }

      const anexos = despesa.anexos;
      this.props.atualizarDespesa(despesa.id, { anexos });
      storage.removerAnexo(anexo.url);
    }, `Confirma exclusão do documento ${anexo.nome}?`);
  }

  selecionarDespesa(event, id) {
    const { filteredExpenses } = this.state;
    const index = filteredExpenses.findIndex((d) => d.id === id);
    const despesa = {
      ...filteredExpenses[index],
      selecionado: !filteredExpenses[index].selecionado,
    };
    filteredExpenses.splice(index, 1, despesa);
    this.setState({ filteredExpenses: [...filteredExpenses] });
    event.stopPropagation();
  }

  solicitarAprovacao() {
    const { authUser } = this.props;
    this.state.filteredExpenses
      .filter((d) => d.selecionado)
      .forEach((d) => {
        this.props.atualizarDespesa(d.id, {
          situacao: situacoes.AG_APROVACAO,
          solicitante: authUser.email,
        });
      });
  }

  gerarRecibo(despesa) {
    const beneficiario = this.props.beneficiarios.find((b) => b.id === despesa.id_beneficiario);
    this.props.parseDocx({
      valor: despesa.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
      valorPorExtenso: numero.porExtenso(despesa.valor, numero.estilo.monetario),
      data: dataPorExtenso(new Date(despesa.data_vencimento)),
      nome: beneficiario.nome_beneficiario,
      tipoDocumento: beneficiario.tipo === 'J' ? 'CNPJ' : 'CPF',
      documento: beneficiario.cpfCnpj,
      descricao: despesa.descricao,
    });
  }

  render() {
    const { classes, authUser, location } = this.props;
    const canEdit = _.includes(authUser.roles, roles.ADMIN_FINANCEIRO);
    const valoresSelecionados = this.state.filteredExpenses
      .filter((d) => d.selecionado)
      .map((e) => calcularEstorno(e))
      .reduce((a, b) => a + b, 0);
    const currentPath = location.pathname;
    const buttonsRoutes = getRoutes(routes, currentPath);

    return (
      <Fragment>
        <Fab variant="extended" aria-label="delete" className={classes.soma}>
          {formatarValor(valoresSelecionados)}
        </Fab>
        <SindilegisBar />
        <Container maxWidth="xl" className={classes.containerBottomPadding}>
          <MenuButton buttonsRoutes={buttonsRoutes} location={location} />
          {canEdit && (
            <React.Fragment>
              <Grid item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {this.state.filteredExpenses.filter((d) => d.selecionado).length ? (
                  <ButtonComponent
                    disabled={!this.state.filteredExpenses.filter((d) => d.selecionado).length}
                    variant="secondary"
                    onClick={() => this.solicitarAprovacao()}>
                    Solicitar aprovação
                  </ButtonComponent>
                ) : (
                  <ButtonComponent variant="insert" onClick={this.handleOpen}>
                    Cadastrar Despesa
                  </ButtonComponent>
                )}
              </Grid>
            </React.Fragment>
          )}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.areaOperacoes}>
            <Grid item container spacing={1} md={5} justifyContent="flex-start">
              {!this.state.isDataParametrizada && (
                <RangeDatePicker
                  fromDate={this.state.fromDate}
                  toDate={this.state.toDate}
                  onChange={(data) => this.handleDateChange(data)}
                />
              )}
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid container>
              <ExpenseForm open={this.state.open} handleClose={this.handleClose} />
            </Grid>
          </Grid>
          <MaterialTable
            columns={[
              {
                title: '',
                field: 'selecionado',
                render: (row) => {
                  return (
                    <Checkbox
                      checked={!!row.selecionado}
                      disabled={row.situacao && row.situacao !== situacoes.NEGADA}
                      onChange={(event) => this.selecionarDespesa(event, row.id)}
                      onClick={(e) => e.stopPropagation()}
                      inputProps={{
                        'aria-label': 'Selecionar despesa',
                      }}
                    />
                  );
                },
              },
              {
                title: 'Tipo',
                field: 'tipo_despesa',
                render: (row) => {
                  return (
                    <span
                      style={{
                        color: getCorDespesa(row.data_vencimento, row.data_liquidacao_pagamento),
                      }}>
                      {row.tipo_despesa}
                    </span>
                  );
                },
              },
              { title: 'Item', field: 'item_despesa' },
              { title: 'Beneficiário', field: 'nome_beneficiario' },
              { title: 'Parcela', field: 'parcela' },
              {
                title: 'Valor',
                field: 'valor',
                render: (row) => <span>{formatarValor(row.valor)}</span>,
              },
            ]}
            data={this.state.filteredExpenses}
            title="Despesas"
            detailPanel={(row) => this.renderDetail(row, canEdit)}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
            options={{
              actionsColumnIndex: -1,
              pageSize: 10,
              pageSizeOptions: [10, 20, 50, 100],
            }}
            actions={[
              {
                icon: 'cloud_download',
                tooltip: 'Gerar recibo',
                onClick: (event, row) => this.gerarRecibo(row),
                disabled: !canEdit,
              },
              {
                icon: 'edit',
                tooltip: 'Editar',
                onClick: (event, row) => this.editExpense(event, row.id),
                disabled: !canEdit,
              },
              (rowData) => {
                if (
                  !canEdit ||
                  (authUser.email !== 'amanda@sindilegis.org.br' &&
                    rowData.data_liquidacao_pagamento)
                ) {
                  return;
                }

                return {
                  icon: 'delete',
                  tooltip: 'Excluir',
                  onClick: (event, row) =>
                    this.handleConfirmOpen(
                      () => this.props.deleteExpense(row.id),
                      'Confirma exclusão da despesa?'
                    ),
                };
              },
            ]}
            localization={tableI18n}
          />
          <ConfirmDialog
            open={this.state.confirmOpen}
            message={this.state.confirmMessage}
            onClose={this.handleConfirmClose}
          />
        </Container>
      </Fragment>
    );
  }

  renderDetail(despesa, canEdit) {
    const { classes, authUser } = this.props;
    const {
      descricao,
      numero_parcelas,
      meio_pagamento,
      tipo_despesa,
      centro_custo,
      data_vencimento,
      id,
      anexos,
      data_liquidacao_pagamento,
      aprovadores,
    } = despesa;
    return (
      <Grid container className={classes.despesa}>
        <Grid container spacing={4}>
          <Grid item sm={8}>
            <Typography variant="body2" color="textSecondary">
              Descrição
            </Typography>
            <Typography variant="body1">{descricao}</Typography>
            {/* <Typography variant="body1">{id}</Typography> */}
          </Grid>

          <Grid item sm={4}>
            <Typography variant="body2" align="right" color="textPrimary">
              Nº de parcelas: {numero_parcelas} | Meio de pagamento: {meio_pagamento} |{' '}
              {tipo_despesa}
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" color="textSecondary">
              Centro de custo
            </Typography>
            <Typography variant="body1">{centro_custo}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" color="textSecondary">
              Data de vencimento
            </Typography>
            <Typography variant="body1">{formataData(data_vencimento)}</Typography>
          </Grid>
          <Grid item container sm={8} spacing={2} justifyContent="flex-end" alignItems="center">
            <LiquidacaoPagamento despesa={despesa} />
          </Grid>
          <Grid item container sm={12} spacing={2} justifyContent="flex-end" alignItems="center">
            <EstornoDespesa despesa={despesa} />
          </Grid>
          <FileUpload
            id={id}
            anexos={anexos}
            pagamentoLiquidado={!!data_liquidacao_pagamento}
            handleDelete={(anexo) => this.deleteAnexo(despesa, anexo)}
            canEdit={canEdit}
            canDelete={authUser.email === 'amanda@sindilegis.org.br'}
          />
          {aprovadores && (
            <Grid item container sm={6} spacing={2} justifyContent="flex-end" alignItems="center">
              <Typography variant="body2" color="textSecondary">
                Aprovador por:
              </Typography>
              <Typography variant="body1">{aprovadores.join(',')}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = ({ financial, beneficiario: { beneficiarios } }) => ({
  expenses: financial.expenses,
  expenseId: financial.expenseId,
  beneficiarios,
});

ListaDespesas.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getExpenses,
    setExpenseId,
    setExpense,
    deleteExpense,
    atualizarDespesa,
    parseDocx,
    getBeneficiarios,
  })
)(withRouter(ListaDespesas));
