import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
} from '@material-ui/core';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import { storage } from '../../../firebase';
import tableI18n from '../../TableLocalization';
import styles from './styles';
import { getFiliado } from '../../../actions/filiado';
import { connect, useDispatch } from 'react-redux';

const ArquivosFiliado = ({
  handleClose,
  classes,
  open,
  filiadoId,
  handleDeleteArquivoFiliado,
  filiado,
}) => {
  const [anexos, setAnexos] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open && filiadoId) {
      dispatch(getFiliado(filiadoId));
    }
  }, [open, filiadoId, dispatch]);

  const loadAnexos = useCallback(() => {
    if (filiado && filiado.anexos) {
      setAnexos(Object.values(filiado.anexos));
    }
  }, [filiado]);

  useEffect(() => {
    loadAnexos();
  }, [filiado, loadAnexos, filiadoId]);

  const handleDelete = (item) => {
    handleDeleteArquivoFiliado(item.id, filiadoId, item.url)
      .then(() => {
        setAnexos((prevAnexos) => prevAnexos.filter((anexo) => anexo.id !== item.id));
      })
      .catch((error) => {
        console.error('Erro ao excluir arquivo:', error);
      });
  };

  const titulo = filiado?.nome_completo;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        <div className={classes.demo}>
          <MaterialTable
            columns={[
              {
                title: '',
                field: 'nome',
                render: (rowData) => (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => storage.downloadAnexo(rowData.url)}>
                    <Grid container alignItems="center">
                      {rowData.nome}
                    </Grid>
                  </Link>
                ),
              },
            ]}
            data={anexos}
            title={'Arquivos'}
            options={{
              actionsColumnIndex: -1,
            }}
            actions={[
              {
                icon: 'delete',
                tooltip: 'Excluir',
                onClick: (event, row) => handleDelete(row),
              },
            ]}
            localization={tableI18n}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="default">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ filiado }) => ({
  filiado: filiado.filiado,
  filiadoId: filiado.filiadoId,
});

export default withStyles(styles)(connect(mapStateToProps, { getFiliado })(ArquivosFiliado));
