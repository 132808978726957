import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField as MuiTextField,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';

const RenderTextField = ({ input, label, meta: { touched, error }, type, ...custom }) => {
  if (type === 'checkbox') {
    return (
      <FormControl error={touched && !!error}>
        <FormControlLabel control={<Checkbox {...input} checked={input.value} />} label={label} />
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
  return (
    <FormControl fullWidth error={touched && !!error}>
      <MuiTextField
        {...input}
        {...custom}
        label={label}
        variant="outlined"
        margin="normal"
        fullWidth
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default RenderTextField;
