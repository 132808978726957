import React from 'react';
import { withSnackbar } from 'notistack';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Juridico from '../Juridico';

class HomeJuridico extends React.Component {
  render() {
    return <Juridico {...this.props} isEscritorio={true} />;
  }
}

HomeJuridico.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles))(withRouter(withSnackbar(HomeJuridico)));
