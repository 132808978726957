import _ from 'lodash';

export default (values) => {
  const errors = {};
  const requiredFields = ['tipo_tema', 'titulo', 'interessados', 'descricao', 'prioridade'];
  requiredFields.forEach((field) => {
    if (_.isNil(values[field]) || _.isEmpty(values[field])) {
      errors[field] = ' Obrigatório';
    }
  });

  if (values.existe_itens_destaque) {
    if (_.isEmpty(values.descricao_item)) {
      errors.descricao_item = ' Obrigatório';
    }
    if (_.isEmpty(values.posicionamento)) {
      errors.posicionamento = ' Obrigatório';
    }

    if (!values.itens_destaque || !values.itens_destaque.length) {
      errors.itens_destaque = { _error: 'Pelo menos um item de destaque deve ser informado.' };
    } else {
      const itensDestaqueArrayErrors = [];
      values.itens_destaque.forEach((itemDestaque, itemDestaqueIndex) => {
        const itemDestaqueErrors = {};
        if (!itemDestaque || _.isEmpty(itemDestaque.descricao_item)) {
          itemDestaqueErrors.descricao_item = 'Obrigatório';
          itensDestaqueArrayErrors[itemDestaqueIndex] = itemDestaqueErrors;
        }
        if (!itemDestaque || _.isEmpty(itemDestaque.posicionamento)) {
          itemDestaqueErrors.posicionamento = 'Obrigatório';
          itensDestaqueArrayErrors[itemDestaqueIndex] = itemDestaqueErrors;
        }
      });
      if (itensDestaqueArrayErrors.length) {
        errors.itens_destaque = itensDestaqueArrayErrors;
      }
    }
  }

  if (values.existe_pessoas_instituicoes) {
    if (!values.pessoas_instituicoes || !values.pessoas_instituicoes.length) {
      errors.pessoas_instituicoes = {
        _error: 'Pelo menos uma pessoa/instituição deve ser informada.',
      };
    } else {
      const pessoasInstituicoesArrayErrors = [];
      values.pessoas_instituicoes.forEach((pessoa, pessoaIndex) => {
        const pessoaErrors = {};
        if (!pessoa || _.isEmpty(pessoa.nome)) {
          pessoaErrors.nome = 'Obrigatório';
          pessoasInstituicoesArrayErrors[pessoaIndex] = pessoaErrors;
        }
        if (!pessoa || _.isEmpty(pessoa.motivo_relevancia)) {
          pessoaErrors.motivo_relevancia = 'Obrigatório';
          pessoasInstituicoesArrayErrors[pessoaIndex] = pessoaErrors;
        }
      });
      if (pessoasInstituicoesArrayErrors.length) {
        errors.pessoas_instituicoes = pessoasInstituicoesArrayErrors;
      }
    }
  }

  if (values.existe_processos) {
    if (!values.processos || !values.processos.length) {
      errors.processos = { _error: 'Pelo menos um processo deve ser informado.' };
    } else {
      const processosArrayErrors = [];
      values.processos.forEach((processo, processoIndex) => {
        const processoErrors = {};
        if (!processo || _.isEmpty(processo.processo_sindilegis)) {
          processoErrors.processo_sindilegis = 'Obrigatório';
          processosArrayErrors[processoIndex] = processoErrors;
        }
        if (!processo || _.isEmpty(processo.identificacao_processo)) {
          processoErrors.identificacao_processo = 'Obrigatório';
          processosArrayErrors[processoIndex] = processoErrors;
        }
      });
      if (processosArrayErrors.length) {
        errors.processos = processosArrayErrors;
      }
    }
  }

  if (values.existe_acoes_sindilegis) {
    if (!values.acoes_sindilegis || !values.acoes_sindilegis.length) {
      errors.acoes_sindilegis = { _error: 'Pelo menos uma ação deve ser informada.' };
    } else {
      const acoesSindilegisArrayErrors = [];
      values.acoes_sindilegis.forEach((acao, acaoIndex) => {
        const acaoErrors = {};
        if (!acao || _.isNil(acao.data_acao)) {
          acaoErrors.data_acao = 'Obrigatório';
          acoesSindilegisArrayErrors[acaoIndex] = acaoErrors;
        }
        if (!acao || _.isEmpty(acao.descricao_acao)) {
          acaoErrors.descricao_acao = 'Obrigatório';
          acoesSindilegisArrayErrors[acaoIndex] = acaoErrors;
        }
      });
      if (acoesSindilegisArrayErrors.length) {
        errors.acoes_sindilegis = acoesSindilegisArrayErrors;
      }
    }
  }

  return errors;
};
