export const whatsapp_templates = [
  {
    nome: 'CANCELAMENTO',
    id: 'bca63e3a-283a-49eb-a116-3aa1cfab2e46',
    parametros: { name_variable_0: 'Vitor' },
  },
];

export const remetentes = [
  'Jurídico Sindilegis <juridico@sindilegis.org.br>',
  'Não Responder <nao-responder@sindilegis.org.br>',
];
