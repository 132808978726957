import React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { PieChart, Pie, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';

const CustomPieChart = ({
  title,
  className,
  data,
  hidden,
  colors,
  showChart,
  containerHeight,
  outerRadius = 80,
}) => {
  return (
    <Grid item md={6}>
      <Card className={className} variant="outlined" hidden={hidden}>
        <CardHeader title={title} style={{ textAlign: 'start' }} />
        <CardContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px',
          }}>
          <div style={{ width: '100%', height: containerHeight }}>
            {showChart ? (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={outerRadius}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}>
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend align="center" verticalAlign="bottom" layout="horizontal" />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <div style={{ fontSize: 20, textAlign: 'center' }}>
                Não há dados para serem exibidos.
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CustomPieChart;
