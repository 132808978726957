import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import InputText from '../../commons/InputText';
import { withSnackbar } from 'notistack';
import styles from './styles';
import {
  getEscritorioAdvocacia,
  criarEscritorioAdvocacia,
  saveEscritorioAdvocacia,
} from '../../../actions/juridico';

class EscritorioForm extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.escritorioAdvocaciaId)
      this.props.getEscritorioAdvocacia(this.props.escritorioAdvocaciaId);
  }

  save(escritorioAdvocacia) {
    const { escritorioAdvocaciaId } = this.props;
    if (escritorioAdvocaciaId) {
      this.props.saveEscritorioAdvocacia(escritorioAdvocaciaId, escritorioAdvocacia, () =>
        this.successHandlerUpdate()
      );
    } else {
      this.props.criarEscritorioAdvocacia(escritorioAdvocacia, () => this.successHandler(true));
    }
  }

  successHandler(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Escritorio salvo com sucesso!', {
      variant: 'success',
    });
    reset('escritorioAdvocacia');
    handleClose(reload);
  }

  successHandlerUpdate(reload) {
    const { reset, handleClose, enqueueSnackbar } = this.props;
    enqueueSnackbar('Informação atualizada com sucesso!', {
      variant: 'success',
    });
    reset('escritorioAdvocacia');
    handleClose(reload);
  }

  errorHandler() {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar('Erro ao salvar escritorio.', {
      variant: 'error',
    });
  }

  render() {
    const { handleSubmit, handleClose, pristine, submitting, reset } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose.bind(this, null)}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Escritório</DialogTitle>
        <form onSubmit={handleSubmit(this.save)}>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={6}>
                <Field name="nome" component={InputText} label="Nome" />
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Field name="email" component={InputText} label="E-mail" />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => reset() && handleClose()} color="default">
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={pristine || submitting}
              color="primary">
              Salvar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

EscritorioForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ['nome', 'email'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = ' Obrigatório';
    }
  });
  return errors;
};

const mapStateToProps = ({ juridico: { escritorioAdvocacia, escritorioAdvocaciaId } }) => ({
  escritorioAdvocacia: escritorioAdvocacia,
  escritorioAdvocaciaId: escritorioAdvocaciaId,
  initialValues: escritorioAdvocacia,
});

export default compose(
  connect(mapStateToProps, {
    getEscritorioAdvocacia,
    criarEscritorioAdvocacia,
    saveEscritorioAdvocacia,
  }),
  withStyles(styles)
)(
  reduxForm({
    form: 'escritorioAdvocacia',
    validate,
    enableReinitialize: true,
  })(withSnackbar(EscritorioForm))
);
